import {PrinterConfigGuiSettings} from './printerConfigGuiSettings.model';
import {Serializable} from '../deserialize.service';

export class ConfigGuiSettings implements Serializable<ConfigGuiSettings> {
    printer: PrinterConfigGuiSettings;
    deserialize(input: any) {
        if (!input || input === null) {
            return null;
        }
        this.printer = new PrinterConfigGuiSettings().deserialize(input.printer);
        return this;
    }
}
