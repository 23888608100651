import {Reservation} from './../../../../_shared/model/reservation.model';
import {ConfirmReservationsRequest} from './../../../../_shared/model/treatment/confirmReservationsRequest.model';
import {PrereservationId} from './../../../../_shared/model/schedule/prereservationId.model';
import {Treatment} from './../../../../_shared/model/treatment/treatment.model';
import {GeneratedTreatmentPrereservation} from './../../../../_shared/model/treatment/generatedTreatmentPrereservation.model';
import {CreateTreatmentPrereservationsRequest} from './../../../../_shared/model/treatment/createTreatmentPrereservationsRequest.model';
import {Observable} from 'rxjs';
import {CreatePrereservationRequest} from './../../../../_shared/model/treatment/createPrereservationRequest.model';
import {HelperService} from './../../../../_shared/services/helper.service';
import {HttpClientService} from './../../../../_shared/services/http-client.service';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class TreatmentService {
    constructor(private httpClient: HttpClientService, private helper: HelperService) {}

    public validatePrereservations(prereservations: CreatePrereservationRequest[]): Observable<any> {
        return this.httpClient.post(this.helper.getTreatmentUrl() + '/validate/prereservation', prereservations);
    }

    public getTreatmentPrereservations(
        contractorid: number,
        subcontractorid: number,
        prereservations: CreateTreatmentPrereservationsRequest,
    ): Observable<GeneratedTreatmentPrereservation[]> {
        return this.httpClient.post(
            this.helper.getTreatmentUrl() +
                `/contractors/${contractorid}/subcontractors/${subcontractorid}/prereservations`,
            prereservations,
        );
    }

    public createTreatment(contractorid: number, subcontractorid: number, treatment: Treatment): Observable<any> {
        return this.httpClient.post(
            this.helper.getTreatmentUrl() + `/contractors/${contractorid}/subcontractors/${subcontractorid}`,
            treatment,
        );
    }

    public getAllTreatmentsCustomer(contractorid: number, customerid: number): Observable<Treatment[]> {
        return this.httpClient.get(
            this.helper.getTreatmentUrl() + `/contractors/${contractorid}/customer/${customerid}`,
        );
    }

    public getAllTreatments(contractorid: number): Observable<Treatment[]> {
        return this.httpClient.get(this.helper.getTreatmentUrl() + `/contractors/${contractorid}`);
    }

    public makePrereservations(
        contractorid: number,
        prepres: CreatePrereservationRequest[],
    ): Observable<PrereservationId[]> {
        return this.httpClient.post(
            this.helper.getTreatmentUrl() + `/contractors/${contractorid}/makePrereservations`,
            prepres,
        );
    }

    public confirmPrereservations(
        contractorid: number,
        treatmentid: number,
        confprere: ConfirmReservationsRequest,
    ): Observable<any> {
        return this.httpClient.post(
            this.helper.getTreatmentUrl() +
                `/contractors/${contractorid}/treatments/${treatmentid}/confirmPrereservations`,
            confprere,
        );
    }

    //getTreatmentReservations
    public getTreatmentReservations(contractorid: number, treatmentid: number): Observable<Reservation[]> {
        return this.httpClient.get(
            this.helper.getTreatmentUrl() + `/contractors/${contractorid}/treatments/${treatmentid}/reservations`,
        );
    }

    public getTreatmentDetails(contractorid: number, treatmentid: number): Observable<Treatment> {
        return this.httpClient.get(
            this.helper.getTreatmentUrl() + `/contractors/${contractorid}/treatments/${treatmentid}`,
        );
    }
}
