export enum AppModulesType {
    ACCOUNTING = 'ACCOUNTING',
    PATIENT_APP = 'PATIENT_APP',
    CASE = 'CASE',
    WAITING_QUEUE = 'WAITING_QUEUE',
    TRIAGE = 'TRIAGE',
    TREATMENTS = 'TREATMENTS',
    TELEMEDICINE = 'TELEMEDICINE',
    INBOX = 'INBOX',
    DEVICES_INBOX = 'DEVICES_INBOX',
    COVID = 'COVID',
    AVAIL_PARTNERS = 'AVAIL_PARTNERS',
}
