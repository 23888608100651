import {ConfigGuiSettings} from './configGuiSettings.model';
import {Serializable} from '../deserialize.service';
import {ResultUserGuiSettings} from './resultUserGuiSettings.model';
import {CalendarUserGuiSettings} from './calendarUserGuiSettings.model';
import {AppGuiSettings} from './appGuiSettings.model';

export class GuiUserContractorSettings implements Serializable<GuiUserContractorSettings> {
    calendar: CalendarUserGuiSettings;
    result: ResultUserGuiSettings;
    app: AppGuiSettings;
    config: ConfigGuiSettings;

    constructor() {
        this.calendar = new CalendarUserGuiSettings();
        this.result = new ResultUserGuiSettings();
        this.app = new AppGuiSettings();
        this.config = new ConfigGuiSettings();
    }
    deserialize(input: any) {
        if (!input || input === null) {
            return null;
        }
        if (input.calendar) {
            this.calendar = new CalendarUserGuiSettings().deserialize(input.calendar);
        }
        if (input.result) {
            this.result = new ResultUserGuiSettings().deserialize(input.result);
        }

        if (input.app) {
            this.app = new AppGuiSettings().deserialize(input.app);
        }

        if (input.config) {
            this.config = new ConfigGuiSettings().deserialize(input.config);
        }

        return this;
    }
}

// public filter: any = {
//     showResources: false,
//     showOnlyAvailable: false,
//     showWeekend: false
// };
