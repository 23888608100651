import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LogoutComponent } from './auth/logout/logout.component';
import {SelectedTermComponent} from "./theme/pages/home/terms/visit/selected-term/selected-term.component";
import {CanLeaveService} from "./auth/can-leave/can-leave.service";
import {SelectedTermTabletComponent} from "./theme/pages/home/terms/visit/selected-term-tablet/selected-term-tablet.component";

const routes: Routes = [
    {
        path: 'login',

        loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
    },
    { path: 'logout', component: LogoutComponent },
    { path: '', redirectTo: 'index', pathMatch: 'full' },
    {
        path: 'public',
        loadChildren: () => import('./theme/pages/home/terms/terms.module').then((m) => m.TermsModule),
    },
    {
        path: 'public',
        children: [
            {
                path: 'selected',
                children: [
                    {
                        path: ':prereservationId',
                        component: SelectedTermComponent,
                        canDeactivate: [CanLeaveService],
                        children:  [
                            {
                                path: 'form',
                                children: [
                                    {
                                        path: ':formid',
                                        component: SelectedTermComponent,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
            {
                path: 'tablet/selected',
                children: [
                    {
                        path: ':prereservationId',
                        component: SelectedTermTabletComponent,
                        canDeactivate: [CanLeaveService],
                        children:  [
                            {
                                path: 'form',
                                children: [
                                    {
                                        path: ':formid',
                                        component: SelectedTermTabletComponent,
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
