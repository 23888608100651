export enum ReservationTypes {
    INTERNAL,
    RESERVE,
    DRAFT,
    VISIT,
    QUEUE,
}

export function whichReservation(type: ReservationTypes): string {
    switch (type) {
        case ReservationTypes.INTERNAL:
            return 'internal';
        case ReservationTypes.RESERVE:
            return 'reservation';
        case ReservationTypes.DRAFT:
            return 'draft';
        case ReservationTypes.VISIT:
            return 'visit';
        case ReservationTypes.QUEUE:
            return 'queue';
    }
}
