import {Serializable} from './deserialize.service';
import {ConsentGroup} from './consentGroup.model';
import {ConsentBoolean} from './consentBoolean.model';

export class Consent implements Serializable<Consent> {
    informing: ConsentGroup;
    survey: ConsentGroup;
    marketing: ConsentGroup;
    insuranceConsent: ConsentBoolean;
    documentId: string;
    changeDate: Date;
    emailSent: Date;
    contentType: string;
    contractorEmail: string;
    contractorName: string;
    contractorWWW: string;
    contractorAddressName: string;
    contractorAddressNumber: string;
    contractorPostName: string;
    contractorPostNumber: string;
    consentFulfilled: Date;

    deserialize(input) {
        if (!input || input === null) {
            return null;
        }
        this.informing = new ConsentGroup().deserialize(input.informing);
        this.survey = new ConsentGroup().deserialize(input.survey);
        this.marketing = new ConsentGroup().deserialize(input.marketing);
        this.insuranceConsent = new ConsentBoolean().deserialize(input.insuranceConsent);
        this.documentId = input.documentId;
        this.changeDate = input.changeDate;
        this.emailSent = input.emailSent;
        this.contentType = input.contentType;
        this.contractorEmail = input.contractorEmail;
        this.contractorName = input.contractorName;
        this.contractorWWW = input.contractorWWW;
        this.contractorAddressName = input.contractorAddressName;
        this.contractorAddressNumber = input.contractorAddressNumber;
        this.contractorPostName = input.contractorPostName;
        this.contractorPostNumber = input.contractorPostNumber;
        this.consentFulfilled = input.consentFulfilled;
        return this;
    }
    public default(): Consent {
        return new Consent().deserialize({
            informing: {
                title: 'Obveščanje o terminu',
                id: 1,
                email: true,
                sms: true,
            },
            survey: {
                title: 'Anketiranje',
                id: 2,
                email: false,
                sms: false,
            },
            marketing: {
                title: 'Direktno trženje',
                id: 3,
                email: false,
                sms: false,
            },
            insuranceConsent: {
                title: 'Uporaba podatkov',
                id: 4,
                canShareData: false,
            },

            documentId: '',
            change: '',
            emailSend: '',
            contentType: '',
            contractorEmail: '',
            contractorName: '',
            contractorWWW: '',
            contractorAddressName: '',
            contractorAddressNumber: '',
            contractorPostName: '',
            contractorPostNumber: '',
            consentFulfilled: '',
        });
    }

    public defaultEmpty(): Consent {
        return new Consent().deserialize({
            informing: {
                title: 'Obveščanje o terminu',
                id: 1,
                email: true,
                sms: true,
            },
            survey: {
                title: 'Anketiranje',
                id: 2,
                email: undefined,
                sms: undefined,
            },
            marketing: {
                title: 'Direktno trženje',
                id: 3,
                email: undefined,
                sms: undefined,
            },
            insuranceConsent: {
                title: 'Uporaba podatkov',
                id: 4,
                canShareData: false,
            },
            documentId: '',
            change: '',
            emailSend: '',
            contentType: '',
            contractorEmail: '',
            contractorName: '',
            contractorWWW: '',
            contractorAddressName: '',
            contractorAddressNumber: '',
            contractorPostName: '',
            contractorPostNumber: '',
            consentFulfilled: '',
        });
    }

    public isObjFiled(): boolean {
        if (
            this.informing.email !== undefined &&
            this.informing.sms !== undefined &&
            this.marketing.email !== undefined &&
            this.marketing.sms !== undefined &&
            this.survey.email !== undefined &&
            this.survey.sms !== undefined
        ) {
            return true;
        } else {
            return false;
        }
    }
}
