import {Helpers} from '../../../helpers';
import {MenuService} from '../../../_shared/services/menu.service';
import {ScriptLoaderService} from '../../../_services/script-loader.service';
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';

declare let mApp: any;
declare let mUtil: any;
declare let mLayout: any;
@Component({
    selector: '.m-grid.m-grid--hor.m-grid--root.m-page',
    templateUrl: './theme.component.html',
    styleUrls: ['./theme.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ThemeComponent implements OnInit {
    public isFooterVisible: boolean = true;
    constructor(private _script: ScriptLoaderService, private _router: Router, private menu: MenuService) {}
    ngOnInit() {
        // this._script.loadScripts('body', ['assets/vendors/base/vendors.bundle.js', 'assets/demo/demo5/base/scripts.bundle.js'], true)
        this._script
            .loadScripts(
                'body',
                ['assets/vendors/base/vendors.bundle.js', 'assets/demo/demo5/base/scripts.bundle.js'],
                true,
            )
            .then((result) => {
                Helpers.setLoading(false);
                // optional js to be loaded once
            });

        this._router.events.subscribe((route) => {
            // this.menu.showDefaultMenu();
            if (route instanceof NavigationStart) {
                try {
                    (<any>mLayout).closeMobileAsideMenuOffcanvas();
                    (<any>mLayout).closeMobileHorMenuOffcanvas();
                } catch (e) {}
                // (<any>mApp).scrollTop();
                Helpers.setLoading(true);
                // hide visible popover
                (<any>$('[data-toggle="m-popover"]')).popover('hide');
            }
            if (route instanceof NavigationEnd) {
                // debugger;
                // init required js
                (<any>mApp).init();
                (<any>mUtil).init();
                Helpers.setLoading(false);
                // content m-wrapper animation
                let animation = 'm-animate-fade-in-up';
                // $('.m-wrapper')
                //     .one('webkitAnimationEnd mozAnimationEnd MSAnimationEnd oanimationend animationend', function(e) {
                //         $('.m-wrapper').removeClass(animation);
                //     })
                //     .removeClass(animation)
                //     .addClass(animation);
            }
        });
    }
}
