import { Contractor } from './contractor.model';

export class Subcontractor extends Contractor {
    taxId: string;
    active: boolean;

    deserialize(input: any) {
        super.deserialize(input);
        return Object.assign(this, input);
    }
}
