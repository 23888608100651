import {ContractorGroup} from './utils/contractorGroup.model';
import {Contractor} from './contractor.model';
import {UserSettings} from './userSettings.model';
import {Serializable} from './deserialize.service';

export class User implements Serializable<User> {
    id: number;
    contractorId: number;
    name: string;
    surname: string;
    email: string;
    phone: string;
    roles: string[];
    webId: string;
    subcontractorId: number;
    settings: UserSettings;
    contractors: Contractor[];
    subcontractorColor: string;
    subcontractorBgColor: string;
    subcontractorFgColor: string;
    employeeId: number;
    taxId: string;
    active: boolean;
    userId: number;
    userContractors: ContractorGroup[];
    username: string;
    // constructor(obj?: any) {
    //     this.name = obj && obj.name || null;
    //     this.doctorBpi = obj && obj.doctorBpi || null;
    //     this.institutions = obj && obj.institutions || null;
    // }

    deserialize(input) {
        // debugger;
        this.id = input.id;
        this.contractorId = input.contractorId;
        this.name = input.name;
        this.surname = input.surname;
        this.email = input.email;
        this.phone = input.phone;
        this.roles = input.roles;
        this.webId = input.webid;
        this.subcontractorId = input.subcontractorId;
        this.subcontractorBgColor = input.subcontractorBgColor;
        this.subcontractorFgColor = input.subcontractorFgColor;
        this.employeeId = input.employeeId;
        this.active = input.active;
        this.userId = input.userId;
        this.username = input.username;
        this.userContractors = input.userContractors.map((cont) => new ContractorGroup().deserialize(cont));

        if (!input.subcontractorColor) {
            // this.subcontractorColor = 'm-fc-event--pink';
            this.subcontractorColor = '';
        } else {
            this.subcontractorColor = input.subcontractorColor;
        }

        if (input.settings) {
            this.settings = new UserSettings().deserialize(input.settings);
        } else {
            this.settings = new UserSettings().deserialize(undefined);
        }

        if (input.contractors) {
            this.contractors = input.contractors.map((cont) => new Contractor().deserialize(cont));
        }
        this.taxId = input.taxId;

        return this;
    }
}
