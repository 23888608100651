import {Serializable} from '../deserialize.service';
import * as moment from 'moment';

//INTERNI
export class WhiteReferral implements Serializable<WhiteReferral> {
    // VELJAVNOST
    public validity: string;
    public validityMonth: string;

    // OBSEG POOBLASTILA
    public authorization: string;
    public authorizationArray: any[];
    // RAZLOG OBRAVNAVE
    public reasonOfTreatment: string;

    // PREDNOSTNI KRITERIJI
    public priorityCriteria: string;

    // ŠT. ELEKTRONSKE NAPOTNICE
    public referralNumber: string;

    // STOPNJA NUJNOSTI
    public priorityLevel: string;

    // ŠIFRA IZVAJALCA
    public performerId: string;

    // RDP
    public rdp: any;

    // MKB
    public mkb: any;

    public doctorSpeciality: string;
    public referralPlace: string;
    public referralDate: moment.Moment;
    public referralDateString: string;

    public referralText1: string;
    public referralText2: string;
    public referralText3: string;
    public referralText4: string;
    public referralText5: string;
    public referralText6: string;

    deserialize(input: WhiteReferral) {
        this.validity = input.validity;
        this.validityMonth = input.validityMonth;
        this.authorization = input.authorization;
        this.authorizationArray = input.authorizationArray;

        this.reasonOfTreatment = input.reasonOfTreatment;
        this.priorityCriteria = input.priorityCriteria;
        this.referralNumber = input.referralNumber;
        this.priorityLevel = input.priorityLevel;
        this.performerId = input.performerId;
        this.rdp = input.rdp;
        this.mkb = input.mkb;
        this.doctorSpeciality = input.doctorSpeciality;
        this.referralPlace = input.referralPlace;
        this.referralText1 = input.referralText1;
        this.referralText2 = input.referralText2;
        this.referralText3 = input.referralText3;
        this.referralText4 = input.referralText4;
        this.referralText5 = input.referralText5;
        this.referralText6 = input.referralText6;
        this.referralDateString = input.referralDateString;
        if (input.referralDate) {
            this.referralDate = moment(input.referralDate);
        }
        return this;
    }
}
