import { Injectable } from '@angular/core';
import { PreReservation } from './../../model/preReservation.model';
import { TermsService } from './../../../theme/pages/home/_services/terms.service';
import { Customer } from './../../model/customer.model';
import { Reservation } from './../../model/reservation.model';
import { User } from './../../model/user.model';
import { ConfirmReservationsRequest } from './../../model/treatment/confirmReservationsRequest.model';
import { PrereservationId } from './../../model/schedule/prereservationId.model';
import { TreatmentGUI } from './../../model/treatment/treatmentGUI.model';
import { ToastrService } from 'ngx-toastr';
import { Treatment } from './../../model/treatment/treatment.model';
import { GeneratedTreatmentPrereservation } from './../../model/treatment/generatedTreatmentPrereservation.model';
import { CreateTreatmentPrereservationsRequest } from './../../model/treatment/createTreatmentPrereservationsRequest.model';
import { deserialize } from 'v8';
import { tags } from './../../constants/customTags.const';
import { AvailabilityStatus } from './../../enums/treatment/availabilityStatus.enum';
import { tap, catchError, mergeMap } from 'rxjs/operators';
import { BaseState } from './../base/base.state';
import { TreatmentService } from './../../../theme/pages/home/_services/treatment.service';
import { Slot } from './../../model/slot.model';
import { CreatePrereservationRequest } from './../../model/treatment/createPrereservationRequest.model';
import { HelperService } from './../../services/helper.service';
import { TreatmentReservationRequest } from './../../model/treatment/treatmentReservationRequest';
import { ReservationRequest } from './../../model/reservationRequest.model';
import { TreatmentStateModel } from './treatment-state.model';
import { CommonService } from './../../../theme/pages/home/_services/common.service';

import { Action, Selector, State, StateContext, StateToken, Store } from '@ngxs/store';
import { Term, ClearTreatmentState, TreatmentActions } from './treatment.actions';
import { append, patch, removeItem, updateItem } from '@ngxs/store/operators';
import { SetFormDisabled } from '@ngxs/form-plugin';
import * as moment from 'moment';
import * as _ from 'lodash';
import { of } from 'rxjs';
import { updateManyItems } from '../operators/update-many-items';

const TREATMENTSTATE_TOKEN: StateToken<TreatmentStateModel> = new StateToken('treatmentstate');

const DEFAULT_STATE: TreatmentStateModel = {
    baseTerm: undefined,
    newTerms: undefined,
    allTerms: undefined,
    treatmentData: undefined,
    treatmentform: undefined,
};

@State<TreatmentStateModel>({
    name: TREATMENTSTATE_TOKEN,
    defaults: DEFAULT_STATE,
    children: [], //ce bo kdaj prov prislo
})
@Injectable({
    providedIn: 'root',
})
export class TreatmentState {
    // private selectedContractor: Contractor = this.store.selectSnapshot(BaseState.activeContractor);

    constructor(
        private commonRest: CommonService,
        private helper: HelperService,
        private treatmentRest: TreatmentService,
        private store: Store,
        private toast: ToastrService,
        private termRest: TermsService,
    ) {}

    @Selector()
    public static baseTerm(state: TreatmentStateModel): ReservationRequest {
        return state.baseTerm;
    }

    @Selector()
    public static treatmentData(state: TreatmentStateModel): TreatmentGUI {
        return state.treatmentData;
    }

    @Selector()
    public static allTerms(state: TreatmentStateModel): TreatmentReservationRequest[] {
        return state.allTerms;
    }

    @Action(Term.CheckTermAvailability)
    public CheckTermAvailability(ctx: StateContext<TreatmentStateModel>, { termId }: Term.CheckTermAvailability) {
        const term: TreatmentReservationRequest = ctx.getState().allTerms.find((el) => el.treatmentResId == termId);
        const slot: Slot = this.prepareSlotData(term);
        const contractorId: number = this.store.selectSnapshot(BaseState.activeContractor).id;
        const reservation: CreatePrereservationRequest = new CreatePrereservationRequest().deserialize({
            from: slot.start,
            to: slot.end,
            contractor: {
                id: contractorId,
                subcontractors: [term.selectedSubcontractor],
            },
            service: term.selectedService,
        });
        return this.treatmentRest.validatePrereservations([reservation]).pipe(
            tap(() => {
                ctx.setState(
                    patch<TreatmentStateModel>({
                        allTerms: updateItem(
                            (el) => el.treatmentResId == termId,
                            patch({ availabilityStatus: (val) => AvailabilityStatus.FREE }),
                        ),
                    }),
                );
            }),
            catchError((err: any) => {
                ctx.setState(
                    patch<TreatmentStateModel>({
                        allTerms: updateItem(
                            (el) => el.treatmentResId == termId,
                            patch({ availabilityStatus: (val) => AvailabilityStatus.OCCUPIED }),
                        ),
                    }),
                );
                return of(err);
            }),
        );

        // ctx.patchState(DEFAULT_STATE);
    }

    @Action(ClearTreatmentState)
    public ClearState(ctx: StateContext<TreatmentStateModel>, {}: ClearTreatmentState) {
        ctx.patchState(DEFAULT_STATE);
    }

    @Action(Term.AddBaseTerm)
    public AddBaseTerm(ctx: StateContext<TreatmentStateModel>, { baseTerm }: Term.AddBaseTerm) {
        let data: TreatmentReservationRequest = new TreatmentReservationRequest().deserialize(baseTerm);
        data.termTypeObj = tags.TERM_TYPE.obj.RESERVATION;
        data.availabilityStatus = AvailabilityStatus.FREE;
        data.treatmentResId = this.helper.uuid(4);

        ctx.patchState({ baseTerm: baseTerm, allTerms: [data] });
    }

    @Action(Term.UpdateBaseTerm)
    public UpdateBaseTerm(ctx: StateContext<TreatmentStateModel>, { baseTerm }: Term.UpdateBaseTerm) {
        ctx.patchState({ baseTerm: baseTerm });
    }

    @Action(Term.AddNewTerm)
    public AddNewTerm(ctx: StateContext<TreatmentStateModel>, { newTerm }: Term.AddNewTerm) {
        const contractorId: number = this.store.selectSnapshot(BaseState.activeContractor).id;
        const baseTerm: ReservationRequest = ctx.getState().baseTerm;
        const treatment: TreatmentGUI = ctx.getState().treatmentData;
        // const allTerms: TreatmentReservationRequest[] = ctx.getState().allTerms;

        if (!baseTerm.selectedSubcontractor) {
            return of();
        }
        const slot: Slot = this.prepareSlotData(baseTerm);
        const getData: CreateTreatmentPrereservationsRequest = new CreateTreatmentPrereservationsRequest().deserialize({
            from: slot.start,
            to: slot.end,
            recurringType: newTerm.recurringType,
            service: baseTerm.selectedService,
            asset: baseTerm.selectedAsset,
            draft: baseTerm.reservationType == tags.TERM_TYPE.obj.DRAFT.reservationType ? true : false,
            numOfPrereservations: newTerm.recurringFrequency,
        });

        return this.treatmentRest
            .getTreatmentPrereservations(contractorId, baseTerm.selectedSubcontractor.id, getData)
            .pipe(
                tap((res: GeneratedTreatmentPrereservation[]) => {
                    const genTerms: TreatmentReservationRequest[] = res.map((el) => {
                        let tmp: TreatmentReservationRequest = new TreatmentReservationRequest().deserialize(baseTerm);
                        tmp.treatmentResId = this.helper.uuid(4);
                        tmp.termTypeObj = tags.TERM_TYPE.table.find((el) => el.id == newTerm.termType);
                        tmp.selectedAsset = el.asset;
                        tmp.selectedService = el.service;
                        tmp.selectedDate = moment(el.from);
                        tmp.selectedTime = moment(el.from).format('HH:mm');
                        tmp.selectedTimeEnd = moment(el.to).format('HH:mm');
                        tmp.availabilityStatus = AvailabilityStatus.FREE;
                        tmp.termTypeObj = tags.TERM_TYPE.table.find((el) => el.id == baseTerm.reservationType);
                        tmp.includeStartDate = !treatment.isTreatmentCreated;
                        // tmp.reservationType = baseTerm.reservationType;
                        return tmp;
                    });
                    ctx.setState(
                        patch<TreatmentStateModel>({
                            // allTerms: genTerms,
                            allTerms: append(genTerms),
                        }),
                    );
                }),
            );
    }

    @Action(TreatmentActions.CreateNewTreatment)
    public CreateNewTreatment(ctx: StateContext<TreatmentStateModel>, {}: TreatmentActions.CreateNewTreatment) {
        // const treatment: TreatmentGUI = ctx.getState().treatmentData;

        const treatment: TreatmentGUI = ctx.getState().treatmentform.model;
        const allTerms: TreatmentReservationRequest[] = ctx.getState().allTerms;
        // const allTerms: TreatmentReservationRequest[] = ctx.getState().treatmentData;
        const contractorId: number = this.store.selectSnapshot(BaseState.activeContractor).id;
        const subcontractorId: number = this.store.selectSnapshot(BaseState.loggedUser).subcontractorId;
        //validate
        if (allTerms.some((el) => el.isFormValid == false)) {
            this.toast.warning('Vsi termini niso pravilno vnešeni');
            return of([]);
        }
        return this.treatmentRest.createTreatment(contractorId, subcontractorId, treatment).pipe(
            tap((res) => {
                ctx.setState(
                    patch<TreatmentStateModel>({
                        treatmentData: patch<TreatmentGUI>({
                            id: res,
                            isTreatmentCreated: true,
                        }),
                    }),
                );

                this.store.dispatch([
                    new SetFormDisabled('treatmentstate.treatmentform'),
                    new Term.SetAllDetails(false),
                ]);
            }),
            mergeMap(() => {
                return this.treatmentRest
                    .makePrereservations(
                        contractorId,
                        allTerms.map((el) => {
                            const slot: Slot = this.prepareSlotData(el);
                            return new CreatePrereservationRequest().deserialize({
                                from: slot.start,
                                to: slot.end,
                                contractor: { id: contractorId, subcontractors: [{ id: el.selectedSubcontractor.id }] },
                                service: el.selectedService,
                                asset: el.selectedAsset,
                                customer: treatment.customer,
                                itemId: el.treatmentResId,
                            });
                        }),
                    )
                    .pipe(
                        tap((prereservations: PrereservationId[]) => {
                            allTerms.map((term, index) => {
                                ctx.dispatch([
                                    new Term.UpdateTermParameter(
                                        'availabilityStatus',
                                        AvailabilityStatus.BOOKED,
                                        term.treatmentResId,
                                    ),
                                    new Term.UpdateTermParameter(
                                        'prereservationId',
                                        prereservations.find((el) => el.itemId == term.treatmentResId).prereservationId,
                                        term.treatmentResId,
                                    ),
                                ]);
                            });
                        }),
                    );
            }),
            mergeMap((prereservations: PrereservationId[]) => {
                const treatmentId: number = ctx.getState().treatmentData.id;
                const user: User = this.store.selectSnapshot(BaseState.loggedUser);

                const confirmRequest: ConfirmReservationsRequest = new ConfirmReservationsRequest().deserialize({
                    appUserId: user.id,
                    appUserName: user.name,
                    prereservations: prereservations,
                });

                return this.treatmentRest.confirmPrereservations(contractorId, treatmentId, confirmRequest);
            }),
        );
    }

    private preparePreReservationRequest(
        allTerms: TreatmentReservationRequest[],
        cid: number,
        treatment: TreatmentGUI,
    ): CreatePrereservationRequest[] {
        const tmp: CreatePrereservationRequest[] = allTerms.map((term) => {
            const slot: Slot = this.prepareSlotData(term);
            return new CreatePrereservationRequest().deserialize({
                from: slot.start,
                to: slot.end,
                contractor: { id: cid, subcontractors: [{ id: term.selectedSubcontractor.id }] },
                service: term.selectedService,
                asset: term.selectedAsset,
                customer: treatment.customer,
                itemId: term.treatmentResId,
            });
        });
        return tmp;
    }

    @Action(Term.CreateNewReservations)
    public CreateNewReservations(ctx: StateContext<TreatmentStateModel>, { allTerms }: Term.CreateNewReservations) {
        const treatment: TreatmentGUI = ctx.getState().treatmentform.model;
        const contractorId: number = this.store.selectSnapshot(BaseState.activeContractor).id;
        // const subcontractorId: number = this.store.selectSnapshot(BaseState.loggedUser).subcontractorId;

        //validate
        if (!allTerms.every((el) => el.isFormValid)) {
            this.toast.warning('Termin ni pravilno vnešen');
            return of([]);
        }

        return this.treatmentRest
            .makePrereservations(contractorId, this.preparePreReservationRequest(allTerms, contractorId, treatment))
            .pipe(
                tap((prereservations: PrereservationId[]) => {
                    prereservations.map((prere: PrereservationId) => {
                        ctx.dispatch([
                            new Term.UpdateTermParameter('availabilityStatus', AvailabilityStatus.BOOKED, prere.itemId),
                            new Term.UpdateTermParameter('prereservationId', prere.prereservationId, prere.itemId),
                        ]);
                    });
                    // ctx.dispatch([
                    //     new Term.UpdateTermParameter(
                    //         'availabilityStatus',
                    //         AvailabilityStatus.BOOKED,
                    //         term.treatmentResId,
                    //     ),
                    //     new Term.UpdateTermParameter(
                    //         'prereservationId',
                    //         prereservations.find((el) => el.itemId == term.treatmentResId).prereservationId,
                    //         term.treatmentResId,
                    //     ),
                    // ]);
                }),
                mergeMap((prereservations: PrereservationId[]) => {
                    const treatmentId: number = ctx.getState().treatmentData.id;
                    const user: User = this.store.selectSnapshot(BaseState.loggedUser);

                    const confirmRequest: ConfirmReservationsRequest = new ConfirmReservationsRequest().deserialize({
                        appUserId: user.id,
                        appUserName: user.name,
                        prereservations: prereservations,
                    });

                    return this.treatmentRest.confirmPrereservations(contractorId, treatmentId, confirmRequest).pipe(
                        tap(() => {
                            this.store.dispatch([
                                new SetFormDisabled('treatmentstate.treatmentform'),
                                new Term.SetAllDetails(false),
                            ]);
                        }),
                    );
                }),
            );

        // mergeMap((prereservations: PrereservationId[]) => {
        //     const treatmentId: number = ctx.getState().treatmentData.id;
        //     const user: User = this.store.selectSnapshot(BaseState.loggedUser);

        //     const confirmRequest: ConfirmReservationsRequest = new ConfirmReservationsRequest().deserialize({
        //         appUserId: user.id,
        //         appUserName: user.name,
        //         prereservations: prereservations,
        //     });

        //     return this.treatmentRest.confirmPrereservations(contractorId, treatmentId, confirmRequest);
        // }),
        // );
    }

    @Action(TreatmentActions.UpdateReservationsTreatment)
    public UpdateReservationsTreatment(
        ctx: StateContext<TreatmentStateModel>,
        {}: TreatmentActions.UpdateReservationsTreatment,
    ) {
        const onlyNewTerms: TreatmentReservationRequest[] = ctx
            .getState()
            .allTerms.filter((el) => el.availabilityStatus == AvailabilityStatus.FREE);
        ctx.dispatch(new Term.CreateNewReservations(onlyNewTerms));
    }

    @Action(TreatmentActions.UpdateTreatment)
    public UpdateTreatment(ctx: StateContext<TreatmentStateModel>, { treatment }: TreatmentActions.UpdateTreatment) {
        ctx.patchState({ treatmentData: treatment });
    }

    @Action(TreatmentActions.SetTreatment)
    public SetTreatment(ctx: StateContext<TreatmentStateModel>, { treatment }: TreatmentActions.SetTreatment) {
        const formData: any = _.cloneDeep(treatment);
        formData.smsNotify = _.get(treatment, 'customer.sendSms');
        formData.emailNotify = _.get(treatment, 'customer.sendMail');
        ctx.patchState({ treatmentData: treatment, treatmentform: { model: formData } });
    }

    @Action(TreatmentActions.LoadTreatment)
    public LoadTreatment(ctx: StateContext<TreatmentStateModel>, { treatment }: TreatmentActions.LoadTreatment) {
        treatment.isTreatmentCreated = true;
        const contractorId: number = this.store.selectSnapshot(BaseState.activeContractor).id;
        return this.treatmentRest.getTreatmentReservations(contractorId, treatment.id).pipe(
            tap((res: Reservation[]) => {
                const treatmentReservations: TreatmentReservationRequest[] = res.map((el: Reservation) => {
                    return new TreatmentReservationRequest().deserialize({
                        selectedSubcontractor: el.contractor.subcontractors[0],
                        treatmentResId: this.helper.uuid(4),
                        selectedAsset: el.asset,
                        selectedService: el.service,
                        customTagId: el.customTagId,
                        selectedDate: moment(el.reservationFrom),
                        selectedTime: moment(el.reservationFrom).format('HH:mm'),
                        selectedTimeEnd: moment(el.reservationTo).format('HH:mm'),
                        availabilityStatus: AvailabilityStatus.BOOKED,
                        prereservationId: el.prereservationId,
                        aggregateId: el.aggregateId,
                    });
                });

                const lastTerm: ReservationRequest = _.last(treatmentReservations);
                ctx.dispatch([
                    new Term.AddBaseTerm(lastTerm),
                    new TreatmentActions.SetTreatment(treatment),
                    new Term.SetAllTerms(treatmentReservations),
                    new SetFormDisabled('treatmentstate.treatmentform'),
                ]);
            }),
        );
    }

    @Action(Term.DeleteTermReservation)
    public DeleteTermReservation(ctx: StateContext<TreatmentStateModel>, { term }: Term.DeleteTermReservation) {
        this.helper
            .displayAlert('Brisanje rezervacije', 'Ali ste prepričani, da želite izbrisati rezervacijo?')
            .then((result) => {
                if (result.value) {
                    this.termRest.cancelReservation(new PreReservation().deserialize(term)).subscribe(
                        () => {
                            this.toast.success('Termin je bil izbrisan.');
                            ctx.setState(
                                patch<TreatmentStateModel>({
                                    allTerms: removeItem((el) => el.treatmentResId == term.treatmentResId),
                                }),
                            );
                        },
                        () => {
                            this.toast.error('Napaka pri  brisanju termina.');
                        },
                    );
                }
            });
    }

    @Action(Term.RemoveTerm)
    public RemoveTerm(ctx: StateContext<TreatmentStateModel>, { term }: Term.RemoveTerm) {
        ctx.setState(
            patch<TreatmentStateModel>({
                allTerms: removeItem((el) => el.treatmentResId == term.treatmentResId),
            }),
        );
    }

    @Action(Term.UpdateTerm)
    public UpdateTerm(ctx: StateContext<TreatmentStateModel>, { term }: Term.UpdateTerm) {
        ctx.setState(
            patch<TreatmentStateModel>({
                allTerms: updateItem((el) => el.treatmentResId == term.treatmentResId, term),
            }),
        );
    }

    @Action(Term.SetAllTerms)
    public SetAllTerms(ctx: StateContext<TreatmentStateModel>, { terms }: Term.SetAllTerms) {
        ctx.setState(
            patch<TreatmentStateModel>({
                allTerms: terms,
            }),
        );
    }

    @Action(Term.UpdateAllTerms)
    public UpdateAllTerms(ctx: StateContext<TreatmentStateModel>, { term }: Term.UpdateAllTerms) {
        const allTermsCopy: TreatmentReservationRequest[] = _.cloneDeep(ctx.getState().allTerms);
        allTermsCopy.map((el) => {
            // _.set(el, 'selectedAsset', term.selectedAsset);
            el.selectedAsset = term.selectedAsset;
            el.selectedService = term.selectedService;
            el.customTagId = term.customTagId;
            el.selectedSubcontractor = term.selectedSubcontractor;
            el.selectedTimeEnd = term.selectedTimeEnd;
            el.treatmentResId = this.helper.uuid(4);
        });
        ctx.setState(
            patch<TreatmentStateModel>({
                baseTerm: patch({
                    selectedAsset: term.selectedAsset,
                    selectedService: term.selectedService,
                    customTagId: term.customTagId,
                    selectedSubcontractor: term.selectedSubcontractor,
                }),
                allTerms: allTermsCopy,
            }),
        );
    }

    // allTerms: updateManyItems(
    //     () => true,
    //     patch<TreatmentReservationRequest>({
    //         selectedAsset: term.selectedAsset,
    //         selectedService: term.selectedService,
    //         customTagId: term.customTagId,
    //         selectedSubcontractor: term.selectedSubcontractor,
    //         treatmentResId: this.helper.uuid(4),
    //     }),
    // ),

    @Action(Term.UpdateTermParameter)
    public UpdateTermParameter(
        ctx: StateContext<TreatmentStateModel>,
        { name, value, termId }: Term.UpdateTermParameter,
    ) {
        ctx.setState(
            patch<TreatmentStateModel>({
                allTerms: updateItem((el) => el.treatmentResId == termId, patch({ [name]: (val) => (val = value) })),
            }),
        );
    }

    @Action(Term.ToggleDetails)
    public ToggleDetails(ctx: StateContext<TreatmentStateModel>, { termId }: Term.ToggleDetails) {
        // const allTermsCopy: TreatmentReservationRequest[] = _.cloneDeep(ctx.getState().allTerms);
        ctx.setState(
            patch<TreatmentStateModel>({
                allTerms: updateItem((el) => el.treatmentResId == termId, patch({ isDetailsOpend: (val) => !val })),
            }),
        );
    }

    @Action(Term.SetDetailsOpen)
    public SetDetailsOpen(ctx: StateContext<TreatmentStateModel>, { termId, isOpen }: Term.SetDetailsOpen) {
        // const allTermsCopy: TreatmentReservationRequest[] = _.cloneDeep(ctx.getState().allTerms);

        ctx.setState(
            patch<TreatmentStateModel>({
                allTerms: updateItem((el) => el.treatmentResId == termId, patch({ isDetailsOpend: isOpen })),
            }),
        );
    }

    @Action(Term.SetAllDetails)
    public SetAllDetails(ctx: StateContext<TreatmentStateModel>, { isOpen }: Term.SetAllDetails) {
        ctx.setState(
            patch<TreatmentStateModel>({
                allTerms: updateManyItems(() => true, patch({ isDetailsOpend: isOpen })),
            }),
        );
    }

    @Action(Term.SetFormValidation)
    public SetFormValidation(ctx: StateContext<TreatmentStateModel>, { isValid, termId }: Term.SetFormValidation) {
        ctx.setState(
            patch<TreatmentStateModel>({
                allTerms: updateItem((el) => el.treatmentResId == termId, patch({ isFormValid: isValid })),
            }),
        );

        // patch(new TreatmentReservationRequest().deserialize({ isDetailsOpend: true })),

        // ctx.setState(
        //     patch({
        //         allTerms: updateItem((el) => el.treatmentResId == termId, term),
        //     }),
        // );
    }

    private prepareSlotData(formModel: TreatmentReservationRequest | ReservationRequest): Slot {
        let startDate, endDate;
        startDate = moment(formModel.selectedDate, 'DD.MM.YYYY')
            .hours(moment(formModel.selectedTime, 'HH:mm').hours())
            .minutes(moment(formModel.selectedTime, 'HH:mm').minutes());

        endDate = moment(formModel.selectedDate, 'DD.MM.YYYY')
            .hours(moment(formModel.selectedTimeEnd, 'HH:mm').hours())
            .minutes(moment(formModel.selectedTimeEnd, 'HH:mm').minutes());

        return new Slot().deserialize({
            start: startDate.toDate(),
            end: endDate.toDate(),
        });
    }
}
