import {Serializable} from '../deserialize.service';

export class PrintPOSPayer implements Serializable<PrintPOSPayer> {
    public name: string;
    public value: number;

    deserialize(input: any) {
        this.name = input.name;
        this.value = input.value;

        return this;
    }
}
