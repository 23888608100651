import {RecurringType} from './../../enums/recurringType.enum';
import {Asset} from './../asset.model';
import {Service} from './../service.model';
import {Serializable} from './../deserialize.service';
import * as moment from 'moment';

export class CreateTreatmentPrereservationsRequest implements Serializable<CreateTreatmentPrereservationsRequest> {
    from: moment.Moment;
    to: moment.Moment;
    recurringType: RecurringType;
    service: Service;
    asset: Asset;
    draft: boolean;
    numOfPrereservations: number;

    deserialize(input: any) {
        return Object.assign(this, input);
    }
}
