import {PaperType} from '../../enums/PaperType.enum';
import {Serializable} from '../deserialize.service';
import * as moment from 'moment';

export class CustomerPapersElement implements Serializable<CustomerPapersElement> {
    public paperDate: moment.Moment;
    public paperId: number;
    public visitDate: moment.Moment;
    public visitId: number;
    public paperType: PaperType;

    deserialize(input: any) {
        this.paperId = input.paperId;
        this.visitId = input.visitId;
        this.paperType = input.paperType;
        if (input.paperDate) {
            this.paperDate = moment(input.paperDate);
        }

        if (input.visitDate) {
            this.visitDate = moment(input.visitDate);
        }
        return this;
    }
}
