import { FormControl } from '@angular/forms';
import { Injectable } from "@angular/core";

@Injectable()
export class DateValidator {
    static slovenianDate(control: FormControl): { [s: string]: boolean } {
        let slovenianDatePattern = /^([1-9]|0[1-9]|[12][0-9]|3[01])[-\.]([1-9]|0[1-9]|1[012])[-\.]\d{4}$/;
        // debugger;
        // if (control.value.value) {
        //     return { slovenianDate: true };
        // }

        if (control.value === null) {
            return { slovenianDate: true };
        }
        if (!control.value.match(slovenianDatePattern)) return { slovenianDate: true };
        return null;
    }
}
