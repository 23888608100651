import {IrPaymentTypeInfo} from './../invoiceReport/irPaymentTypeInfo.model';
import * as _ from 'lodash';
import {Serializable} from '../../deserialize.service';

export class GroupInvocesCreateRequest implements Serializable<GroupInvocesCreateRequest> {
    groupInvoiceDetailIds: number[];
    paymentTypes: IrPaymentTypeInfo[];
    servicesFromTo: string; //izpis datumo od - do na računu

    deserialize(input) {
        if (_.isNil(input)) {
            return undefined;
        }

        this.groupInvoiceDetailIds = input.groupInvoiceDetailIds;
        this.paymentTypes = input.paymentTypes;
        this.servicesFromTo = input.servicesFromTo;
        return this;
    }
}
