import { Injectable } from '@angular/core';
import { ErrorNotifyService } from './errorNotify.service';
import { ToastrService } from 'ngx-toastr';


@Injectable()
export class MfToastService {
    constructor(private toast: ToastrService, private erorNotify: ErrorNotifyService) {}

    public error(text: string): void {
        this.toast.error(text);
    }

    public errorAndSend(text: string): void {
        this.toast.error(text);
        this.erorNotify.send(text, { severity: 'error' });
    }

    public warning(text: string): void {
        this.toast.warning(text);
    }

    public warningAndSend(text: string): void {
        this.toast.warning(text);
        this.erorNotify.send(text, { severity: 'warning' });
    }

    public info(text: string): void {
        this.toast.info(text);
    }

    public infoAndSend(text: string): void {
        this.toast.info(text);
        this.erorNotify.send(text, { severity: 'info' });
    }

    public success(text: string): void {
        this.toast.success(text);
    }

    public successAndSend(text: string): void {
        this.toast.success(text);
        this.erorNotify.send(text, { severity: 'info' });
    }
}
