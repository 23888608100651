import {Serializable} from '../../deserialize.service';

export class SendInvoiceNotificationInfo implements Serializable<SendInvoiceNotificationInfo> {
    receiver: string;
    phone: string;
    email: string;

    deserialize(input: any) {
        this.receiver = input.receiver;
        this.phone = input.phone;
        this.email = input.email;
        return this;
    }
}
