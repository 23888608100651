import * as _ from 'lodash';
import {Serializable} from '../../deserialize.service';

export class PaymentAmount implements Serializable<PaymentAmount> {
    type: string;
    amount: number;

    deserialize(input: any) {
        if (_.isNil(input)) {
            return undefined;
        }

        this.type = input.type;
        this.amount = input.amount;
        return this;
    }
}
