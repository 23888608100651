import {CaseStatusType} from './../../enums/caseStatusType.enum';
import {Service} from '../service.model';
import {Customer} from '../customer.model';
import {Contractor} from '../contractor.model';
import {Serializable} from '../deserialize.service';

export class BusinessCaseResponse implements Serializable<BusinessCaseResponse> {
    bsid: number;
    caseDate: Date;
    caseId: string;
    contractor: Contractor;
    customer: Customer;
    externalBsUserId: String;
    services: Service[];
    appUserId: number;
    appUserName: string;
    canceled: boolean;
    caseInternalId: string;
    caseStatus: CaseStatusType;
    deserialize(input) {
        if (input) {
            this.bsid = input.bsId;
            this.caseId = input.caseId;
            this.caseDate = input.caseDate;
            if (input.contractor) {
                this.contractor = new Contractor().deserialize(input.contractor);
            }
            if (input.customer) {
                this.customer = new Customer().deserialize(input.customer);
            }
            if (input.services) {
                this.services = input.services.map((el: Service) => new Service().deserialize(el));
            }

            this.externalBsUserId = input.externalBsUserId;
            this.appUserId = input.appUserId;
            this.appUserName = input.appUserName;
            this.canceled = input.canceled;
            this.caseInternalId = input.caseInternalId;
            this.caseStatus = input.caseStatus;
        }
        return this;
    }
}
