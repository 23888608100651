<div
    class="m-grid__item m-grid__item--fluid m-grid m-grid--hor m-login m-login--signin m-login--2 m-login-2--skin-2"
    id="m_login"
    appunwraptag=""
>
    <div class="m-grid__item m-grid__item--fluid m-login__wrapper" style="display: flex;
    justify-content: center;
    align-items: center;">
        <div class="m-login__container">
                        <div class="m-login__logo">
                            <a href="#">
                                <img src="./assets/app/media/img/logos/logo-forms.svg" width="250" />
                            </a>
                        </div>
            <div class="m-login__signin">
                <form (ngSubmit)="f.form.valid && signin()" #f="ngForm" class="m-login__form m-form">
                    <ng-template #alertSignin></ng-template>

                    <div class="form-group m-form__group">
                        <input
                            class="form-control m-input"
                            type="text"
                            i18n-placeholder="@@bb356c7d-3559-55c2-b4ae-6014dbd63514"
                            placeholder="Uporabniško ime"
                            name="email"
                            autocomplete="off"
                            [(ngModel)]="model.email"
                            #email="ngModel"
                            autocorrect="off"
                            autocapitalize="none"
                        />
                    </div>
                    <div class="form-group m-form__group">
                        <input
                            class="form-control m-input m-login__form-input--last"
                            type="password"
                            placeholder="Geslo"
                            name="password"
                            autocomplete="on"
                            [(ngModel)]="model.password"
                            #password="ngModel"
                        />
                    </div>
                    <div class="m-login__form-action">
                        <button
                            id="m_login_signin_submit"
                            class="btn btn-primary m-btn m-btn--pill m-btn--custom m-btn--air m-login__btn m-login__btn--primary"
                            i18n="@@3577a42460b087ba9da9e70fcf826c5b90a310b8"
                        >
                            Prijava
                        </button>
                    </div>
                </form>
            </div>
            <div class="text-center m--font-primary">v {{ version }}</div>
        </div>
    </div>
</div>
