import { Serializable } from './deserialize.service';

export class PreReservation implements Serializable<PreReservation> {
    aggregateId: string;
    prereservationId: string;
    comment: string;
    userId: string;
    fromNowOn: Date;
    additionalPrereservationId: string;
    onlySelected: boolean;
    constructor(obj?: any) {
        this.aggregateId = (obj && obj.aggregateId) || null;
        this.prereservationId = (obj && obj.prereservationId) || null;
        this.comment = (obj && obj.comment) || null;
        this.userId = (obj && obj.userId) || null;
        this.fromNowOn = (obj && obj.fromNowOn) || null;
        this.additionalPrereservationId = (obj && obj.additionalPrereservationId) || null;
        this.onlySelected = (obj && obj.onlySelected) || null;
    }

    deserialize(input) {
        this.aggregateId = input.aggregateId;
        this.prereservationId = input.prereservationId;
        this.comment = input.comment;
        this.userId = input.userId;
        this.fromNowOn = input.fromNowOn;
        this.additionalPrereservationId = input.additionalPrereservationId;
        this.onlySelected = input.onlySelected;
        return this;
    }
}
