import {Serializable} from '../deserialize.service';
import {SurveyType} from '../../enums/surveyType.enum';

export class ResultUserGuiSettings implements Serializable<ResultUserGuiSettings> {
    type: SurveyType;

    deserialize(input) {
        if (!input || input === null) {
            return null;
        }
        if (input.type == 0) {
            this.type = SurveyType.IZVID;
        } else if (input.type == 1) {
            this.type = SurveyType.FTH_POROCILO;
        }
        return this;
    }
}
