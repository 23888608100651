import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
// components
import {HeaderNavComponent} from './header-nav/header-nav.component';
import {FooterComponent} from './footer/footer.component';
import {TooltipsComponent} from './tooltips/tooltips.component';
import {HomeComponent} from '../pages/home/home.component';
// modules
import {ComponentModule} from '../components/component.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxPermissionsModule} from 'ngx-permissions';
// directives
import {MfAutoFocusDirective} from '../../_directives/mfAutoFocus.directive';
import {HrefPreventDefaultDirective} from '../../_directives/href-prevent-default.directive';
import {UnwrapTagDirective} from '../../_directives/unwrap-tag.directive';

@NgModule({
    declarations: [
        HeaderNavComponent,
        FooterComponent,
        TooltipsComponent,
        HrefPreventDefaultDirective,
        UnwrapTagDirective,
        HomeComponent,
        MfAutoFocusDirective,
    ],
    exports: [
        HeaderNavComponent,
        FooterComponent,
        TooltipsComponent,
        HrefPreventDefaultDirective,
        MfAutoFocusDirective,
    ],
    imports: [CommonModule, RouterModule, ComponentModule, NgxPermissionsModule, NgbModule],
    // entryComponents: [EditTextPopupComponent]
})
export class LayoutModule {}
