import { Store } from '@ngxs/store';
import { Document } from './../../../../../../_shared/model/document.model';
import { takeUntil } from 'rxjs/operators';
import { Case } from './../../../../../../_shared/model/schedule/case.model';
import { TermsService } from './../../../_services/terms.service';
import { CustomerService, DocumentsService, ResultService } from '../../../_services';
import { ActionButton, Event, PortfolioPaper } from '../../../../../../_shared/model';
import { HelperService, MfToastService, StorageService } from '../../../../../../_shared/services';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { MenuService } from '../../../../../../_shared/services/menu.service';
import { Observable, Subject } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import * as _ from 'lodash';
import { AppModulesType } from '../../../../../../_shared/enums/appModulesType.enum';
import { ActiveModulesService } from '../../../../../../_shared/services/activeModules.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthNurseComponent } from '../../../../../components/auth-nurse/auth-nurse.component';
import { SurveysService } from '../../../../../../_shared/services/surveys.service';
import { BaseState } from '../../../../../../_shared/state/base/base.state';
import formioSettings from './formio-settings.json';
import { FormioOptions } from 'angular-formio';

@Component({
    selector: 'app-selected-term',
    templateUrl: './selected-term.component.html',
    styleUrls: ['./selected-term.component.scss'],
})
export class SelectedTermComponent implements OnInit {
    // public termData: Event;
    private params: any;
    public termId;
    public termData: Event;
    public data$: Observable<PortfolioPaper[]>;
    public isCovidModuleActive: boolean = false;
    public justSubmited: boolean;
    public documents: Document[] = [];
    public dataFromParent: any = {};
    private onDestroy$ = new Subject<void>();
    public submitData;
    public consentData;
    public survey;
    public surveyTemplate;
    public surveyList;
    public surveyListArray = [];
    public triggerRefresh;
    public appType;
    public selectedForms;
    public guidData;
    public channelType = 'UNKNOWN';
    public source = '';
    public error;
    public bsId;
    public options: FormioOptions = formioSettings;
    public inputMissing: boolean = false;

    constructor(
        private storage: StorageService,
        private menu: MenuService,
        private rest: ResultService,
        private activeRoute: ActivatedRoute,
        private termsRest: TermsService,
        private toaster: MfToastService,
        private am: ActiveModulesService,
        private docviewRest: DocumentsService,
        private store: Store,
        private modalService: NgbModal,
        private customerRest: CustomerService,
        private surveyRest: SurveysService,
        private router: Router,
        private helper: HelperService,
    ) {}

    ngOnInit() {
        this.store.select(BaseState.appType).subscribe((data) => {
            this.appType = data;
        });
        //hack da skrije popover ce se slucajno ostane
        $('.popover').remove();
        //vzemi preReservationId iz URL -> klic v bazo
        if (this.appType === 'tablet') {
            this.activeRoute.params.subscribe((res) => {
                if (res['prereservationId']) {
                    this.termId = res['prereservationId'];
                    this.termsRest
                        .getReservation(this.storage.getSelectedContractor().id, this.termId)
                        .subscribe((res) => {
                            this.termData = new Event().deserialize(res);
                            this.termData.case = new Case().deserialize({
                                caseId: res.caseId,
                                bsid: res.bsid,
                                caseInternalId: res.caseInternalId,
                            });
                            this.storage.setSelectedTerms(this.termData, this.termData.prereservationId);
                            this.reloadData();
                            // this.menu.showIndexMenu();
                            // this.getDocumentList();
                            // this.getConsent();
                            // this.matchSurveys(21);
                            // let formList = this.surveyList;
                        });
                } else {
                    this.toaster.error('Terminu niste dodali šifre.');
                    // this.termData = [];
                    return;
                }
            });
        }

        if (this.appType === 'browser') {
            this.activeRoute.queryParams.subscribe((res) => {
                if (res?.channel) {
                    this.channelType = res?.channel;
                    this.router.navigate([], {
                        queryParams: {
                            channel: null,
                        },
                        queryParamsHandling: 'merge',
                    });
                    localStorage.setItem('channelType', this.channelType);
                }

                const channel = localStorage.getItem('channelType');
                if (channel) {
                    this.channelType = channel;
                }
            });
            // this.getSurveys();
            this.getGuidSureys();
        }

        this.isCovidModuleActive = this.am.isAM(AppModulesType.COVID);

        const result: ActionButton[] = [
            {
                title: 'Prijava',
                component: this,
                function: 'toggleNurseView',
                buttonStyle: 'btn-outline-info',
            },
        ].map((el) => new ActionButton().deserialize(el));

        this.menu.actionButtonsMenu.next(result);
    }

    public getGuidSureys() {
        this.activeRoute.params.subscribe((route) => {
            this.termId = route['prereservationId'];
            this.surveyRest.getSurveysByGuid(this.termId).subscribe(
                (resGuid) => {
                    const resSource = resGuid;
                    let apiCall;
                    if (resSource?.caseId) {
                        this.bsId = resSource?.bsId;
                        this.source = 'mfa bs-' + resSource?.bsId;
                        apiCall = this.surveyRest.getAssistanceSurveys(resGuid?.bsId);
                    } else if (resSource?.service?.name === 'Halo Doktor') {
                        this.source = 'hd';
                        apiCall = this.surveyRest.getErpSurveys(resGuid?.maskedContractorId);
                    } else {
                        this.source = 'erp';
                        apiCall = this.surveyRest.getErpSurveys(resGuid?.maskedContractorId);
                    }
                    apiCall.subscribe((surRes) => {
                        this.surveyList = surRes;
                        if (this.surveyList?.length >= 1) {
                            this.surveyRest.getPublicCustomerResponses(resGuid?.maskedCustomerId).subscribe((res) => {
                                this.guidData = resGuid;
                                let forms;
                                if (this.channelType === 'SMS' && resGuid?.smsForms) {
                                    forms = resGuid?.smsForms;
                                } else if (this.channelType === 'EMAIL' && resGuid.emailForms) {
                                    forms = resGuid?.emailForms;
                                } else {
                                    forms = resGuid?.emailForms ? resGuid.emailForms : resGuid.smsForms;
                                }
                                // const forms = resGuid.emailForms ? resGuid.emailForms : resGuid.smsForms;
                                let formList;
                                if (!_.isEmpty(forms) && !_.isNil(forms)) {
                                    formList = forms.split(',');
                                    if (formList.length > 1) {
                                        formList.forEach((el2) => {
                                            this.matchSurveys(el2);
                                        });
                                    } else {
                                        this.matchSurveys(formList);
                                    }
                                }
                                res.filter((o) => {
                                    this.surveyListArray.some(({ id }, $key) => {
                                        if (
                                            o?.surveyTemplateId === id &&
                                            ((resGuid?.caseId === o?.authorizationId && this.termId === o.guid) ||
                                                resGuid?.prereservationId === o?.authorizationId)
                                        ) {
                                            this.surveyListArray[$key].completed = true;
                                        }
                                    });
                                });

                                if (!_.isEmpty(this.surveyListArray) && !_.isNil(this.surveyListArray)) {
                                    this.surveyTemplate = JSON.parse(this.surveyListArray[0].surveyTemplate);
                                    this.survey = this.surveyListArray[0];

                                    if (!this.router.url.includes('/form/')) {
                                        const id = btoa(this.surveyListArray[0].id);
                                        this.router.navigate([this.router.url + `/form/${id}`]);
                                    } else {
                                        const surveyId = this.router.url.split('/form/')[1];
                                        this.selectSurvey(surveyId);
                                    }
                                } else {
                                    this.error = {
                                        errHeader: 'Obrazec ni več dostopen.',
                                        errDesc: 'Obrazca ni več mogoče izpolniti ali oddati.',
                                    };
                                }
                            });
                        }
                    });
                },
                (err) => {
                    this.error = {
                        errHeader: 'Napaka pri odpiranju.',
                        errDesc:
                            'Povezava do obrazca ne deluje. Prosimo, da poskusite ponovno kasneje oz. preverite, če je povezava do obrazca pravilna.',
                    };
                },
            );
        });
    }

    ngOnDestroy(): void {
        this.menu.actionButtonsMenu.next([]);
        this.onDestroy$.next();
    }

    public matchSurveys(id) {
        this.surveyList.forEach((el3) => {
            if (Number(id) === el3.id) {
                this.surveyListArray.push({
                    id: Number(id),
                    contractorId: el3.contractorId,
                    bsId: el3.bsId,
                    surveyTemplate: el3.surveyTemplate,
                    name: el3.name,
                    completed: el3.completed,
                    created: el3.created,
                    updated: el3.updated,
                    type: el3.type,
                });
            }
        });
    }

    public reloadData() {
        this.dataFromParent = {
            origin: 'term',
            prereservationId: this.termData.prereservationId,
            visitId: this.termData.visitId,
        };
        if (!_.isNil(this.termData.visitId)) {
            this.data$ = this.rest
                .getVisitPortfolio(this.storage.getSelectedContractor().id, this.termData.visitId)
                .pipe(takeUntil(this.onDestroy$));
        }
        if (_.get(this.termData, 'isBs')) {
            this.refreshData();
        }
    }

    public getDocumentList(): void {
        this.docviewRest.getDocumentsVR(this.termData.prereservationId, this.termData.visitId).subscribe((res) => {
            if (!_.isNil(res)) {
                this.documents = res.map((el) => new Document().deserialize(el));
            }
        });
    }

    public getConsent(): void {
        this.customerRest
            .getCustomerConsent(this.storage.getSelectedContractor().id, this.termData.customer.id)
            .subscribe((res) => {
                this.consentData = { data: res };
            });
    }

    public getErpSurveys(cid) {
        this.surveyRest.getErpSurveys(cid).subscribe((res) => {
            this.surveyList = res;
            this.guidData = res;
        });
    }

    public getAssistanceSurveys(bsid): void {
        this.surveyRest.getAssistanceSurveys(bsid).subscribe((res) => {
            this.surveyList = res;
            // this.getGuidSureys();
        });
    }

    public setBtoa(e) {
        return btoa(e);
    }

    public selectSurvey(e) {
        const atobSurvey = decodeURIComponent(e);
        const surveyId = atob(atobSurvey);
        const sur = this.surveyListArray.filter(function (s) {
            return s.id == surveyId;
        });
        this.survey = sur[0];
        if (this.survey) {
            this.surveyTemplate = JSON.parse(sur[0].surveyTemplate);
        }
        this.triggerRefresh = new EventEmitter();

        this.triggerRefresh.emit({
            form: this.surveyTemplate,
        });
    }

    public toggleNurseView(): void {
        this.modalService.open(AuthNurseComponent, {
            backdropClass: 'mf-second-modal-backdrop',
            size: 'sm',
            windowClass: 'mf-transparent-popup',
        });
    }

    refreshData() {
        this.termData = this.storage.getSelectedTerms(this.termData.prereservationId);
        this.menu.showIndexMenu();
    }

    public saveForm(e, type?): void {
        if (type === 'GDPR') {
            this.saveConsent(e);
            return;
        }
        this.inputMissing = false;
        const data = {
            guid: this.termId,
            surveyTemplateId: this.survey?.id,
            customerGuId: this.guidData?.maskedCustomerId,
            completed: true,
            customerName: this.guidData?.customer?.name,
            survey: JSON.stringify(e?.data),
            surveyName: this.survey.name,
            customerSurname: this.guidData.customer.surname,
            authorizationId: this.guidData?.caseId
                ? this.guidData?.caseId?.toString()
                : this.guidData?.prereservationId?.toString(),
            channel: this.channelType?.toString(),
        };
        this.surveyRest.setAssistancePublicSurveyResponse(data).subscribe(
            (res) => {
                if (res) {
                    // this.toaster.success('Spremembe so shranjene.');
                    this.survey.completed = true;
                    this.justSubmited = true;
                    this.nextForm();
                } else {
                    this.toaster.error('Napaka pri shranjevanju.');
                }
            },
            (error) => {
                this.toaster.error('Napaka pri shranjevanju.');
            },
        );
    }

    public nextForm() {
        if (this.surveyListArray.length > 1) {
            const uncompleted = [];
            this.surveyListArray.map((item) => {
                if (item.completed === false) {
                    uncompleted.push(item);
                }
            });

            if (uncompleted.length > 0) {
                const id = btoa(uncompleted[0].id);
                const url = this.router.url.split('/form/')[0];
                this.selectSurvey(this.setBtoa(uncompleted[0].id));
                this.router.navigate([url + `/form/${id}`]);
            }
        }
    }

    findIndexToUpdate(newItem) {
        return newItem.serviceId === this;
    }

    public saveConsent(e): void {
        debugger;
        this.submitData = e.data;
        this.submitData.insuranceConsent = { canShareData: false };
        this.submitData.informing.title = 'Obveščanje o terminu';
        this.submitData.informing.id = 1;
        this.submitData.survey.title = 'Anketiranje';
        this.submitData.survey.id = 2;
        this.submitData.marketing.title = 'Direktno trženje';
        this.submitData.marketing.id = 3;
        // this.submitData.changeDate = "2020-09-02T14:09:50.513+0200";
        // this.submitData.contractorEmail = "tilen.brezar@medifit.si";
        // this.submitData.contractorName = "FIZIOTERAPIJA MEDIFIT";
        // this.submitData.contractorAddressName = "Celovška";
        // this.submitData.contractorAddressNumber = "12";
        // this.submitData.contractorPostName = "Ljubljanaa";
        // this.submitData.contractorPostNumber = "1102";
        // this.submitData.consentFulfilled = "2020-09-02T12:53:56.471Z";

        this.customerRest.updateCustomerConsent(this.guidData.contractor.externalId, 4391, this.submitData).subscribe(
            () => {
                this.toaster.success('Spremembe so shranjene.');
            },
            () => {
                this.toaster.error('Napaka pri shranjevanju.');
            },
        );
    }

    public formReady(e) {
        const surveyComponents = [];
        const surveyStarsComponents = [];
        // const surveyComponentHeaders = [];
        const componentsHTMLList = e.formioElement.nativeElement.children[0].children[0].children;
        const componentsList = e.formio.components;
        componentsList?.forEach((el, key) => {
            if (el.type === 'survey') {
                surveyComponents.push(key);

                if (el.component.inputType === 'stars') {
                    surveyStarsComponents.push(key);
                }
            }
        });
        surveyComponents?.forEach((surveyIndex) => {
            const selectedSurveyComponent = componentsHTMLList[surveyIndex];
            const selectedSurveyComponentHeaders =
                selectedSurveyComponent.children[1].firstElementChild.children[0].children;
            const surveyComponentHeaders = [];
            selectedSurveyComponentHeaders.forEach((el) => {
                surveyComponentHeaders.push(el.innerHTML);
            });
            const selectedSurveyOptions = selectedSurveyComponent.children[1].children[1].children;
            selectedSurveyOptions?.forEach((el) => {
                if (el.children.length > 0) {
                    el.children.forEach((el2, key) => {
                        const node = document.createElement('label');
                        node.className = 'option-label';
                        if (el2.children.length > 0) {
                            node.htmlFor = el2.children[0].id;
                        }
                        const textnode = surveyComponentHeaders[key];
                        if (textnode.length >= 1) {
                            node.insertAdjacentHTML('beforeend', textnode); //create html from textnode
                            el2.appendChild(node); //append label with option value to input
                        }
                    });
                }
            });
            selectedSurveyComponent.children[1].children[1].className = 'survey-d-block';
        });

        surveyStarsComponents?.forEach((surveyIndex) => {
            const selectedSurveyComponent = componentsHTMLList[surveyIndex];
            const selectedSurveyComponentHeaders =
                selectedSurveyComponent.children[1].firstElementChild.children[0].children;
            const surveyComponentHeaders = [];
            selectedSurveyComponentHeaders.forEach((el) => {
                surveyComponentHeaders.push(el.innerHTML);
            });
            const selectedSurveyOptions = selectedSurveyComponent.children[1].children[1].children;
            selectedSurveyOptions?.forEach((el) => {
                if (el.children.length > 0) {
                    el.children.forEach((el2, key) => {
                        // debugger;
                        if (key > 0) {
                            const node = document.createElement('div');
                            node.className = 'star star-' + key;
                            const textnode = ' ';
                            if (textnode.length >= 1) {
                                node.insertAdjacentHTML('beforeend', textnode); //create html from textnode
                                el2.appendChild(node); //append label with option value to input
                            }
                        }
                    });
                }
            });
            selectedSurveyComponent.children[1].children[1].className = 'survey-d-block stars-rating';
        });
    }

    public formChange(e) {
        console.error(e);
        if (e.target && e.target.parentNode) {
            if (e.target.parentNode.parentNode.parentNode.className.includes('stars-rating')) {
                e.target.parentNode.parentNode.parentNode.className =
                    'survey-d-block stars-rating stars-' + e.target.defaultValue;
            }
        }
    }

    public formError(e) {
        console.error('form error changed');
        console.error('formError function data: ' + e);
        console.error('data length ' + e.length);
        this.inputMissing = e.length >= 1 ? true : false;
    }
}
