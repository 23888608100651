import {BusinessSubject} from '../businessSubject.model';
import {Serializable} from '../deserialize.service';

export class BusinessSubjectConnection implements Serializable<BusinessSubjectConnection> {
    bs: BusinessSubject;
    connected: boolean;
    directBooking: boolean;
    directDraft: boolean;
    draftAllowed: boolean;

    deserialize(input: any) {
        // debugger;
        this.connected = input.connected;
        this.directBooking = input.directBooking;
        this.directDraft = input.directDraft;
        this.draftAllowed = input.draftAllowed;

        if (input.bs) {
            this.bs = new BusinessSubject().deserialize(input.bs);
        }
        return this;
    }
}
