import { Injectable } from '@angular/core';
import { ConfigGUIService } from './../../../theme/pages/home/_services/configGUI.service';
import { CalendarFilterSettings } from './../../model/userGuiSettings/calendarFilterSettings.model';
import { GuiUserContractorSettings } from './../../model/userGuiSettings/guiUserContractorSettings.model';
import { User } from './../../model/user.model';
import { Asset } from './../../model/asset.model';
import { Subcontractor } from './../../model/subcontractor.model';
import { TreatmentState } from './../treatment/treatment.state';
import { Contractor } from './../../model/contractor.model';
import { tap } from 'rxjs/operators';
import { CommonService } from './../../../theme/pages/home/_services/common.service';
import { of } from 'rxjs';
import {
    AddServices,
    SetActiveContractor,
    GetAndLoadServices,
    ClearState,
    ClearServiceCache,
    SetUserSettings,
    SetCalendarFilter,
    SetCalendarSubcontractors,
    GetAndLoadSubcontractors,
    GetAndLoadAssets,
    SetLoggedUser,
    SetDefaultUserSettings,
    SetAppType
} from './base.actions';
import { Service } from './../../model/service.model';
import { BaseStateModel } from './base.model';

import { Action, Selector, State, StateContext, StateToken, createSelector } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import _ from 'lodash';
import { CalendarUserGuiSettings } from '../../model';

const BASESTATE_TOKEN: StateToken<BaseStateModel> = new StateToken('basestate');

const DEFAULT_STATE: BaseStateModel = {
    services: [],
    activeContractor: undefined,
    userGuiSettings: undefined,
    allSubcontractors: [],
    assets: [],
    loggedUser: undefined,
    appType: undefined
};

@State<BaseStateModel>({
    name: BASESTATE_TOKEN,
    defaults: DEFAULT_STATE,
    children: [], //ce bo kdaj prov prislo
})
@Injectable()
export class BaseState {
    constructor(private commonRest: CommonService, private configGUIRest: ConfigGUIService) {}

    static getServices() {
        return createSelector([BaseState], (state: BaseStateModel) => state.services);
    }

    static getAllSubcontractors() {
        return createSelector([BaseState], (state: BaseStateModel) => state.allSubcontractors);
    }

    static getAssets() {
        return createSelector([BaseState], (state: BaseStateModel) => state.assets);
    }

    @Selector()
    public static services(state: BaseStateModel): Service[] {
        return state.services;
    }

    @Selector()
    static GetUserSettings(state: BaseStateModel): GuiUserContractorSettings {
        return state.userGuiSettings;
    }

    @Selector()
    static getState(state: BaseStateModel): BaseStateModel {
        return state;
    }
    @Selector()
    public static activeContractor(state: BaseStateModel): Contractor {
        return state.activeContractor;
    }

    @Selector()
    public static activeContractorId(state: BaseStateModel): number {
        return state.activeContractor.id;
    }

    @Selector()
    public static loggedUser(state: BaseStateModel): User {
        return state.loggedUser;
    }

    @Selector()
    public static appType(state: BaseStateModel): string {
        return state.appType;
    }
    
    @Action(ClearState)
    public ClearState(ctx: StateContext<BaseStateModel>, {}: ClearState) {
        ctx.patchState(DEFAULT_STATE);
    }

    @Action(ClearServiceCache)
    public ClearServiceCache(ctx: StateContext<BaseStateModel>, {}: ClearServiceCache) {
        ctx.patchState({
            services: [],
        });
    }

    @Action(AddServices)
    public AddServices({ setState }: StateContext<BaseStateModel>, { services }: AddServices) {
        setState(
            patch({
                services: services,
            }),
        );
    }

    @Action(GetAndLoadServices)
    public GetAndLoadServices({ getState, patchState }: StateContext<BaseStateModel>) {
        const storeServices: Service[] = getState().services;

        if (storeServices.length > 0) {
            return;
        }
        return this.commonRest.getServicesForContractor(getState().activeContractor.id).pipe(
            tap((res) => {
                patchState({
                    services: res,
                });
            }),
        );
    }

    @Action(SetActiveContractor)
    public SetActiveContractor(ctx: StateContext<BaseStateModel>, { contractor }: SetActiveContractor) {
        ctx.patchState({
            activeContractor: contractor,
        });
    }

    @Action(SetLoggedUser)
    public SetLoggedUser(ctx: StateContext<BaseStateModel>, { user }: SetLoggedUser) {
        ctx.patchState({
            loggedUser: user,
        });
    }

    @Action(GetAndLoadSubcontractors)
    public GetAndLoadSubcontractors({ getState, patchState }: StateContext<BaseStateModel>) {
        const allSubcontractors: Subcontractor[] = getState().allSubcontractors;

        if (allSubcontractors.length > 0) {
            return;
        }
        return this.commonRest.getAllSubcontractors(getState().activeContractor.id).pipe(
            tap((res) => {
                patchState({
                    allSubcontractors: res,
                });
            }),
        );
    }
    @Action(SetCalendarFilter)
    public SetCalendarFilter({ patchState, getState }: StateContext<BaseStateModel>, { filter, timeFrequency }: any) {
        let state = getState();
        let newSettings = _.cloneDeep(state.userGuiSettings);
        newSettings.calendar.filter = new CalendarFilterSettings().deserialize(filter);
        if (timeFrequency) {
            newSettings.calendar.timeFrequency = timeFrequency;
            // this.configGUIRest.updateUserGuiCalendarFrequency(state.activeContractor.id, timeFrequency);
        }
        this.configGUIRest.updateUserContractorGuiSettings(state.activeContractor.id, newSettings);
        patchState({
            userGuiSettings: newSettings,
        });
    }

    @Action(GetAndLoadAssets)
    public GetAndLoadAssets({ getState, patchState }: StateContext<BaseStateModel>) {
        const assets: Asset[] = getState().assets;

        if (assets.length > 0) {
            return;
        }
        return this.commonRest.getAllAssets(getState().activeContractor.id).pipe(
            tap((res) => {
                patchState({
                    assets: res,
                });
            }),
        );
    }

    @Action(SetUserSettings)
    public SetUserSettings({ setState }: StateContext<BaseStateModel>, { userGuiSettings }: SetUserSettings) {
        setState(
            patch({
                userGuiSettings: userGuiSettings,
            }),
        );
    }

    @Action(SetDefaultUserSettings)
    public SetDefaultUserSettings({ setState }: StateContext<BaseStateModel>, { settings }: SetDefaultUserSettings) {
        // set default userGuisettings for calendar
        if (_.isNil(settings)) {
            settings = {};
        }
        settings.calendar = new CalendarUserGuiSettings().deserialize({
            timeFrequency: 25,
            subconstractors: undefined,
            filter: new CalendarFilterSettings().deserialize({
                visible: true,
                showExternalAvailable: false,
                showExternalCustomerAvailable: false,
                showInternal: false,
                timeFrequncy: 25,
                showResources: false,
                showOnlyAvailable: false,
                showWeekend: false,
                showHolidays: false,
                show24h: false,
                showServicesColor: false,
                from: '06:00',
                to: '19:00',
            }),
        });
        setState(
            patch({
                userGuiSettings: settings,
            }),
        );
    }
    @Action(SetCalendarSubcontractors)
    public SetCalendarSubcontractors(ctx: StateContext<BaseStateModel>, { newSelectedSubcontractors }: any) {
        ctx.setState(
            patch<BaseStateModel>({
                userGuiSettings: patch<GuiUserContractorSettings | any>({
                    calendar: patch<CalendarUserGuiSettings>({
                        subconstractors: newSelectedSubcontractors,
                    }),
                }),
            }),
        );
    }

    @Action(SetAppType)
    public SetAppType({ setState }: StateContext<BaseStateModel>, { appType }: SetAppType) {
        setState(
            patch({
            appType: appType
        })
        );
    }
}
