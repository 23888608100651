import { EventEmitter, Injectable, Output, Directive } from '@angular/core';

@Directive()
@Injectable({
    providedIn: 'root',
})
export class QuickSidebarService {
    constructor() {}

    private data: any = {
        isOpen: false,
        value: undefined,
    };

    @Output() onChange: EventEmitter<any> = new EventEmitter();
    @Output() onChangeSeenNotifications: EventEmitter<any> = new EventEmitter();

    toggle(value?: any) {
        this.data.isOpen = !this.data.isOpen;
        this.data.value = value;
        this.onChange.emit(this.data);
    }

    public seenNotificationChange(value?: any): void {
        this.onChangeSeenNotifications.emit(value);
    }
}
