import {Serializable} from './deserialize.service';
import {ResultSet} from './resultSet.model';

export class Result implements Serializable<Result> {
    surveys: ResultSet[];
    diagnose: any;
    diagnoses: any;
    surveyType: any;

    deserialize(input) {
        if (input.surveys) {
            this.surveys = input.surveys.map((el) => new ResultSet().deserialize(el));
        }
        this.diagnose = input.diagnose;
        this.diagnoses = input.diagnoses;
        this.surveyType = input.surveyType;
        return this;
    }
}
// public class Comment {
//     public String id;
//     public Date commentAdded;
//     public String comment;
