import {PaymentType} from '../../../enums/accounting/paymentType.enum';
import * as _ from 'lodash';

import {Serializable} from '../../deserialize.service';

export class IrPaymentTypeInfo implements Serializable<IrPaymentTypeInfo> {
    paymentType: PaymentType;
    amount: number;
    paymentTypeName: string;
    id: string;
    couponId?: number;
    couponLabel: string;

    deserialize(input: any) {
        if (_.isNil(input)) {
            return undefined;
        }

        this.paymentType = input.paymentType;
        this.amount = input.amount;
        this.paymentTypeName = input.paymentTypeName;
        this.id = input.id;
        this.couponId = input.couponId;
        this.couponLabel = input.couponLabel;
        return this;
    }
}
