import {Customer} from './../customer.model';
import * as _ from 'lodash';
import {Serializable} from '../deserialize.service';

export class UnprocessedReservations implements Serializable<UnprocessedReservations> {
    aggregateId: number;
    bsid: number;
    customer: Customer;
    id: number;
    invoiceNumber: number;
    noShow: boolean;
    prereservationId: string;
    reservationFrom: Date;
    status: number;
    subcontractorName: string;
    visitId: number;
    visitNumber: number;

    deserialize(input) {
        if (_.isNil(input)) {
            return undefined;
        }
        return Object.assign(this, input);
    }
}
