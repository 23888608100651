import {Serializable} from '../deserialize.service';

export class ContractorMenuSettings implements Serializable<ContractorMenuSettings> {
    hidden: string[];

    constructor() {}
    deserialize(input: any) {
        if (!input || input === null) {
            return null;
        }
        this.hidden = input.hidden;

        return this;
    }
}
