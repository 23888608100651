import {Customer} from './../customer.model';
import {Serializable} from './../deserialize.service';
import * as moment from 'moment';

export class Treatment implements Serializable<Treatment> {
    id: number;
    description: string;
    customer: Customer;
    created: moment.Moment;
    deserialize(input: any) {
        return Object.assign(this, input);
    }
}
