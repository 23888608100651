import {TokenInterceptor} from '../token.interceptor';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CommonService} from '../theme/pages/home/_services';
import {NotSavedGuard} from './_guards/not-saved.guard';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';

import {AuthRoutingModule} from './auth-routing.routing';
import {AuthComponent} from './auth.component';
import {AlertComponent} from './_directives';
import {LogoutComponent} from './logout/logout.component';
import {AuthGuard} from './_guards';
import {AlertService, AuthenticationService} from './_services';
import {SharedModule} from '../_shared/shared.module';
import {PublicService} from '../_shared/services';

@NgModule({
    declarations: [AuthComponent, AlertComponent, LogoutComponent],
    imports: [CommonModule, FormsModule, HttpClientModule, AuthRoutingModule, SharedModule],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        AuthGuard,
        AlertService,
        AuthenticationService,
        NotSavedGuard,
        CommonService,
        PublicService,
    ],
    entryComponents: [AlertComponent],
})
export class AuthModule {}
