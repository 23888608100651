import { GroupTag } from './settings/groupTag.model';
import { Serializable } from './deserialize.service';
import { Service } from './service.model';
import * as _ from 'lodash';

export class Contractor implements Serializable<Contractor> {
    id: number;
    name: string;
    address: string;
    description: string;
    webId: string;
    services: Service[];
    subcontractors: Contractor[];
    color: string;
    locations: any[];
    phone: string;
    post: string;
    postNumber: string;
    email: string;
    bgColor: string;
    fgColor: string;
    academicTitle: string;
    education: string;
    employmentStart: Date;
    employmentEnd: Date;
    subspecializations: string[];
    groupTags: GroupTag[];
    bpi: string;

    deserialize(input) {
        if (_.isNil(input)) {
            return undefined;
        }
        this.id = input.id;
        this.name = input.name;
        this.address = input.address;
        this.description = input.description;
        this.webId = input.webId;
        this.color = input.color;
        this.locations = input.locations;
        this.phone = input.phone;
        this.post = input.post;
        this.postNumber = input.postNumber;
        this.email = input.email;
        this.bgColor = input.bgColor;
        this.fgColor = input.fgColor;
        this.academicTitle = input.academicTitle;
        this.education = input.education;
        this.employmentStart = input.employmentStart;
        this.employmentEnd = input.employmentEnd;
        this.subspecializations = input.subspecializations;
        this.bpi = input.bpi;

        if (input.groupTags) {
            this.groupTags = input.groupTags.map((conr) => {
                return new GroupTag().deserialize(conr);
            });
        }
        // debugger;
        if (input.services) {
            this.services = input.services.map((conr) => {
                return new Service().deserialize(conr);
            });
        }
        // debugger;
        if (input.subcontractors) {
            this.subcontractors = input.subcontractors.map((conr) => {
                return new Contractor().deserialize(conr);
            });
        }
        // this.services = new Services().deserialize(input.services);

        return this;
    }

    // constructor(obj?: any) {
    //     this.name = obj && obj.name || null;
    //     this.doctorBpi = obj && obj.doctorBpi || null;
    //     this.institutions = obj && obj.institutions || null;
    // }
}
