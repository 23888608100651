import {Serializable} from '../deserialize.service';

export class ContractorGroup implements Serializable<ContractorGroup> {
    contractorId: number;
    userId: number;
    employeeId: number;
    subcontractorId: number;

    deserialize(input) {
        if (!input || input === null) {
            return null;
        }

        Object.keys(input).forEach((key) => (this[key] = input[key]));

        return this;
    }
}
