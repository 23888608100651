import {PrintPOSContractor} from './printPOSContractor.model';
import {Serializable} from '../deserialize.service';
import * as moment from 'moment';

export class PrintPOS implements Serializable<PrintPOS> {
    public contractor: PrintPOSContractor;
    public customer: number;
    public invoiceDetails: string;
    public listOfItems: moment.Moment;
    public payer: moment.Moment;
    public paymentType: moment.Moment;
    public additionalText: string[];
    public issuer: string[];

    deserialize(input: any) {
        this.contractor = input.contractor;
        return this;
    }
}
