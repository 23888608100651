import * as _ from 'lodash';
import {Serializable} from '../../deserialize.service';

export class InvoiceItems implements Serializable<InvoiceItems> {
    discount: number;
    id: number;
    name: string;
    price: number;
    quantity: number;
    tax: number;

    deserialize(input: any) {
        if (_.isNil(input)) {
            return undefined;
        }

        this.discount = input.discount;
        this.id = input.id;
        this.name = input.name;
        this.price = input.price;
        this.quantity = input.quantity;
        this.tax = input.tax;
        return this;
    }
}
