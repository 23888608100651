import { RecurringType } from './../enums/recurringType.enum';
import { ReservationTypes } from './../enums/reservationTypes.enum';
export const tags = {
    CUSTOM_TERMS_TAGS: [
        { name: 'Brez oznake', id: 1, classColor: 'mf-custom-tag' },
        { name: 'Rdeča', id: 2, classColor: 'mf-custom-tag__red' },
        { name: 'Rumena', id: 3, classColor: 'mf-custom-tag__yellow' },
        { name: 'Zelena', id: 4, classColor: 'mf-custom-tag__green' },
    ],
    // RECURRING: [
    //     { name: 'Enkratna rezervacija', id: 1, sid: 'SINGLE' },
    //     { name: 'Vsak dan od ponedeljka do petka', id: 2, sid: 'MONFRI' },
    //     { name: 'Tedensko', id: 3, sid: RecurringType.WEEKLY },
    //     { name: 'Mesečno', id: 4, sid: 'MONTHLY' },
    //     { name: 'Letno', id: 4, sid: 'YEARLY' },
    // ],
    RECURRING_NEW: {
        table: [
            { name: 'Enkratno', id: 1, sid: RecurringType.SINGLE },
            { name: 'Vsak dan od ponedeljka do petka', id: 2, sid: RecurringType.MONFRI },
            { name: 'Vsak dan', id: 3, sid: RecurringType.EVERYDAY },
            { name: 'Tedensko', id: 4, sid: RecurringType.WEEKLY },
            { name: 'Mesečno', id: 5, sid: RecurringType.MONTHLY },
            { name: 'Letno', id: 6, sid: RecurringType.YEARLY },
        ],
        obj: {
            SINGLE: { name: 'Enkratno', id: 1, sid: RecurringType.SINGLE },
            MONFRI: { name: 'Vsak dan od ponedeljka do petka', id: 2, sid: RecurringType.MONFRI },
            EVERYDAY: { name: 'Vsak dan', id: 3, sid: RecurringType.EVERYDAY },
            WEEKLY: { name: 'Tedensko', id: 4, sid: RecurringType.WEEKLY },
            MONTHLY: { name: 'Mesečno', id: 5, sid: RecurringType.MONTHLY },
            YEARLY: { name: 'Letno', id: 6, sid: RecurringType.YEARLY },
        },
    },
    TERM_TYPE: {
        table: [
            { name: 'Rezervacija', id: 1, reservationType: ReservationTypes.RESERVE },
            { name: 'Osnutek', id: 2, reservationType: ReservationTypes.DRAFT },
        ],
        obj: {
            RESERVATION: { name: 'Rezervacija', id: 1, reservationType: ReservationTypes.RESERVE },
            DRAFT: { name: 'Osnutek', id: 2, reservationType: ReservationTypes.DRAFT },
        },
    },
};
