import {Serializable} from './../deserialize.service';

export class GroupTag implements Serializable<GroupTag> {
    id: number;
    name: string;

    deserialize(input: any) {
        this.id = input.id;
        this.name = input.name;
        return this;
    }
}
