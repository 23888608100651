import {Serializable} from '../deserialize.service';

export class WQInfo implements Serializable<WQInfo> {
    public caseid: string;
    public preresrvationid: string;
    public internalid: string;

    deserialize(input: any) {
        this.caseid = input.caseid;
        this.preresrvationid = input.preresrvationid;
        this.internalid = input.internalid;
        return this;
    }
}
