import {VisitNew} from './visitNew.model';
import {EventActions} from '../../enums/eventActions.enum';
// import { FcEvent } from './../fcEvent.model';
import * as _ from 'lodash';
import {Serializable} from '../deserialize.service';

export class VisitReturn implements Serializable<VisitReturn> {
    action: EventActions;
    visitNew: VisitNew;

    deserialize(input) {
        if (_.isNil(input)) {
            return this;
        }
        this.action = input.action;
        this.visitNew = new VisitNew().deserialize(input.visitNew);

        return this;
    }
}
