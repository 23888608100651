import {Serializable} from '../deserialize.service';
import * as moment from 'moment';
import {PriceListElementPut} from './priceListElementPut.model';

export class PriceListPut implements Serializable<PriceListPut> {
    id: number;
    listName: string;
    fromDate: moment.Moment;
    toDate: moment.Moment;
    priceList: PriceListElementPut[];

    deserialize(input: any) {
        this.id = input.id;
        this.listName = input.listName;
        this.fromDate = moment(input.fromDate);
        this.toDate = moment(input.toDate);
        if (input.priceList) {
            this.priceList = input.priceList.map((el: PriceListElementPut) =>
                new PriceListElementPut().deserialize(el),
            );
        } else {
            this.priceList = [];
        }
        return this;
    }
}
