import * as _ from 'lodash';
import {Serializable} from '../deserialize.service';

export class Coupons implements Serializable<Coupons> {
    consumed: boolean;
    consumedTime: Date;
    couponId: string;
    couponValue: number;
    createdTime: Date;
    id: number;
    unused: number;

    deserialize(input) {
        if (_.isNil(input)) {
            return undefined;
        }

        this.id = input.id;
        this.consumed = input.consumed;
        this.consumedTime = input.consumedTime;
        this.couponId = input.couponId;
        this.couponValue = input.couponValue;
        this.createdTime = input.createdTime;
        this.unused = input.unused;

        return this;
    }
}
