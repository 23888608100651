import {CaseStatusPipe} from './../../_shared/pipes/case-status.pipe';
import {CanLeaveModule} from './../../auth/can-leave/can-leave.module';
import {CommTypeDescriptionPipe} from '../../_shared/pipes/comm-type-description.pipe';
import {SweetAlert2Module} from '@sweetalert2/ngx-sweetalert2';
import {ComAppVersionComponent} from './utils/com-app-version/com-app-version.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {Daterangepicker} from 'ng2-daterangepicker';
import {TokenInterceptor} from '../../token.interceptor';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {CustomerService, TermsService,} from '../pages/home/_services';
import {RouterModule} from '@angular/router';
import {NgbDateMomentParserFormatter} from '../../_shared/services/NgbDateMomentParserFormatter.class';
import {NgModule} from '@angular/core';
import {CommonModule, DatePipe,} from '@angular/common';
import {TimeListComponent} from './time-list/time-list.component';
import {TimeListElementComponent} from './time-list/time-list-element/time-list-element.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MfToastService} from '../../_shared/services';
import {TimeListDetailsComponent} from './time-list/time-list-details/time-list-details.component';
import {AuthNurseComponent} from './auth-nurse/auth-nurse.component';

@NgModule({
    declarations: [
        TimeListComponent,
        TimeListDetailsComponent,
        TimeListElementComponent,
        ComAppVersionComponent, //ok
        CommTypeDescriptionPipe, //ok
        CaseStatusPipe, //todo
        AuthNurseComponent, //ok
    ],
    exports: [
        TimeListComponent,
        TimeListDetailsComponent,
        ComAppVersionComponent,
        CommTypeDescriptionPipe,
        CaseStatusPipe,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        RouterModule,
        SweetAlert2Module,
        Daterangepicker,
        NgSelectModule,
        CanLeaveModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        CustomerService,
        {
            provide: NgbDateParserFormatter,
            useValue: new NgbDateMomentParserFormatter(),
        },
        DatePipe,
        MfToastService,
        TermsService,
        CommTypeDescriptionPipe,
        CaseStatusPipe,
    ],
    entryComponents: [
    ],
})
export class ComponentModule {}
