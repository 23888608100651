import {EventActions} from '../../enums/eventActions.enum';
import {FcEvent} from '../fcEvent.model';
import * as _ from 'lodash';
import {Serializable} from '../deserialize.service';

export class EventReturn implements Serializable<EventReturn> {
    action: EventActions;
    fcEvent: FcEvent;
    isOpenReservation: boolean;

    deserialize(input) {
        if (_.isNil(input)) {
            return this;
        }
        this.action = input.action;
        this.fcEvent = new FcEvent().deserialize(input.fcEvent);
        this.isOpenReservation = input.isOpenReservation;
        return this;
    }
}
