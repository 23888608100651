import {PaymentAmount} from './paymentAmount.model';
import * as _ from 'lodash';
import {Serializable} from '../../deserialize.service';

export class DailyReportResponse implements Serializable<DailyReportResponse> {
    payments: PaymentAmount[];

    deserialize(input: any) {
        if (_.isNil(input)) {
            return undefined;
        }
        if (input.payments) {
            this.payments = input.localeCountry.map((el) => new PaymentAmount().deserialize(el));
        }
        return this;
    }
}
