import {Injectable} from '@angular/core';
// models
import {Customer, CustomerHistory} from '../../../../_shared/model';
import {Reservation} from '../../../../_shared/model/reservation.model';
import {Consent} from '../../../../_shared/model/consent.model';
// rxjs
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
// services
import {HttpClientService} from '../../../../_shared/services/http-client.service';
import {HelperService, StorageService} from '../../../../_shared/services';
// other
import * as moment from 'moment';

@Injectable()
export class CustomerService {
    constructor(
        private httpClient: HttpClientService,
        private storage: StorageService,
        private helper: HelperService,
    ) {}

    public findSimilarCustomersCustomer(contractorId: number, customerid: number): Observable<Customer[]> {
        return this.httpClient
            .get(this.helper.getApiUrl() + `/contractors/${contractorId}/customers/${customerid}/similar`)
            .pipe(
                map((el) => {
                    if (el.customers) {
                        return el.customers;
                    }
                    return undefined;
                }),
            );
    }

    //createCustomer()
    public createCustomer(data: any, force?: boolean): Observable<any> {
        let forceString: string = '';

        if (force) {
            forceString = '?force=true';
        }
        return this.httpClient.post(
            this.helper.getApiUrl() +
                '/contractors/' +
                this.storage.getSelectedContractor().id +
                '/customers' +
                forceString,
            data,
        );
    }
    //getCustomers
    public getAllCustomers(contractorId: number): Observable<Customer[]> {
        return this.httpClient.get(this.helper.getApiUrl(2) + '/contractors/' + contractorId + '/customers');
    }
    //getCustomerInfo
    public getCustomer(contractorId: number, customerId: number): Observable<Customer> {
        return this.httpClient.get(
            this.helper.getApiUrl(2) + '/contractors/' + contractorId + '/customers/' + customerId,
        );
    }
    //updateCustomer()
    public updateCustomer(contractorId: number, customerId: number, data: Customer): Observable<any> {
        return this.httpClient.put(
            this.helper.getApiUrl() + '/contractors/' + contractorId + '/customers/' + customerId,
            data,
        );
    }
    //getCustomerVisitsHistory()
    public getCustomerHistory(
        contractorId: number,
        customerId: number,
        from?: moment.Moment,
        to?: moment.Moment,
    ): Observable<CustomerHistory[]> {
        // debugger;
        let url: string = '';
        if (!from || !to) {
            url = this.helper.getVisitUrl() + `/contractors/${contractorId}/customers/${customerId}/visits`;
        } else {
            if (from.toJSON() == null && to.toJSON() == null) {
                url = this.helper.getVisitUrl() + `/contractors/${contractorId}/customers/${customerId}/visits`;
            } else {
                url =
                    this.helper.getVisitUrl() +
                    `/contractors/${contractorId}/customers/${customerId}/visits?from=${from.format(
                        'YYYY-MM-DD',
                    )}&to=${to.format('YYYY-MM-DD')}`;
            }
        }
        return this.httpClient.get(url).pipe(map((res) => res.visits));
    }

    public getCustomerReservations(
        contractorId: number,
        customerId: number,
        from: moment.Moment,
        to: moment.Moment,
    ): Observable<Reservation[]> {
        if ((from === null && to === null) || (from.toJSON() == null && to.toJSON() == null)) {
            return this.httpClient.get(
                this.helper.getApiUrl() + '/contractors/' + contractorId + '/customers/' + customerId + '/reservations',
            );
        } else {
            return this.httpClient.get(
                this.helper.getApiUrl() +
                    '/contractors/' +
                    contractorId +
                    '/customers/' +
                    customerId +
                    '/reservations?from=' +
                    from.format('YYYY-MM-DD') +
                    '&to=' +
                    to.format('YYYY-MM-DD'),
            );
        }
    }

    public getCustomerConsent(contractorId: number, customerId: number): Observable<Consent> {
        return this.httpClient.get(
            this.helper.getApiUrl() + '/contractors/' + contractorId + '/customers/' + customerId + '/consent',
        );
    }

    public updateCustomerConsent(contractorId: number, customerId: number, consent: Consent): Observable<Consent> {
        return this.httpClient.put(
            this.helper.getApiUrl() + '/contractors/' + contractorId + '/customers/' + customerId + '/consent',
            consent,
        );
    }

    public sendConsentMail(contractorId: number, customerId: number): Observable<Consent> {
        return this.httpClient.post(
            this.helper.getApiUrl() + '/contractors/' + contractorId + '/customers/' + customerId + '/consent/mail',
        );
    }

    //deactivateCustomer()
    public deactivateCustomer(contractorId: number, customerId: number): Observable<Consent> {
        return this.httpClient.delete(this.helper.getApiUrl() + `/contractors/${contractorId}/customers/${customerId}`);
    }
    public getCustomerSurveys(contractorId: number): Observable<any> {
        return this.httpClient.get(this.helper.getSurveysUrl() + '/contractors/' + contractorId);
    }
    public getSingleCustomerSurveys(contractorId: number, customerId: number): Observable<any> {
        return this.httpClient.get(
            this.helper.getSurveysUrl() + '/contractors/' + contractorId + '/customers/' + customerId,
        );
    }
}
