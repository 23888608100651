import {Service} from './../service.model';
import {Asset} from './../asset.model';
import {Serializable} from '../deserialize.service';

// import * as moment from 'moment';
export class CustomerHistory implements Serializable<CustomerHistory> {
    aggregateId: string;
    comment: string;
    diagnoseCreated: boolean;
    papers: any[];
    prereservationId: string;
    reservationTime: any;
    subcontractorName: string;
    visitConcluded: any;
    visitDate: any;
    visitService: Service[];
    asset: Asset;

    deserialize(input) {
        this.asset = input.asset;
        this.visitService = input.visitService;
        this.aggregateId = input.aggregateId;
        this.comment = input.comment;
        this.diagnoseCreated = input.diagnoseCreated;
        this.papers = input.papers;
        this.prereservationId = input.prereservationId;
        this.reservationTime = input.reservationTime;
        this.subcontractorName = input.subcontractorName;
        this.visitConcluded = input.visitConcluded;
        this.visitDate = input.visitDate;
        return this;
    }
}
