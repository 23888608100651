import * as _ from 'lodash';

import {Serializable} from '../../deserialize.service';

export class IrLocalePrint implements Serializable<IrLocalePrint> {
    localeCountry: string;
    localeLanguage: string;

    deserialize(input: any) {
        if (_.isNil(input)) {
            return undefined;
        }

        this.localeCountry = input.localeCountry;
        this.localeLanguage = input.localeLanguage;
        return this;
    }
}
