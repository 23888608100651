import {Serializable} from './deserialize.service';

export class Slot implements Serializable<Slot> {
    start: Date;
    end: Date;

    deserialize(input) {
        this.start = input.start;
        this.end = input.end;
        return this;
    }
}
