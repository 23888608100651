import {Serializable} from '../deserialize.service';
import {PaymentType} from '../../enums/accounting/paymentType.enum';

export class ContractorAccoutingSettings implements Serializable<ContractorAccoutingSettings> {
    paymentTypes: PaymentType[];

    constructor() {}
    deserialize(input: any) {
        if (!input || input === null) {
            return null;
        }
        this.paymentTypes = input.paymentTypes;

        return this;
    }
}
