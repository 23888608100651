import * as _ from 'lodash';
import {Serializable} from '../../deserialize.service';

export class InvoiceCustomer implements Serializable<InvoiceCustomer> {
    address: string;
    city: string;
    country: string;
    id: string;
    name: string;
    post: string;
    surname: string;
    taxNo: string;

    deserialize(input: any) {
        if (_.isNil(input)) {
            return undefined;
        }

        this.address = input.address;
        this.city = input.city;
        this.country = input.country;
        this.id = input.id;
        this.name = input.name;
        this.post = input.post;
        this.surname = input.surname;
        this.taxNo = input.taxNo;
        return this;
    }
}
