import { ScriptLoaderService } from './_services/script-loader.service';
import { ActiveModulesService } from './_shared/services/activeModules.service';
import { AppModulesType } from './_shared/enums/appModulesType.enum';
import { Store } from '@ngxs/store';
import { HelperService, MfToastService, RulesService, StorageService } from './_shared/services';
import { AuthenticationService } from './auth/_services';
import { Component, OnInit, ViewEncapsulation, Renderer2, Inject } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, NavigationCancel } from '@angular/router';
import { Helpers } from './helpers';
import * as _ from 'lodash';
import {BaseState} from "./_shared/state/base/base.state";
import {MenuService} from "./_shared/services/menu.service";
import {MenuItem} from "./_shared/model";

@Component({
    selector: 'body',
    templateUrl: './app.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
    private globalBodyClassBase =
        'm-page--loading-non-block m-page--fluid m--skin-  m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--offcanvas m-aside--offcanvas-default m-topbar--on';
    private globalBodyClass: string = '';
    public subMenuList: MenuItem[] = [];
    public appType;
    
    constructor(
        private _router: Router,
        public toastr: MfToastService,
        private rules: RulesService,
        private helper: HelperService,
        private _authService: AuthenticationService,
        private storage: StorageService,
        private store: Store,
        private activeModules: ActiveModulesService,
        private _script: ScriptLoaderService,
        private render: Renderer2,
        public menu: MenuService,
    ) 
    {
        this.toggleMenu();
    }

    ngOnInit() {

        this._script
            .loadScripts(
                'body',
                ['assets/vendors/base/vendors.bundle.js', 'assets/demo/demo5/base/scripts.bundle.js'],
                true,
            )
            .then((result) => {
                Helpers.setLoading(false);
                // optional js to be loaded once
            });
        this.toggleMenu();
        this._router.events.subscribe((route) => {
            this.toggleMenu();

            if (route instanceof NavigationStart) {
                // if (this.helper.isDifferentAppVersion()) {
                //     this.toastr.info('Naložene so bile posodobitve. Potrebna je ponovna prijava.');
                //     this._authService.logout();
                //     this._router.navigate(['/login']);
                // }
                Helpers.setLoading(true);
                // this.render.addClass(document.body, 'm-brand--minimize');
                Helpers.bodyClass(this.globalBodyClass);
            }
            if (route instanceof NavigationCancel) {
                Helpers.setLoading(false);
            }

            if (route instanceof NavigationEnd) {
                Helpers.setLoading(false);
            }
        });

        this.rules.setRules();

    }

    private toggleMenu(): void {
        if (_.get(this.storage.getGuiUserSettings(), 'app.isMenuMinimized', false)) {
            this.globalBodyClass = this.globalBodyClassBase + ' m-brand--minimize m-aside-left--minimize';
        } else {
            this.globalBodyClass = this.globalBodyClassBase;
        }


        this.store.select(BaseState.appType).subscribe((data) => { this.appType = data; });
        this.menu.menuList.subscribe((res) => {
            this.subMenuList = res;
            if (res.length <= 0 && this.appType === 'browser') {
                this.render.removeClass(document.body, 'm-topbar--on');
                this.render.removeClass(document.body, 'm-header--on');
            } else {
                this.render.addClass(document.body, 'm-topbar--on');
                this.render.addClass(document.body, 'm-header--on');
            }
        });
    }
}
