import { CapitalizePipe } from './pipes/capitalize.pipe';
import { DateValidator } from './validators/date.validator';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HelperService, StorageService } from './services';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientService } from './services/http-client.service';
import { NgbDateMomentParserFormatter } from './services/NgbDateMomentParserFormatter.class';
import { HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { ErrorNotifyService } from './services/errorNotify.service';
import { DataPipe } from './pipes/data.pipe';
import { TriageStatusPipe } from './pipes/triage-status.pipe';
import { SettingsTypePipe } from './pipes/settings-type.pipe';
import { TermStatusPipe } from './pipes/term-status.pipe';
import { LinkTypePipe } from './pipes/link-type.pipe';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgxPermissionsModule,
        HttpClientXsrfModule,
    ],
    declarations: [CapitalizePipe, DataPipe, TriageStatusPipe, SettingsTypePipe, TermStatusPipe, LinkTypePipe],
    providers: [
        StorageService,
        HelperService,
        HttpClientService,
        NgbDateMomentParserFormatter,
        DateValidator,
        ErrorNotifyService,
    ],
    exports: [NgxPermissionsModule, DataPipe, TriageStatusPipe, SettingsTypePipe, TermStatusPipe, LinkTypePipe],
})
export class SharedModule {}
