import {Serializable} from '../deserialize.service';

export class PrintPOSBase implements Serializable<PrintPOSBase> {
    public order: number;

    deserialize(input: any) {
        this.order = input.order;

        return this;
    }
}
