import {SendInvoiceNotificationInfo} from './../../../../_shared/model/accounting/invoice/sendInvoiceNotificationInfo.model';
import {CustomerPapersElement} from '../../../../_shared/model';
import {Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClientService} from '../../../../_shared/services/http-client.service';
import {HelperService} from '../../../../_shared/services';

@Injectable()
export class VisitService {
    public NotifyVisitIsConcluded: Subject<any> = new Subject();

    constructor(private httpClient: HttpClientService, private helper: HelperService) {}

    public creatPdfForVisitPaper(visitId: string, data: any): Observable<any> {
        return this.httpClient.getAttachmentPost(this.helper.getPrintServerUrl() + `/visits/${visitId}/termin`, data);
    }

    //createVisitFromPrereservation
    public createVisitFromPrereservation(prereservationId: string, customerId: string | number): Observable<any> {
        return this.httpClient.post(
            this.helper.getVisitUrl() + '/visits/' + prereservationId + '/customer/' + customerId,
        );
    }

    // concludeVisit() /contractors/{contractorid}/visits/{visitid}
    public concludeVisit(contractorId: number, visitid: number): Observable<any> {
        return this.httpClient.put(this.helper.getVisitUrl() + '/contractors/' + contractorId + '/visits/' + visitid);
    }
    //isVisitConcluded()
    public isVisitConcluded(contractorId: number, visitid: number): Observable<any> {
        return this.httpClient.get(
            this.helper.getVisitUrl() + '/contractors/' + contractorId + '/visits/' + visitid + '/concluded',
        );
    }
    //createVisit()
    public createVisit(data: any): Observable<any> {
        // let data = { contractor: contractor, customer: customer, visitTime: visitTime, services: services };
        return this.httpClient.post(this.helper.getVisitUrl() + '/visits', data);
    }

    //getVisit()
    public getVisit(contractorId: number, visitid: string): Observable<any> {
        return this.httpClient.get(this.helper.getVisitUrl() + '/contractors/' + contractorId + '/visits/' + visitid);
    }

    //getVisit()
    public getVisitFromPrereservation(contractorId: number, prereservationid: string): Observable<any> {
        return this.httpClient.get(
            this.helper.getVisitUrl() + `/contractors/${contractorId}/visits/prereservations/${prereservationid}`,
        );
    }

    //createVisitFromPrereservation
    public deleteVisit(visitId: string | number): Observable<any> {
        return this.httpClient.post(this.helper.getVisitUrl() + '/visits/' + visitId);
    }

    //sendDiagnose()
    public sendResult(visitId: string): Observable<any> {
        return this.httpClient.post(this.helper.getVisitUrl() + '/visits/' + visitId + '/diagnosis/mail');
    }

    //sendDiagnose()
    public sendPaper(visitId: string, paperid: number,data: SendInvoiceNotificationInfo ): Observable<any> {
        return this.httpClient.post(this.helper.getVisitUrl() + `/visits/${visitId}/paper/mail/${paperid}`,data);
    }

    //deleteVisitPortfolioPaper()
    public deleteVisitPortfolioPaper(contractorid: number, visitId: number, paperId: number): Observable<any> {
        return this.httpClient.delete(
            this.helper.getVisitUrl() + `/contractors/${contractorid}/visits/${visitId}/portfolio/${paperId}`,
        );
    }

    //getCustomerListsHistory()
    public getCustomerListsHistory(contractorId: number, customerId: number): Observable<CustomerPapersElement[]> {
        return this.httpClient.get(
            this.helper.getVisitUrl() + `/contractors/${contractorId}/customers/${customerId}/papers`,
        );
    }

    // //confirmReservations()
    // public confirmReservations(visitId: string, paperid: number): Observable<any> {
    //     return this.httpClient.post(this.helper.getVisitUrl() + `/confirmPrereservations`);
    // }
}
