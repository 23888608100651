import { ContractorSettings, MfModule } from '../model';
import { StorageService } from './storage.service';
import { AppModulesType } from '../enums/appModulesType.enum';
import { Injectable } from '@angular/core';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ActiveModulesService {
    public allModules: any[] = [
        {
            id: AppModulesType.ACCOUNTING,
            name: 'Accounting',
            menuId: 'ACCOUNTING',
        },
        {
            id: AppModulesType.PATIENT_APP,
            name: 'patientApp',
            menuId: '',
        },
        {
            id: AppModulesType.CASE,
            name: 'patientApp',
            menuId: 'CASE',
        },
        {
            id: AppModulesType.WAITING_QUEUE,
            name: 'patientApp',
            menuId: 'WAITING-QUEUE',
        },
        {
            id: AppModulesType.TRIAGE,
            name: 'eTriaža',
            menuId: 'TRIAGE',
        },
        {
            id: AppModulesType.TREATMENTS,
            name: 'Zdravljenje',
            menuId: 'TREATMENTS',
        },
        {
            id: AppModulesType.TELEMEDICINE,
            name: 'ePosvet',
            menuId: 'TELEMEDICINE',
        },
        {
            id: AppModulesType.INBOX,
            name: 'Inbox',
            menuId: 'INBOX',
        },
        {
            id: AppModulesType.DEVICES_INBOX,
            name: 'Inbox for devices',
            menuId: 'DEVICES_INBOX',
        },
        {
            id: AppModulesType.COVID,
            name: 'Covid',
            menuId: '',
        },
        {
            id: AppModulesType.AVAIL_PARTNERS,
            name: 'AVAIL_PARTNERS',
            menuId: '',
        },
    ];

    private aMChanges$: Subject<any> = new Subject();

    // public activeModules: any[] = [];

    constructor(private storage: StorageService) {}

    /**
     * setActiveModules
     */
    public setActiveModules() {
        let activeModules: any[];
        const cs: ContractorSettings = this.storage.getContractorSettings();
        const savedActiveMod: string[] = _.get(cs, 'activeModules.active', []);

        activeModules = this.allModules.filter((el: MfModule) => {
            if (savedActiveMod.includes(el.id)) {
                return true;
            }
            return false;
        });

        this.storage.setActiveModules(activeModules);
        this.aMChanges$.next(activeModules);
    }

    public getAM(): any {
        return this.storage.getActiveModules();
    }

    public isAM(module: AppModulesType): boolean {
        return this.storage
            .getActiveModules()
            .map((el: MfModule) => el.id)
            .includes(module);
    }

    public getAMOnChange(): Observable<any> {
        return this.aMChanges$;
    }

    // public getAMMenu(): string[] {
    //     return this.storage.getActiveModules().map((el: MfModule) => el.menuId);
    // }
}
