import { TreatmentGUI } from './../../model/treatment/treatmentGUI.model';
import { Treatment } from './../../model/treatment/treatment.model';
import { GenTreatmentFormData } from './../../model/treatment/genTreatmentFormData.model';
import { TreatmentReservationRequest } from '../../model/treatment/treatmentReservationRequest';
import { ReservationRequest } from './../../model/reservationRequest.model';
import { EventNew } from './../../model/schedule/eventNew.model';

export class ClearTreatmentState {
    public static readonly type = '[User] ClearTreatmentState';
}

export namespace TreatmentActions {
    export class CreateNewTreatment {
        public static readonly type = '[Treatment] CreateNewTreatment';
    }

    export class UpdateReservationsTreatment {
        public static readonly type = '[Treatment] UpdateReservationsTreatment';
    }

    export class UpdateTreatment {
        public static readonly type = '[Treatment] UpdateTreatment';
        constructor(public readonly treatment: TreatmentGUI) {}
    }

    export class SetTreatment {
        public static readonly type = '[Term] SetTreatment';
        constructor(public readonly treatment: TreatmentGUI) {}
    }

    export class LoadTreatment {
        public static readonly type = '[Term] LoadTreatment';
        constructor(public readonly treatment: TreatmentGUI) {}
    }
}
export namespace Term {
    export class DeleteTermReservation {
        public static readonly type = '[Term] CreateNewReservation';
        constructor(public readonly term: TreatmentReservationRequest) {}
    }

    export class CreateNewReservations {
        public static readonly type = '[Term] CreateNewReservations';
        constructor(public readonly allTerms: TreatmentReservationRequest[]) {}
    }

    export class AddBaseTerm {
        public static readonly type = '[Term] AddBaseTerm';
        constructor(public readonly baseTerm: ReservationRequest) {}
    }

    export class UpdateBaseTerm {
        public static readonly type = '[Term] UpdateBaseTerm';
        constructor(public readonly baseTerm: ReservationRequest) {}
    }

    export class AddNewTerm {
        public static readonly type = '[Term] AddNewTerm';
        constructor(public readonly newTerm: GenTreatmentFormData) {}
        // constructor(public readonly numberOfNewTerms: number) {}
    }

    export class RemoveTerm {
        public static readonly type = '[Term] RemoveTerm';
        constructor(public readonly term: TreatmentReservationRequest) {}
    }

    export class UpdateTerm {
        public static readonly type = '[Term] UpdateTerm';
        constructor(public readonly term: TreatmentReservationRequest) {}
    }

    export class UpdateAllTerms {
        public static readonly type = '[Term] UpdateAllTerm';
        constructor(public readonly term: ReservationRequest) {}
    }

    export class SetAllTerms {
        public static readonly type = '[Term] SetAllTerms';
        constructor(public readonly terms: TreatmentReservationRequest[]) {}
    }
    export class ToggleDetails {
        public static readonly type = '[Term] ToggleDetails';
        constructor(public readonly termId: string) {}
    }

    export class SetDetailsOpen {
        public static readonly type = '[Term] SetDetailsOpen';
        constructor(public readonly termId: string, public readonly isOpen: boolean) {}
    }

    export class SetAllDetails {
        public static readonly type = '[Term] SetAllDetails';
        constructor(public readonly isOpen: boolean) {}
    }

    export class SetFormValidation {
        public static readonly type = '[Term] FormValidation';
        constructor(public readonly isValid: boolean, public readonly termId: string) {}
    }

    export class UpdateTermParameter {
        public static readonly type = '[Term] UpdateTerm parameter';
        constructor(public readonly name: string, public readonly value: any, public readonly termId: string) {}
    }

    export class CheckTermAvailability {
        public static readonly type = '[Term] Check term availability';
        constructor(public readonly termId: string) {}
    }
}

// export class GetAndLoadSubcontractors {
//     public static readonly type = '[User] GetSubcontractors';
// }
