<div [ngClass]="this.authMode ? 'container col-lg-4 col-sm-6 text-center p-5' : ''">
    <h1 *ngIf="this.authMode" class="p-5">Prijava</h1>
    <div class="m-portlet">
<!--        <div class="m-portlet__head">-->
<!--            <div class="m-portlet__head-caption">-->
<!--                <div class="m-portlet__head-title">-->
<!--                    <h3 class="m-portlet__head-text text-center" i18n="@@19bd5cf597b9fd00fdb4020c47a7ce0bd8f9b448">-->
<!--                    </h3>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->
        <div class="m-portlet__body text-center">
            <form (ngSubmit)="f.form.valid && authenticate()" #f="ngForm" name="myForm" method="post">
                <input class="form-control form-control-lg m-input" name="code" id="code" placeholder="PIN" type="text" autocomplete="off"
                       [(ngModel)]="model.passcode" style="text-security:disc; -webkit-text-security:disc;" />
            </form>
        </div>
    </div>
    <div class="modal-footer">
        <button
            type="button"
            class="m-auto btn m-btn--pill btn-success m-btn m-btn--custom"
            (click)="authenticate()"
            tabindex="8"
            i18n="@@8719e5f3f37cc6a96a4336ad6ed796c743541bd4"
        >
            Prijava
        </button>
        <button
            *ngIf="!this.authMode"
            type="button"
            class="m-auto btn m-btn--pill btn-danger m-btn m-btn--custom"
            (click)="activeModal.close('Close click')"
            tabindex="8"
            i18n="@@8719e5f3f37cc6a96a4336ad6ed796c743541bd4"
        >
            Prekliči
        </button>
    </div>
</div>