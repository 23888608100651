import { NgxPermissionsService, NgxRolesService } from 'ngx-permissions';
import { StorageService } from './storage.service';
import { Injectable } from '@angular/core';

@Injectable()
export class RulesService {
    constructor(
        private storage: StorageService,
        private permissionsService: NgxPermissionsService,
        private rolesService: NgxRolesService,
    ) {}

    private allRules: any = {
        ROLE_OWNER: [
            'index_termsViewAll',
            'terms_addNewTermForAll',
            'customer_details_canEdit',
            'customer_statiscis_view',
            'customer_statiscis_viewRating',
            'customer_statiscis_viewBusiness',
            'customer_documents_view',
            'customer_term_delete',
            'reports_accountingVisible',
            'reports_performaceForSubContractorVisible',
            'reports_dailyTrafficVisible',
            'settings_EditPermissions',
            'caleandar_view_terms__visible',
            //customer_view
            'customer_view_tasks__visible',
            'customer_view_patientapp__visible',
            'customer_view_accounting__visible',
            'customer_view_comments__visible',
            'customer_view_documents__visible',
            'customer_view_portfolio__visible',
            'customer_view_communications__visible',
            'customer_view_details__visible',
            'customer_view_actions__visible',
            'customer_list_booking__visible',
            ////mainmenu/*
            'menu_main_accountingVisible',
            'menu_main_waiting_queue__Visible',
            'menu_main_case__Visible',
            'menu_main_inbox__Visible',
            'menu_main_inbox_device__Visible',
            'menu_main_inbox_eposvet__Visible',
            'menu_main_treatments__Visible',
            'menu_main_triage__Visible',
            'menu_main_reports__Visible',
            'menu_main_settings__Visible',
            'settings_enabled',
            'menu_main_notifications__Visible',
            'menu_main_tasks__Visible',
            'menu_main_customers__Visible',
            'menu_main_communicationVisible',
            'menu_main_calendar__Visible',
            ////mainmenu/settings/*
            'menu_main_settings_profile__Visible',
            'menu_main_settings_changePassword__Visible',
            'menu_main_settings_availability__Visible',
            'menu_main_settings_users__Visible',
            'menu_main_settings_services__Visible',
            'menu_main_settings_clinic__Visible',
            'menu_main_settings_partners__Visible',
            'menu_main_settings_printer__Visible',
            'menu_main_settings_sendContent__Visible',
            'menu_main_settings_accounting__Visible',
        ],
        ROLE_SUBCONTRACTOR: [
            'customer_details_canEdit',
            'caleandar_view_terms__visible',
            'customer_view_patientapp__visible',
            'customer_view_accounting__visible',
            'customer_view_comments__visible',
            'customer_view_portfolio__visible',
            'customer_view_documents__visible',
            'customer_view_communications__visible',
            'customer_view_details__visible',
            'customer_view_tasks__visible',
            'customer_view_actions__visible',
            'customer_list_booking__visible',
            ////mainmenu/*
            'menu_main_waiting_queue__Visible',
            'menu_main_case__Visible',
            'menu_main_inbox__Visible',
            'menu_main_inbox_device__Visible',
            'menu_main_inbox_eposvet__Visible',
            'menu_main_treatments__Visible',
            'menu_main_triage__Visible',
            'menu_main_reports__Visible',
            'menu_main_settings__Visible',
            'settings_enabled',
            'menu_main_notifications__Visible',
            'menu_main_communicationVisible',
            'menu_main_tasks__Visible',
            'menu_main_customers__Visible',
            'menu_main_calendar__Visible',
            ////mainmenu/settings/*
            'menu_main_settings_profile__Visible',
            'menu_main_settings_availability__Visible',
            'menu_main_settings_printer__Visible',
        ],
        ROLE_SEKRETAR: [
            'index_termsViewAll',
            'terms_addNewTermForAll',
            'customer_details_canEdit',
            'customer_statiscis_view',
            'customer_statiscis_viewRating',
            'customer_documents_view',
            'customer_term_delete',
            'menu_main_accountingVisible',
            'reports_performaceForSubContractorVisible',
            'customer_view_patientapp__visible',
            'customer_view_accounting__visible',
            'customer_view_comments__visible',
            'customer_view_documents__visible',
            'customer_view_communications__visible',
            'customer_view_portfolio__visible',
            'customer_view_details__visible',
            'customer_view_tasks__visible',
            'customer_view_actions__visible',
            'customer_list_booking__visible',
            ////mainmenu/*
            'menu_main_waiting_queue__Visible',
            'menu_main_case__Visible',
            'menu_main_inbox__Visible',
            'menu_main_inbox_device__Visible',
            'menu_main_inbox_eposvet__Visible',
            'menu_main_triage__Visible',
            'menu_main_settings__Visible',
            'settings_enabled',
            'menu_main_notifications__Visible',
            'menu_main_communicationVisible',
            'menu_main_tasks__Visible',
            'menu_main_customers__Visible',
            'menu_main_calendar__Visible',
            ////mainmenu/settings/*
            'menu_main_settings_profile__Visible',
            'menu_main_settings_changePassword__Visible',
            'menu_main_settings_users__Visible',
            'menu_main_settings_services__Visible',
            'menu_main_settings_clinic__Visible',
            'menu_main_settings_partners__Visible',
            'menu_main_settings_printer__Visible',
            'menu_main_settings_sendContent__Visible',
            'caleandar_view_terms__visible',
            'menu_main_settings_availability__Visible',
            'menu_main_settings_accounting__Visible',
            'menu_main_treatments__Visible',
        ],
        ROLE_WORKINGHOURS_MANAGER: [
            'customer_view_details__visible',
            'customer_view_communications__visible',
            'terms_addNewTermForAll',
            ////mainmenu/*
            'menu_main_notifications__Visible',
            'menu_main_settings__Visible',
            'settings_enabled',
            'menu_main_communicationVisible',
            'menu_main_tasks__Visible',
            'menu_main_customers__Visible',
            'menu_main_calendar__Visible',
            'customer_list_booking__visible',
            ////mainmenu/settings/*
            'menu_main_settings_profile__Visible',
            'menu_main_settings_changePassword__Visible',
            'caleandar_view_terms__visible',
            'index_termsViewAll',
            'customer_details_canEdit',
            'menu_main_treatments__Visible',
        ],
        ROLE_FRANCHISE_MANAGER: [
            ////mainmenu/*
            'menu_main_accountingVisible',
            'menu_main_reports__Visible',
            'reports_accountingVisible',
            'reports_performaceForSubContractorVisible',
            'reports_dailyTrafficVisible',
            // 'menu_main_settings_accounting__Visible',
            'customer_view_accounting__visible',
            'customer_list_booking__visible',
        ],
        ROLE_RESTRICTED_SUBCONTRACTOR: [
            //mainmenu
            'menu_main_calendar__Visible',
            'menu_main_customers__Visible',
            'menu_main_communicationVisible',
            'menu_main_reports__Visible',
            //customerView
            'customer_view_patientapp__visible',
            'customer_view_accounting__visible',
            'customer_view_comments__visible',
            'customer_view_portfolio__visible',
            'customer_view_documents__visible',
            'customer_view_communications__visible',
            'customer_view_details__visible',
            'customer_view_tasks__visible',
            'menu_main_settings_profile__Visible',
            'settings_enabled',
        ],
    };

    public setRules(): void {
        let roles = this.storage.getUserRoles();
        if (roles === null) {
            return;
        }
        if (roles) {
            let userPermissions = [];
            roles.map((el) => {
                userPermissions.push(...this.allRules[el]);
            });
            this.permissionsService.loadPermissions(userPermissions);
        }
    }
}
