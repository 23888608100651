import {PayerType} from './../../enums/accounting/payerType.enum';
import {InvoicePartner} from './invoicePartner.model';
import * as _ from 'lodash';
import {Serializable} from '../deserialize.service';

export class Payer implements Serializable<Payer> {
    id: number;
    name: string;
    surname: string;
    address: string;
    city: string;
    country: string;
    phone: string;
    email: string;
    post: string;
    taxNo: string;
    medifitId: number;
    type: PayerType;
    deserialize(input) {
        if (_.isNil(input)) {
            return undefined;
        }
        return Object.assign(this, input);
        // this.id = input.id;
        // this.name = input.name;
        // this.surname = input.surname;
        // this.address = input.address;
        // this.city = input.city;
        // this.country = input.country;
        // this.phone = input.phone;
        // this.email = input.email;
        // this.post = input.post;
        // this.taxNo = input.taxNo;
        // this.medifitId = input.medifitId;
        // this.type = input.type;
        // return this;
    }

    public deserializeFromInvoicePartner(input: InvoicePartner): Payer {
        this.id = input.id;
        this.name = input.name;
        this.surname = input.description;
        this.address = input.addressName + ' ' + input.addressNumber;
        this.city = input.postName;
        this.post = input.postNumber;
        this.taxNo = input.taxId;
        this.type = PayerType.PARTNER;
        return this;
    }
}
