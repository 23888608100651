import {Task} from '../../../../_shared/model';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import 'rxjs';
import {HttpClientService} from '../../../../_shared/services/http-client.service';
import {HelperService, StorageService} from '../../../../_shared/services';

@Injectable()
export class TaskService {
    constructor(
        private httpClient: HttpClientService,
        private storage: StorageService,
        private helper: HelperService,
    ) {}

    public addRVTask(contractorId: number, task: Task, prereservationId: string, visitId?: number): Observable<any> {
        if (visitId) {
            return this.addVisitTask(contractorId, task, visitId);
        } else {
            return this.addReservationTask(contractorId, task, prereservationId);
        }
    }

    public addReservationTask(contractorId: number, task: Task, prereservationId: string): Observable<any> {
        return this.httpClient.post(
            this.helper.getTaskUrl() + '/contractors/' + contractorId + '/reservations/' + prereservationId,
            task,
            {
                responseType: 'text',
            },
        );
    }

    public addVisitTask(contractorId: number, task: Task, visitId: number): Observable<any> {
        return this.httpClient.post(
            this.helper.getTaskUrl() + '/contractors/' + contractorId + '/visits/' + visitId,
            task,
            {
                responseType: 'text',
            },
        );
    }

    public getTasksRV(contractorId: number, prereservationId: string, visitId?: number): Observable<Task[]> {
        if (visitId) {
            return this.getTasksVisit(contractorId, visitId);
        } else {
            return this.getTasksReservation(contractorId, prereservationId);
        }
    }

    public getTasksReservation(contractorId: number, prereservationId: string): Observable<Task[]> {
        return this.httpClient.get(
            this.helper.getTaskUrl() + '/contractors/' + contractorId + '/reservations/' + prereservationId,
        );
    }

    public getTasksVisit(contractorId: number, visitId: number): Observable<Task[]> {
        return this.httpClient.get(this.helper.getTaskUrl() + '/contractors/' + contractorId + '/visits/' + visitId);
    }
    // za v se isti klic
    //concludeTask()
    public concludeTask(contractorId: number, taskId: number): Observable<Task[]> {
        return this.httpClient.put(this.helper.getTaskUrl() + '/contractors/' + contractorId + '/tasks/' + taskId, {
            responseType: 'text',
        });
    }
    // /contractors/{contractorId}/customers/{customerId}/tasks
    //getCustumerTasks()
    public getCustomerTasks(contractorId: number, customerId: number): Observable<Task[]> {
        return this.httpClient.get(
            this.helper.getTaskUrl() + '/contractors/' + contractorId + '/customers/' + customerId + '/tasks',
        );
    }

    public getContractorTasks(contractorId: number, viewConcluded?: boolean): Observable<Task[]> {
        return this.httpClient.get(
            this.helper.getTaskUrl() + '/contractors/' + contractorId + '/tasks?concluded=' + (viewConcluded || false),
            {
                responseType: 'json',
            },
        );
    }

    public addCustomerTask(contractorId: number, customerId: number, task: Task): Observable<any> {
        return this.httpClient.post(
            this.helper.getTaskUrl() + '/contractors/' + contractorId + '/customers/' + customerId + '/tasks',
            task,
        );
    }

    public editCustomerTask(contractorId: number, customerId: number, task: Task): Observable<any> {
        return this.httpClient.put(
            this.helper.getTaskUrl() + '/contractors/' + contractorId + '/customers/' + customerId + '/tasks',
            task,
        );
    }

    public addTask(contractorId: number, task: Task): Observable<any> {
        return this.httpClient.post(this.helper.getTaskUrl() + '/contractors/' + contractorId + '/tasks', task);
    }

    public editTask(contractorId: number, task: Task): Observable<any> {
        return this.httpClient.put(this.helper.getTaskUrl() + '/contractors/' + contractorId + '/tasks', task);
    }

    public deleteTask(contractorId: number, taskId: number): Observable<any> {
        return this.httpClient.delete(this.helper.getTaskUrl() + '/contractors/' + contractorId + '/tasks/' + taskId);
    }
}
