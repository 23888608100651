import {HttpClientService} from '../../../../_shared/services/http-client.service';
import {HelperService} from '../../../../_shared/services';
import {Injectable} from "@angular/core";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root',
})
export class SurveyService {
    constructor(private helper: HelperService, private httpClient: HttpClientService) {}

    // getSurveyResultsForVisit()
    public getSurveyResultsForVisit(visitId: number): Observable<any> {
        return this.httpClient.get(this.helper.getSurveysUrl() + '/visits/' + visitId);
    }

    public getSurveyResults(queueid: string): Observable<any> {
        return this.httpClient.get(this.helper.getSurveysUrl() + `/queue/${queueid}`);
    }
}
