import {DOCUMENT} from '@angular/common';
import {Contractor} from './../_shared/model/contractor.model';
import {
    ClearState,
    SetActiveContractor,
    SetDefaultUserSettings,
    SetLoggedUser,
    SetUserSettings,
} from './../_shared/state/base/base.actions';
import {ContractorGroup} from './../_shared/model/utils/contractorGroup.model';
import {GuiUserSettings} from './../_shared/model/userGuiSettings/guiUserSettings.model';
import {catchError, filter, map, mergeMap, tap} from 'rxjs/operators';
import {ActiveModulesService} from '../_shared/services/activeModules.service';
import {ConfigGUIService} from '../theme/pages/home/_services/configGUI.service';
import {ContractorSettings, GuiUserContractorSettings, User} from '../_shared/model';
import {HelperService, MfToastService, PublicService, RulesService, StorageService} from '../_shared/services';
import {CommonService} from '../theme/pages/home/_services';
import {Helpers} from '../helpers';
import {
    Component,
    ComponentFactoryResolver,
    Inject,
    OnInit,
    Renderer2,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation,
} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ScriptLoaderService} from '../_services/script-loader.service';
import {AlertService, AuthenticationService} from './_services';
import {AlertComponent} from './_directives';
import {LoginCustom} from './_helpers/login-custom';
import {environment} from '../../environments/environment';
import * as _ from 'lodash';
import {forkJoin, throwError} from 'rxjs';
import {Store} from '@ngxs/store';

@Component({
    selector: '.m-grid.m-grid--hor.m-grid--root.m-page',
    templateUrl: './templates/login.component.html',
    styleUrls: ['./templates/login.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AuthComponent implements OnInit {
    model: any = {};
    loading = false;
    returnUrl: string;
    public version: string = this.helper.getAppVersion();
    public isTest: boolean = environment.test;

    @ViewChild('alertSignin', { read: ViewContainerRef, static: true })
    alertSignin: ViewContainerRef;
    @ViewChild('alertSignup', { read: ViewContainerRef })
    alertSignup: ViewContainerRef;
    @ViewChild('alertForgotPass', { read: ViewContainerRef })
    alertForgotPass: ViewContainerRef;

    public isCaptcha: boolean = false;
    public isCaptchaSolved: boolean = true;
    public isPasswordResetCaptchaSolved: boolean = false;
    public passwordResetCaptchaSolution: any = null;

    constructor(
        private _router: Router,
        private _script: ScriptLoaderService,
        private _route: ActivatedRoute,
        private _authService: AuthenticationService,
        private _alertService: AlertService,
        private cfr: ComponentFactoryResolver,
        private _storage: StorageService,
        private rules: RulesService,
        private toast: MfToastService,
        private helper: HelperService,
        private publicRest: PublicService,
        private commonRest: CommonService,
        private configGUIRest: ConfigGUIService,
        private activeModules: ActiveModulesService,
        private store: Store,
        private _renderer: Renderer2,
        @Inject(DOCUMENT) private _document,
    ) {}

    ngOnInit() {
        this.clearStorage();
        // this._storage.deleteAll();
        //this._storage.clearToken();
        // debugger;
        this.model.remember = false;
        // get return url from route parameters or default to '/'
        this.returnUrl = this._route.snapshot.queryParams['returnUrl'] || '/login?returnUrl=%2Findex';
        // this._router.navigate([this.returnUrl]);

        this._script
            .loadScripts(
                'body',
                ['assets/vendors/base/vendors.bundle.js', 'assets/demo/demo5/base/scripts.bundle.js'],
                true,
            )
            .then(() => {
                Helpers.setLoading(false);
                LoginCustom.init();
            });
    }
    // private id = '5ef99f259e5f694422917d71';
    // private tawk(): void {
    //     const user: User = this.store.selectSnapshot(BaseState.loggedUser);
    //     const contr: Contractor = this.store.selectSnapshot(BaseState.activeContractor);
    //     if ((<any>window).Tawk_API) {
    //         (<any>window).Tawk_API.setAttributes(
    //             {
    //                 name: user?.name,
    //                 email: user?.email,
    //                 contractor: contr?.name,
    //                 // hash: 'd41e715c4bc7cc73ef795dd4e815981caa1f3aa7d9580c1df478595f031ce489',
    //             },
    //             function (error) {
    //                 // if (error) {
    //                 //     console.error(`MF TAWK - error: ${error}`);
    //                 // }
    //             },
    //         );
    //     }
    // }
    signin() {
        //varnost skrit input
        if (this.model.username) {
            return;
        }

        if (!this.isCaptchaSolved) {
            this.toast.error('Dodatno preverjanje ni bilo uspešno!');
            return;
        }

        this.loading = true;
        Helpers.setLoading(true);

        this._authService
            .login(this.model.email, this.model.password)
            .pipe(
                filter((canLogin) => canLogin),
                mergeMap(() => {
                    return forkJoin({
                        userSettings: this.configGUIRest.getUserGuiSettings(),
                        userData: this.commonRest.getUserData(),
                    }).pipe(
                        tap(() => {
                            this._storage.deleteAll();
                            this._storage.deleteUserData();
                            this._storage.setAppData({
                                version: this.version,
                            });
                        }),
                        map((res: { userSettings: GuiUserSettings; userData: User }) => {
                            //nastavimo userdata
                            let selectedContractorGroup: ContractorGroup = undefined;
                            //Vzamemo zadnjega izbranega contractorja, ce ga ni vzamemo tega ki je prisel z klicem
                            if (!_.isNil(res.userSettings)) {
                                selectedContractorGroup = res.userData.userContractors.find(
                                    (el) => el.contractorId === res.userSettings.lastSelectedContractorId,
                                );
                                //če contractorja nismo našli je bil uporabnik pri njem izbrisan (vzemi naslednjega contractorja)
                                if (
                                    _.isNil(selectedContractorGroup) &&
                                    _.get(res, 'userData.userContractors.length') > 0
                                ) {
                                    selectedContractorGroup = res.userData.userContractors[0];
                                }
                            } else {
                                selectedContractorGroup = res.userData.userContractors.find(
                                    (el) => el.contractorId === res.userData.contractorId,
                                );
                            }

                            res.userData.contractorId = selectedContractorGroup.contractorId;

                            _.assign(
                                res.userData,
                                { employeeId: selectedContractorGroup.employeeId },
                                { subcontractorId: selectedContractorGroup.subcontractorId },
                            );

                            const loggedUser: User = new User().deserialize(res.userData);
                            loggedUser.username = this.model.email;
                            this._storage.setUserData(loggedUser);
                            const selectedContractor: Contractor = res.userData.contractors.find(
                                (el) => el.id === res.userData.contractorId,
                            );
                            this._storage.setSelectedContractor(selectedContractor);
                            this._storage.authNurse();
                            this.store.dispatch([
                                new SetActiveContractor(selectedContractor),
                                new SetLoggedUser(loggedUser),
                            ]);
                            return res.userData.contractorId;
                        }),
                        mergeMap((contractorId) => {
                            return forkJoin([
                                this.commonRest.getUserRoles(contractorId).pipe(
                                    tap((roles: string[]) => {
                                        if (roles.length < 1) {
                                            throw new Error('Nimate pravice za prijavo!');
                                        }
                                        this.rules.setRules();
                                    }),
                                    catchError((error) => {
                                        _.set(error, 'error.error_description', 'ROLES_ERROR');
                                        this.toast.error(error.message);
                                        return throwError(error);
                                    }),
                                ),
                                this.configGUIRest.getUserContractorGuiSettings(contractorId).pipe(
                                    tap((userSettings) => {
                                        if (_.get(userSettings, 'calendar.filter')) {
                                            let settings = new GuiUserContractorSettings().deserialize(userSettings);
                                            this._storage.setGuiUserContractorSettings(settings);
                                            this.store.dispatch(new SetUserSettings(settings));
                                        } else {
                                            this.store.dispatch(new SetDefaultUserSettings(userSettings));
                                        }
                                    }),
                                ),
                                this.configGUIRest.getContractorGuiSettings(contractorId).pipe(
                                    tap((contractorSettings) => {
                                        if (contractorSettings) {
                                            this._storage.setContractorSettings(
                                                new ContractorSettings().deserialize(contractorSettings),
                                            );
                                            this.activeModules.setActiveModules();
                                        }
                                    }),
                                ),
                            ]);
                        }),
                    );
                }),
            )
            .subscribe(
                () => {
                    // .pipe(takeUntil(this.onDestroy$));
                    this.loading = false;
                    Helpers.setLoading(false);
                    // this.tawk();
                    this._router.navigate([this.returnUrl]);
                },
                (error) => {
                    this.isCaptcha = false;
                    this.isCaptchaSolved = true;
                    this.showAlert('alertSignin');
                    if (error.error.error_description === 'CaptchaNeeded') {
                        this.isCaptcha = true;
                        this.isCaptchaSolved = false;
                        this._alertService.error('Zahtevano je bilo dodatno preverjanje uporabnika!');
                    } else if (
                        _.get(error, 'error.error', '') === 'unauthorized' ||
                        _.get(error, 'error.error', '') === 'invalid_grant'
                    ) {
                        this._alertService.error('Napačno uporabniško ime ali geslo.');
                    } else {
                        console.error(error);
                        this._alertService.error('Napaka pri prijavi.');
                    }
                    Helpers.setLoading(false);
                    this.loading = false;
                },
            );
    }

    signup() {}

    forgotPass() {
        //varnost skrit input
        if (this.model.username) {
            return;
        }

        if (!this.passwordResetCaptchaSolution) {
            this.toast.error('Napaka pri preverjanju slike!');
            return;
        }

        this.loading = true;
        this.publicRest
            .forgottenPwd({
                username: this.model.email,
                guid: this.passwordResetCaptchaSolution.guid,
                x: this.passwordResetCaptchaSolution.x,
                y: this.passwordResetCaptchaSolution.y,
            })
            .subscribe(
                () => {
                    this.isPasswordResetCaptchaSolved = false;
                    this.passwordResetCaptchaSolution = null;
                    this.showAlert('alertSignin');
                    this.loading = false;
                    LoginCustom.displaySignInForm();
                    this.model = {};
                    this.toast.success(
                        'Če med uporabniki obstaja račun z navedenim elektronskim naslovom, bo nanj poslana povezava za ponastavitev gesla.',
                    );
                },
                (error) => {
                    this.isPasswordResetCaptchaSolved = false;
                    this.passwordResetCaptchaSolution = null;
                    if (error.error.errors[0].code === 'invalidCaptcha') {
                        this.toast.error('Napaka pri preverjanju slike!');
                    } else {
                        this.toast.error('Napaka pri spremembi gesla.');
                    }
                    this.loading = false;
                    Helpers.setLoading(false);
                },
            );
    }

    showAlert(target) {
        this[target].clear();
        let factory = this.cfr.resolveComponentFactory(AlertComponent);
        let ref = this[target].createComponent(factory);
        ref.changeDetectorRef.detectChanges();
    }

    public captchaLoginSolver(event) {
        this.publicRest.solveUserCaptcha(this.model.email, event.guid, event.x, event.y).subscribe(
            (response) => {
                if (!response) {
                    //this.loadCaptcha();
                    this.isCaptchaSolved = false;
                } else {
                    this.isCaptchaSolved = true;
                }
            },
            (error) => {
                this.isCaptchaSolved = false;
            },
        );
    }

    public captchaPasswordResetSolver(event) {
        this.isPasswordResetCaptchaSolved = true;
        this.passwordResetCaptchaSolution = event;
    }

    private clearStorage(): void {
        this.store.dispatch(new ClearState());
        this._storage.deleteAvailibilityData();
        this._storage.deleteActiveModules();
        this._storage.deleteSelectedTerms();
        this._authService.logout();
    }
}
