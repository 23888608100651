import {Serializable} from '../deserialize.service';

export class PrintPOSItem implements Serializable<PrintPOSItem> {
    public id: string;
    public payer: string;
    public description: string;
    public quantity: number;
    //price per item
    public pricePI: number;
    public priceSum: number;
    public discount: number;
    public medicalService: boolean;
    public tax: number;

    deserialize(input: any) {
        this.id = input.id;
        this.payer = input.payer;
        this.description = input.description;
        this.quantity = input.quantity;
        this.pricePI = input.pricePI;
        this.priceSum = input.priceSum;
        this.discount = input.discount;
        this.medicalService = input.medicalService;
        this.tax = input.tax;
        return this;
    }
}
