import {GuiUserSettings} from './../../../../_shared/model/userGuiSettings/guiUserSettings.model';
import {
    CalendarFilterSettings,
    CalendarUserGuiSettings,
    Contractor,
    ContractorSettings,
    GuiUserContractorSettings,
    ResultUserGuiSettings,
} from '../../../../_shared/model';
import {HttpClientService} from '../../../../_shared/services/http-client.service';
import {Observable, Subject} from 'rxjs';
import {HelperService, StorageService} from '../../../../_shared/services';
import {Injectable} from '@angular/core';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class ConfigGUIService {
    constructor(
        private storage: StorageService,
        private helper: HelperService,
        private httpClient: HttpClientService,
    ) {}

    public onToggleMenu: Subject<boolean> = new Subject();

    public updateUserGuiAppSettings(cid: number, termsAndConditions: any): boolean {
        // debugger;
        let data: GuiUserContractorSettings = this.storage.getGuiUserSettings();
        if (data === null && !data) {
            data = new GuiUserContractorSettings();
        }
        data.app.termsAndConditions = termsAndConditions;
        return this.updateUserContractorGuiSettings(cid, data);
    }

    public updateUserGuiAppSettingsToggleMenu(cid: number): boolean {
        let data: GuiUserContractorSettings = this.storage.getGuiUserSettings();
        if (data === null && !data) {
            data = new GuiUserContractorSettings();
        }
        _.set(data, 'app.isMenuMinimized', !_.get(data, 'app.isMenuMinimized', true));
        // tukaj je treba takoj shranit
        this.storage.setGuiUserContractorSettings(data);
        this.onToggleMenu.next(_.get(data, 'app.isMenuMinimized', true));
        return this.updateUserContractorGuiSettings(cid, data);
    }

    public updateUserGuiPrinterPOSPaperType(cid: number, type: number): boolean {
        // debugger;
        let data: GuiUserContractorSettings = this.storage.getGuiUserSettings();
        if (data === null && !data) {
            data = new GuiUserContractorSettings();
        }
        _.set(data, 'config.printer.paperPOSType', type);
        return this.updateUserContractorGuiSettings(cid, data);
    }

    public updateUserGuiPrinterPOSActive(cid: number, active: boolean, paperType: number): boolean {
        // debugger;
        let data: GuiUserContractorSettings = this.storage.getGuiUserSettings();
        if (data === null && !data) {
            data = new GuiUserContractorSettings();
        }
        _.set(data, 'config.printer.isPOSPrinterActive', active);
        _.set(data, 'config.printer.paperPOSType', paperType);

        return this.updateUserContractorGuiSettings(cid, data);
    }

    public updateUserGuiCalendarFrequency(cid: number, timeFrequency: number): boolean {
        // debugger;
        let data: GuiUserContractorSettings = this.storage.getGuiUserSettings();
        if (data === null && !data) {
            data = new GuiUserContractorSettings();
        }
        data.calendar.timeFrequency = timeFrequency;
        return this.updateUserContractorGuiSettings(cid, data);
    }

    public updateUserGuiCalendarFilter(cid: number, guiSettings: CalendarFilterSettings): boolean {
        // debugger;
        let data: GuiUserContractorSettings = this.storage.getGuiUserSettings();
        if (data === null && !data) {
            data = new GuiUserContractorSettings();
        }
        data.calendar.filter = guiSettings;
        return this.updateUserContractorGuiSettings(cid, data);
    }

    public updateUserGuiCalendarSubcontractors(cid: number, guiSettings: Contractor[]): boolean {
        let data: GuiUserContractorSettings = this.storage.getGuiUserSettings();
        if (data === null && !data) {
            data = new GuiUserContractorSettings();
        }
        data.calendar.subconstractors = guiSettings;
        return this.updateUserContractorGuiSettings(cid, data);
    }

    public updateUserGuiCalendar(cid: number, guiSettings: CalendarUserGuiSettings): boolean {
        let data: GuiUserContractorSettings = this.storage.getGuiUserSettings();
        if (data === null && !data) {
            data = new GuiUserContractorSettings().deserialize({
                calendar: {},
            });
        }
        data.calendar = guiSettings;
        return this.updateUserContractorGuiSettings(cid, data);
    }

    public updateUserContractorGuiSettings(cid: number, guiSettings: GuiUserContractorSettings): boolean {
        let res: boolean = true;
        this.httpClient.put(this.helper.getApiUrl() + '/app/settings/' + cid, guiSettings).subscribe(
            () => {
                this.storage.setGuiUserContractorSettings(guiSettings);
                res = true;
            },
            () => {
                res = false;
            },
        );
        return res;
    }

    public updateUserGuiResult(cid: number, guiSettings: ResultUserGuiSettings): boolean {
        let data: GuiUserContractorSettings = this.storage.getGuiUserSettings();
        if (data === null && !data) {
            data = new GuiUserContractorSettings().deserialize({
                result: {},
            });
        }
        data.result = guiSettings;
        return this.updateUserContractorGuiSettings(cid, data);
    }

    public updateUserGuiSettings(guiSettings: GuiUserSettings): boolean {
        let res: boolean = true;
        this.httpClient.put(this.helper.getApiUrl() + `/app/settings/user`, guiSettings).subscribe(
            () => {
                this.storage.setUserGuiSettings(guiSettings);
                res = true;
            },
            () => {
                res = false;
            },
        );
        return res;
    }

    public getUserGuiSettings(): Observable<GuiUserSettings> {
        return this.httpClient.get(this.helper.getApiUrl() + `/app/settings/user`);
    }

    public getUserContractorGuiSettings(cid: number): Observable<GuiUserContractorSettings> {
        return this.httpClient.get(this.helper.getApiUrl() + `/app/settings/${cid}`);
    }

    public getGlobalSettings(): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl() + `/app/settings/`);
    }

    public getContractorGuiSettings(cid: number): Observable<ContractorSettings> {
        return this.httpClient.get(this.helper.getApiUrl() + `/app/settings/contractor/${cid}`);
    }

    public setContractorGuiSetting(cid: number, data: any): Observable<ContractorSettings> {
        return this.httpClient.put(this.helper.getApiUrl() + `/app/settings/contractor/${cid}`, data);
    }
}
