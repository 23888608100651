import {InvoiceItems} from './invoiceItems.model';
import {InvoiceCustomer} from './invoiceCustomer.model';
import {InvoiceContractor} from './invoiceContractor.model';
import * as _ from 'lodash';
import {Serializable} from '../../deserialize.service';

export class InvoiceDetails implements Serializable<InvoiceDetails> {
    contractor: InvoiceContractor;
    customer: InvoiceCustomer;
    invoiceItems: InvoiceItems[];
    prereservationId: string;
    visitDateEnd: string;
    visitDateStart: string;
    visitId: string;

    deserialize(input: any) {
        if (_.isNil(input)) {
            return undefined;
        }
        // debugger;

        this.contractor = new InvoiceContractor().deserialize(input.contractor);
        this.customer = new InvoiceCustomer().deserialize(input.customer);
        if (_.has(input, 'invoiceItems')) {
            this.invoiceItems = input.invoiceItems.map((el: InvoiceItems) => new InvoiceItems().deserialize(el));
        }

        this.prereservationId = input.prereservationId;
        this.visitDateEnd = input.visitDateEnd;
        this.visitDateStart = input.visitDateStart;
        this.visitId = input.visitId;
        return this;
    }
}
