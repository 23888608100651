import {HelperService} from './../../_shared/services/helper.service';
import {Component, Input} from '@angular/core';
import {CanLeaveService} from './can-leave.service';
import {defer, Observable, of} from 'rxjs';
import {scan} from 'rxjs/operators';

@Component({
    selector: 'mf-can-leave',
    template: '<ng-template><ng-content></ng-content></ng-template>',
})
export class CanLeaveComponent {
    constructor(private canLeave: CanLeaveService, private helper: HelperService) {
        this.canLeave.allowDeactivation(this.canLeave$);
    }

    /** When true, pops-up a dialog asking for user's consent to leave*/
    @Input() dontLeave: boolean = false;

    private get canLeave$(): Observable<boolean> {
        return defer(() =>
            this.dontLeave
                ? this.helper
                      .displayAlert('Na strani imate neshranjene spremembe.', 'Želite nadaljevati?')
                      .then((el) => {
                          if (el.value) {
                              return true;
                          } else {
                              return false;
                          }
                      })
                : of(true),
        ).pipe(scan((granted, allow) => granted || allow, false));
    }
}
