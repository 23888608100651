import {Serializable} from '../deserialize.service';

export class PrintPOSFooterMessage implements Serializable<PrintPOSFooterMessage> {
    public value: string;

    deserialize(input: any) {
        this.value = input.value;

        return this;
    }
}
