import {Event} from '../event.model';
import * as _ from 'lodash';
import * as moment from 'moment';

export class EventNew extends Event {
    from: string;
    to: string;
    recurring: any;
    newCustomer: boolean;
    originDate: moment.Moment;
    // recurringUntil: string;

    deserialize(input: any) {
        if (_.isNil(input)) {
            return undefined;
        }
        super.deserialize(input);
        this.from = input.from;
        this.to = input.to;
        this.recurring = input.recurring;
        this.newCustomer = input.newCustomer;
        this.originDate = input.originDate;
        // this.recurringUntil = input.recurringUntil;
        return this;
    }
}
