import {AvailabilityStatus} from './../../enums/treatment/availabilityStatus.enum';
import {ReservationRequest} from '../reservationRequest.model';

export class TreatmentReservationRequest extends ReservationRequest {
    treatmentResId: string;
    isDetailsOpend: boolean = false;
    isFormValid: boolean = true;
    availabilityStatus: AvailabilityStatus = AvailabilityStatus.NOT_CHECKED;
    termTypeObj: any;
    prereservationId: string;
    includeStartDate: boolean = false;
    aggregateId: number;
    deserialize(input: any) {
        super.deserialize(input);
        return Object.assign(this, input);
    }
}
