import { Service } from './service.model';
import { Serializable } from './deserialize.service';

export class ServiceVisit implements Serializable<ServiceVisit> {
    service: Service;
    internalId: string;
    quantity: number;
    tax: number;
    discount: number;
    new: boolean;
    deserialize(input) {
        this.internalId = input.internalId;
        input.quantity ? (this.quantity = input.quantity) : (this.quantity = 1);
        this.tax = input.tax;
        input.discount ? (this.discount = input.discount) : (this.discount = 0);
        if (input.service) {
            this.service = new Service().deserialize(input.service);
        }
        this.new = input.new;
        return this;
    }

    // constructor(obj?: any) {
    //     this.id = (obj && obj.id) || null;
    //     this.name = (obj && obj.name) || null;
    //     this.description = (obj && obj.description) || [];
    //     this.length = (obj && obj.length) || [];
    //     this.price = (obj && obj.price) || [];
    //     this.internalId = (obj && obj.internalId) || [];
    // }
}

// public Long id;
// public String internalId;
// public String name;
// public String description;
// public Integer length; // čas trajanja storitve v minutah
// public BigDecimal quantity;
// public BigDecimal price;
// public BigDecimal tax;
// public BigDecimal discount;
