import {ReservationStatusType} from './../enums/reservationStatusType.enum';
import {Serializable} from './deserialize.service';

export class History implements Serializable<History> {
    id: number;
    data: any;
    date: Date;
    type: string;
    subcontractorName: string;
    comment: string;
    status: ReservationStatusType;

    deserialize(input) {
        this.id = input.id;
        this.data = input.data;
        this.date = input.date;
        this.type = input.type;
        this.subcontractorName = input.subcontractorName;
        this.comment = input.comment;
        this.status = input.status;
        return this;
    }
}
