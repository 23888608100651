import {HelperService, StorageService} from '../../_shared/services';
import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateChild, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';

@Injectable()
export class NotSavedGuard implements CanActivateChild {
    constructor(private router: Router, private storage: StorageService, private helper: HelperService) {}

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean> | Promise<boolean> | boolean {
        if (this.helper.disabledNavigation) {
            console.info('Nimate shranjene podatke');
            return false;
        }
        return true;
    }
}
