import {Serializable} from './deserialize.service';

export class Captcha implements Serializable<Captcha> {
    guid: string;
    captcha: string;
    selectChar: string;

    deserialize(input) {
        // debugger;
        this.guid = input.guid;
        this.captcha = input.captcha;
        this.selectChar = input.selectChar;
        return this;
    }
}
