import { GuiUserContractorSettings } from './../../model/userGuiSettings/guiUserContractorSettings.model';
import { User } from './../../model/user.model';
import { Asset } from './../../model/asset.model';
import { Subcontractor } from './../../model/subcontractor.model';
import { Contractor } from './../../model/contractor.model';
import { Service } from './../../model/service.model';

export class BaseStateModel {
    services: Service[];
    activeContractor: Contractor;
    userGuiSettings: GuiUserContractorSettings;
    allSubcontractors: Subcontractor[];
    assets: Asset[];
    loggedUser: User;
    appType;
}
