import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import 'rxjs';
import {HttpClientService} from '../../../../_shared/services/http-client.service';
import {HelperService, StorageService} from '../../../../_shared/services';
import {Document} from '../../../../_shared/model';

@Injectable({
    providedIn: 'root',
})
export class DocumentsService {
    constructor(
        private http: HttpClient,
        private httpClient: HttpClientService,
        private storage: StorageService,
        private helper: HelperService,
    ) {}

    // addContractorDocument()
    public addFileToContractor(constracorId: number, documentId: string): Observable<any> {
        // /contractors/{contractorId}/documents/{documentId}
        return this.httpClient.post(
            this.helper.getApiUrl() + '/contractors/' + constracorId + '/documents/' + documentId,
        );
    }

    public uploadFile(file: File, comment: string): Observable<any> {
        return this.httpClient.uploadFile(
            this.helper.getDocumentUrl() + '/upload/contractors/' + this.storage.getSelectedContractor().id,
            file,
            comment,
        );
    }

    public addFileToVR(reservationId: string, documentId: string, visitId?: number): Observable<any> {
        if (visitId) {
            return this.addFileToVisit(visitId, documentId);
        } else {
            return this.addFileToReservation(reservationId, documentId);
        }
    }

    public addFileToVisit(visitId: number, documentId: string): Observable<any> {
        return this.httpClient.post(this.helper.getVisitUrl() + '/visits/' + visitId + '/documents/' + documentId);
    }

    public addFileToReservation(reservationId: string, documentId: string): Observable<any> {
        return this.httpClient.post(
            this.helper.getApiUrl() + '/reservations/' + reservationId + '/documents/' + documentId,
        );
    }

    public getDocumentsVR(reservationId: string, visitId?: number): Observable<Document[]> {
        if (visitId) {
            return this.getDocumentsForVisit(visitId);
        } else {
            return this.getDocumentsForReservation(reservationId);
        }
    }

    public getDocumentsForReservation(reservationId: string): Observable<Document[]> {
        return this.httpClient.get(this.helper.getApiUrl() + '/reservations/' + reservationId + '/documents').pipe(
            map((val) => {
                return val.documents;
            }),
        );
    }

    public getDocumentsForVisit(visitId: number): Observable<Document[]> {
        return this.httpClient.get(this.helper.getVisitUrl() + '/visits/' + visitId + '/documents').pipe(
            map((val) => {
                return val.documents;
            }),
        );
    }

    public deleteDocumentVR(prereservationid: string, documentId: string, visitId?: number): Observable<any> {
        if (visitId) {
            return this.deleteDocumentFromVisit(visitId, documentId);
        } else {
            return this.deleteDocumentFromReseration(prereservationid, documentId);
        }
    }

    public deleteDocumentFromReseration(prereservationid: string, documentId: string): Observable<any> {
        return this.httpClient.delete(
            this.helper.getApiUrl() + '/reservations/' + prereservationid + '/documents/' + documentId,
        );
    }

    public deleteDocumentFromVisit(visitId: number, documentId: string): Observable<any> {
        return this.httpClient.delete(this.helper.getVisitUrl() + '/visits/' + visitId + '/documents/' + documentId);
    }

    public openDocument(contractorId: number, documentId: string): Observable<any> {
        return this.httpClient.getAttachment(
            this.helper.getDocumentUrl() + '/contractors/' + contractorId + '/documents/' + documentId,
        );
    }

    // #########CUSTOMER###########

    public printGdprConsent(contractorId: number, customerId: number): Observable<any> {
        return this.httpClient.getAttachment(
            this.helper.getPrintServerUrl() + '/gdpr/contractors/' + contractorId + '/customers/' + customerId,
        );
    }

    //getCustomerDocuments()
    public getCustomerDocuments(contractorId: number, customerId: number): Observable<Document[]> {
        return this.httpClient
            .get(this.helper.getApiUrl(2) + '/contractors/' + contractorId + '/customers/' + customerId + '/documents')
            .pipe(
                map((val) => {
                    return val.documents;
                }),
            );
    }
    // addCustomerDocument()
    public addFileToCustomer(contractorId: number, customerId: number, documentId: string): Observable<any> {
        return this.httpClient.post(
            this.helper.getApiUrl() +
                '/contractors/' +
                contractorId +
                '/customers/' +
                customerId +
                '/documents/' +
                documentId,
        );
    }

    public deleteCustomerDocument(contractorId: number, customerId: number, documentId: string): Observable<any> {
        return this.httpClient.delete(
            this.helper.getApiUrl() +
                '/contractors/' +
                contractorId +
                '/customers/' +
                customerId +
                '/documents/' +
                documentId,
        );
    }
    getDocumentThumbnails(contractorId: number, documentId: string, height: number, width: number): Observable<any> {
        if (width && !height) {
            return this.httpClient.getAttachment(
                this.helper.getDocumentUrl() +
                    '/contractors/' +
                    contractorId +
                    '/documents/' +
                    documentId +
                    `/thumbnail?width=${width}`,
            );
        } else if (height && !width) {
            return this.httpClient.getAttachment(
                this.helper.getDocumentUrl() +
                    '/contractors/' +
                    contractorId +
                    '/documents/' +
                    documentId +
                    `/thumbnail?height=${height}`,
            );
        } else {
            return this.httpClient.getAttachment(
                this.helper.getDocumentUrl() +
                    '/contractors/' +
                    contractorId +
                    '/documents/' +
                    documentId +
                    `/thumbnail?width=${width}&height=${height}`,
            );
        }
    }
}
