import * as _ from 'lodash';
import {Serializable} from '../deserialize.service';

export class GroupInvoicePayer implements Serializable<GroupInvoicePayer> {
    address: string;
    name: string;
    payerCustomerId: number;
    payerPartnerId: number;
    postName: string;
    postNumber: string;
    taxId: string;

    deserialize(input) {
        if (_.isNil(input)) {
            return undefined;
        }
        return Object.assign(this, input);
    }
}
