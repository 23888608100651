import { map } from 'rxjs/operators';
import { HelperService } from './helper.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Consent } from '../model/consent.model';

@Injectable()
export class PublicService {
    constructor(private http: HttpClient, private helper: HelperService) {}

    public sendAccessCode(documentid: string, phonenumber: string): Observable<any> {
        return this.http.post(this.helper.getAccessUrl() + '/code/' + documentid + '/phone/' + phonenumber, {});
    }
    //printDiagnose()
    public getResultDocument(documentid: string, pwd: string): Observable<any> {
        return this.http.get(this.helper.getPrintServerUrl() + '/visits/survey/document/' + documentid + '/' + pwd, {
            responseType: 'blob',
        });
    }
    // previewInvoice()
    public getInvoiceDocument(documentid: string, pwd: string): Observable<any> {
        return this.http.get(this.helper.getAccountingUrl(2) + '/invoices/document/' + documentid + '/' + pwd, {
            responseType: 'blob',
        });
    }

    //forgottenPwd() //public access
    public forgottenPwd(data: any): Observable<any> {
        return this.http.post(this.helper.getApiUrl() + '/pwd/forgotten', data);
    }

    //renewPwd() //public access
    public renewPwd(data: any): Observable<any> {
        return this.http.post(this.helper.getApiUrl() + '/pwd/renew', data);
    }

    //surveyHook() //public access
    public sendSurveyResults(guid: string, data: any): Observable<any> {
        return this.http.post(this.helper.getSurveysUrl() + '/erp?guid=' + guid, data);
    }

    //printDiagnose()
    public getCustomerConsent(token: string): Observable<Consent> {
        return this.http
            .get(this.helper.getApiUrl() + '/consent/' + token)
            .pipe(map((res) => new Consent().deserialize(res)));
    }

    //updateCustomerConsentWithToken()
    public updateCustomerConsentWithToken(token: string, data: Consent): Observable<any> {
        return this.http.post(this.helper.getApiUrl() + '/consent/' + token, data);
    }

    public solveUserCaptcha(username: string, guid: string, x: number, y: number): Observable<boolean> {
        return this.http.get(
            this.helper.getRootUrl() + `/captcha/users/${username}/validate/${guid}/${x}/${y}`,
        ) as Observable<boolean>;
    }
}
