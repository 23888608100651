import {Serializable} from './deserialize.service';

export class Task implements Serializable<Task> {
    id: number;
    taskCreated: Date;
    taskCreatedBy: string;
    description: string;
    taskFinished: Date;
    dueDate: Date;
    delegatedId: number;
    delegatedName: string;
    status: number;
    customerName: string;
    customerId: number;
    details: string;

    deserialize(input) {
        // debugger;
        this.id = input.id;
        this.taskCreated = input.taskCreated;
        this.taskCreatedBy = input.taskCreatedBy;
        this.description = input.description;
        this.taskFinished = input.taskFinished;
        this.dueDate = input.dueDate;
        this.delegatedId = input.delegatedId;
        this.delegatedName = input.delegatedName;
        this.status = input.status;
        this.customerName = input.customerName;
        this.customerId = input.customerId;
        this.details = input.details;
        return this;
    }
}
// public Long id;
// public Date taskCreated;
// public String taskCreatedBy;
// public String description;
// public Date taskFinished;
