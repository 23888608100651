import * as _ from 'lodash';
import {Serializable} from '../../deserialize.service';

export class GroupInvocesRemoveRequest implements Serializable<GroupInvocesRemoveRequest> {
    //uporabis eno ali drugo, na ta nacin lahko brises group ali pa posamezno postavko
    groupInvoiceDetailIds: number[];
    groupInvoiceId: number;

    deserialize(input) {
        if (_.isNil(input)) {
            return undefined;
        }

        this.groupInvoiceDetailIds = input.groupInvoiceDetailIds;
        this.groupInvoiceId = input.groupInvoiceId;
        return this;
    }
}
