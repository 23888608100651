import {Serializable} from '../deserialize.service';
import * as _ from 'lodash';

export class HolidayEvent implements Serializable<HolidayEvent> {
    holidayDate: Date;
    name: string;

    deserialize(input) {
        if (_.isNil(input)) {
            return this;
        }
        // debugger;
        this.holidayDate = input.holidayDate;
        this.name = input.name;

        return this;
    }
}
