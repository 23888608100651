import {Serializable} from '../deserialize.service';

export class PrinterConfigGuiSettings implements Serializable<PrinterConfigGuiSettings> {
    isPOSPrinterActive: boolean = false;
    paperPOSType: number = 1;
    constructor() {}
    deserialize(input: any) {
        if (!input || input === null) {
            return null;
        }
        this.isPOSPrinterActive = input.isPOSPrinterActive;
        this.paperPOSType = input.paperPOSType;
        return this;
    }
}
