import {
    Coupons,
    DailyReportResponse,
    Event,
    GroupInvocesCreateRequest,
    GroupInvocesRemoveRequest,
    GroupInvocesRequest,
    GroupInvoice,
    GroupInvoicePayer,
    InvoiceMain,
    InvoicePartner,
    InvoiceReport,
    PivotDef,
    SendInvoiceNotificationInfo,
    ServiceVisit,
} from '../../../../_shared/model';
import {map} from 'rxjs/operators';
import {Invoice} from '../../../../_shared/model/invoice.model';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import 'rxjs';
import {HttpClientService} from '../../../../_shared/services/http-client.service';
import {HelperService} from '../../../../_shared/services';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class AccountingService {
    constructor(private httpClient: HttpClientService, private helper: HelperService) {}

    public getPivotTableData(contractorid: number, from: string, to: string): Observable<any> {
        return this.httpClient.getAttachment(
            this.helper.getAccountingUrl(2) + `/contractors/${contractorid}/pivot-data?from=${from}&to=${to}`,
        );
    }

    public removePivotDefs(contractorid: number, pivotDefId: number): Observable<any> {
        return this.httpClient.delete(
            this.helper.getAccountingUrl(2) + `/contractors/${contractorid}/pivot-defs/${pivotDefId}`,
        );
    }

    public getPivotDefs(contractorid: number): Observable<PivotDef[]> {
        return this.httpClient.get(this.helper.getAccountingUrl(2) + `/contractors/${contractorid}/pivot-defs`);
    }

    public addPivotDefs(contractorid: number, data: PivotDef): Observable<any> {
        return this.httpClient.post(this.helper.getAccountingUrl(2) + `/contractors/${contractorid}/pivot-defs`, data, {
            responseType: 'text',
        });
    }
    public getGroupInvoiceReportPreview(contractorId: number, data: InvoiceReport): Observable<any> {
        return this.httpClient.getAttachmentPost(
            this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/groupInvoiceConfirmation/preview`,
            data,
        );
    }

    // public getGroupInvoiceReportPreview(contractorId: number, data: InvoiceReport): Observable<any> {
    //     return this.httpClient.getAttachmentPost(
    //         this.helper.getAccountingUrl() + `/contractors/${contractorId}/groupInvoice/preview`,
    //         data,
    //     );
    // }

    public getInvoicePreviewGeneric(
        contractorId: number,
        data: InvoiceReport | Invoice,
        isCopy: boolean = false,
    ): Observable<any> {
        let url = `/contractors/${contractorId}/invoices/preview/generic`;
        if (isCopy) {
            url += '?copy=true';
        }
        // return this.httpClient.getAttachment(this.helper.getAccountingUrl() + url);
        return this.httpClient.getAttachmentPost(this.helper.getAccountingUrl(2) + url, data);
    }

    public getInvoicePreview(contractorId: number, visitId: number, isCopy: boolean = false): Observable<any> {
        let url = `/contractors/${contractorId}/visits/${visitId}/preview`;
        if (isCopy) {
            url += '?copy=true';
        }
        return this.httpClient.getAttachment(this.helper.getAccountingUrl() + url);
    }

    public addServiceOnVisit(contractorId: number, visitId: number, visitService: ServiceVisit): Observable<any> {
        return this.httpClient.post(
            this.helper.getVisitUrl() + '/contractors/' + contractorId + '/visits/' + visitId + '/services',
            visitService,
            {
                responseType: 'text',
            },
        );
    }

    public getAllServicesOnVisit(visitId: string): Observable<ServiceVisit[]> {
        return this.httpClient
            .get(this.helper.getVisitUrl() + '/visits/' + visitId + '/services')
            .pipe(map((res) => res.map((el) => new ServiceVisit().deserialize(el))));
    }

    // .map(res => {
    //     return res.json().map(el => {
    //         return new ServiceVisit().deserialize(el);
    //     });
    // });

    public updateServiceVisit(contractorId: number, visitId: number, visitService: ServiceVisit): Observable<any> {
        return this.httpClient.put(
            this.helper.getVisitUrl() + '/visits/' + visitId + '/services/' + visitService.service.id,
            visitService,
        );
    }

    public deleteServiceVisit(visitId: string, internalId: string): Observable<any> {
        return this.httpClient.delete(this.helper.getVisitUrl() + '/visits/' + visitId + '/services/' + internalId);
    }

    //createInvoiceFromVisit()
    public createInvoiceFromVisit(contractorId: number, visitId: number): Observable<any> {
        return this.httpClient.getAttachment(
            this.helper.getAccountingUrl() + '/contractors/' + contractorId + '/visits/' + visitId,
        );
    }

    //createInvoiceGeneric()
    public createInvoiceGeneric(contractorId: number, data: InvoiceReport): Observable<any> {
        return this.httpClient.post(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/generic`, data);
    }
    // getInvoiceForInvoiceId
    public getInvoiceForInvoiceId(
        contractorId: number,
        invoiceid: number,
        print: boolean = false,
    ): Observable<InvoiceReport> {
        return this.httpClient.get(
            this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/invoices/${invoiceid}/data?print=${print}`,
        );
    }

    public getGroupInvoiceForGroupId(contractorId: number, groupInvoiceid: number): Observable<InvoiceReport> {
        return this.httpClient.get(
            this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/groupInvoiceConfirmation/${groupInvoiceid}`,
        );
    }
    // //createInvoice()
    // public createInvoice(contractorId: number, visitId: number): Observable<any> {
    //     return this.httpClient.getAttachment(
    //         this.helper.getAccountingUrl() + '/contractors/' + contractorId + '/visits/' + visitId
    //     );
    // }

    //statusInvoice()
    public getInvoiceStatus(contractorId: number, visitId: string): Observable<any> {
        return this.httpClient.get(
            this.helper.getAccountingUrl() + '/contractors/' + contractorId + '/visits/' + visitId + '/status',
        );
    }

    //sendDiagnose()
    public sendInvoice(contractorid: number, invoiceid: number, data: SendInvoiceNotificationInfo): Observable<any> {
        return this.httpClient.post(
            this.helper.getAccountingUrl() + `/contractors/${contractorid}/invoices/${invoiceid}/mail`,
            data,
        );
    }
    //getInvoices()
    public getInvoices(contractorId: number, from: string, to: string): Observable<Invoice[]> {
        return this.httpClient
            .get(this.helper.getAccountingUrl(2) + '/contractors/' + contractorId + '?from=' + from + '&to=' + to)
            .pipe(
                map((res) => {
                    if (!_.isNil(res.invoiceStat)) {
                        return _.get(res, 'invoiceStat', []).map((el) => {
                            return new Invoice().deserialize(el);
                        });
                    }
                    return [];
                }),
            );
    }

    //createInvoice()
    public getInvoice(contractorId: number, invoiceId: number): Observable<any> {
        return this.httpClient.getAttachment(
            this.helper.getAccountingUrl() + '/contractors/' + contractorId + '/invoices/' + invoiceId,
        );
    }

    //deleteInvoice()
    public cancelInvoice(contractorId: number, invoiceId: number): Observable<any> {
        return this.httpClient.delete(
            this.helper.getAccountingUrl(2) + '/contractors/' + contractorId + '/invoices/' + invoiceId,
        );
    }

    //setPayerName()
    public setPayerName(contractorId: number, visitId: string, data: any): Observable<any> {
        return this.httpClient.post(
            this.helper.getVisitUrl() + `/contractors/${contractorId}/visits/${visitId}/company`,
            data,
        );
    }

    //createPOSInvoice()
    public createPOSInvoice(contractorId: number, visitId: number): Observable<any> {
        return this.httpClient.get(
            this.helper.getAccountingUrl() + `/contractors/${contractorId}/visits/${visitId}/pos`,
        );
    }

    // REPORTS

    //prepareReports()
    public prepareReports(
        contractorId: number,
        reportName: string,
        reportType: string,
        from: string,
        to: string,
    ): Observable<any> {
        return this.httpClient.getAttachment(
            this.helper.getAccountingUrl(2) +
                `/contractors/${contractorId}/reports?reportName=${reportName}&reportType=${reportType}&from=${from}&to=${to}`,
        );
    }

    //contractorReports()
    public invoicesZip(contractorId: number, from: string, to: string): Observable<any> {
        return this.httpClient.getAttachment(
            this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/invoices/zip?from=${from}&to=${to}`,
        );
    }
    // createInvoiceReportFromVisit()
    public createInvoiceReportFromVisit(contractorId: number, visitId: number): Observable<InvoiceReport> {
        return this.httpClient.get(
            this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/visits/${visitId}/data`,
        );
    }

    //prepareInvoiceData
    public prepareInvoiceDataVisit(contractorId: number, visitId: number): Observable<InvoiceMain> {
        return this.httpClient
            .get(this.helper.getAccountingUrl() + `/contractors/${contractorId}/visits/${visitId}/invoice`)
            .pipe(
                map((el) => {
                    return new InvoiceMain().deserialize(el);
                }),
            );
    }

    //getInvoicesForVisit()
    public getInvoicesForVisit(contractorId: number, visitId: number): Observable<InvoiceReport[]> {
        return this.httpClient.get(
            this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/visits/${visitId}/invoices`,
        );
    }

    //getInvoicesForCustomer()
    public getInvoicesForCustomer(contractorId: number, customerId: number): Observable<InvoiceReport[]> {
        return this.httpClient.get(
            this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/customers/${customerId}/invoices`,
        );
    }

    //getInvoicesTemplateEmploye()
    public getInvoicesTemplateEmploye(contractorId: number, userId: number): Observable<InvoiceReport> {
        return this.httpClient.get(
            this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/users/${userId}/invoices/template`,
        );
    }

    //getInvoicesTemplate()
    public getInvoicesTemplate(
        contractorId: number,
        subcontractorId: number,
        userId: number,
    ): Observable<InvoiceReport> {
        return this.httpClient.get(
            this.helper.getAccountingUrl(2) +
                `/contractors/${contractorId}/subcontractors/${subcontractorId}/users/${userId}/invoices/template`,
        );
    }

    //getInvoicesTemplate()
    public getInvoicesTemplateCustomer(
        contractorId: number,
        customerId: number,
        userId: number,
    ): Observable<InvoiceReport> {
        return this.httpClient.get(
            this.helper.getAccountingUrl(2) +
                `/contractors/${contractorId}/users/${userId}/customers/${customerId}/invoices/template`,
        );
    }

    //prepareDailyReport
    public prepareDailyReport(contractorId: number, reportDate: string): Observable<DailyReportResponse> {
        return this.httpClient.get(
            this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/dailyreport?reportDate=${reportDate}`,
        );
    }

    //getUnusedCoupons
    public getUnusedCoupons(contractorId: number): Observable<Coupons[]> {
        return this.httpClient.get(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/coupons`);
    }

    //changePaymentType
    public changePaymentType(contractorId: number, invoiceRequest: InvoiceReport): Observable<DailyReportResponse> {
        return this.httpClient.put(
            this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/paymentType`,
            invoiceRequest,
        );
    }

    //getInvoicePartners()
    public getInvoicePartners(contractorId: number): Observable<any[]> {
        return this.httpClient.get(this.helper.getAccountingUrl() + `/contractors/${contractorId}/invoicePartners`);
    }

    //createUpdateInvoicePartner()
    public createUpdateInvoicePartner(data: InvoicePartner): Observable<any> {
        return this.httpClient.post(this.helper.getAccountingUrl(2) + `/invoicePartner`, data);
    }

    public searchInvoicePartners(contractorId: number, query: string): Observable<InvoicePartner[]> {
        return this.httpClient.get(
            this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/invoicePartners/search?query=${query}`,
        );
    }

    //createGroupInvoiceConfirmation()
    public addToGroupInvoice(contractorId: number, invoiceRequest: InvoiceReport): Observable<any> {
        return this.httpClient.post(
            this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/groupInvoiceConfirmation`,
            invoiceRequest,
        );
    }

    //getContractorGroupInvoicePayers()
    public getContractorGroupInvoicePayers(
        contractorId: number,
        searchText: string,
        showOpenOnly: boolean = false,
    ): Observable<GroupInvoicePayer[]> {
        return this.httpClient.get(
            this.helper.getAccountingUrl(2) +
                `/contractors/${contractorId}/groupInvoicePayers/search?searchText=${searchText}&showOpenOnly=${showOpenOnly}`,
        );
    }

    //getContractorPayerGroupInvoices ()
    public getContractorPayerGroupInvoices(
        contractorId: number,
        data: GroupInvocesRequest,
        from: string,
        to: string,
        showOpenOnly: boolean = true,
    ): Observable<GroupInvoice[]> {
        return this.httpClient.post(
            this.helper.getAccountingUrl(2) +
                `/contractors/${contractorId}/payerGroupInvoices/search?from=${from}&to=${to}&showOpenOnly=${showOpenOnly}`,
            data,
        );
    }

    public previewInvoiceFromGroupInvoiceRequest(
        contractorId: number,
        data: GroupInvocesCreateRequest,
    ): Observable<any> {
        return this.httpClient.getAttachmentPost(
            this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/groupInvoice/preview`,
            data,
        );
    }

    //createInvoiceFromGroupInvoiceRequest ()
    public createInvoiceFromGroupInvoiceRequest(
        contractorId: number,
        data: GroupInvocesCreateRequest,
    ): Observable<Invoice> {
        return this.httpClient.post(this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/groupInvoice`, data);
    }

    public getGroupInvoicesForVisit(contractorId: number, visitid: number): Observable<any> {
        return this.httpClient.get(
            this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/visits/${visitid}/groupInvoiceConfirmations`,
        );
    }

    public getGroupInvoicesForCustomer(contractorId: number, customerId: number): Observable<any> {
        return this.httpClient.get(
            this.helper.getAccountingUrl(2) +
                `/contractors/${contractorId}/customers/${customerId}/groupInvoiceConfirmations`,
        );
    }

    public getReservationWithNoInvoice(
        contractorId: number,
        from: string = undefined,
        to: string = undefined,
    ): Observable<Event[]> {
        let url: string = `/contractors/${contractorId}/reservations/noinvoice`;
        //YYYY-MM-DD
        if (from && to) {
            url += `?from=${from}&to=${to}`;
        }
        return this.httpClient.get(this.helper.getAccountingUrl(2) + url);
    }

    //deleteGroupInvoiceDetails ()
    public deleteGroupInvoiceDetails(contractorId: number, data: GroupInvocesRemoveRequest): Observable<any> {
        return this.httpClient.post(
            this.helper.getAccountingUrl(2) + `/contractors/${contractorId}/deleteGroupInvoice`,
            data,
        );
    }
}
