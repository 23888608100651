import { Subcontractor } from './../../model/subcontractor.model';
import { GuiUserContractorSettings } from './../../model/userGuiSettings/guiUserContractorSettings.model';
import { User } from './../../model/user.model';
import { Contractor } from './../../model/contractor.model';
import { Service } from './../../model/service.model';
export class AddServices {
    public static readonly type = 'AddServices';
    constructor(public readonly services: Service[]) {}
}

export class ClearState {
    public static readonly type = 'ClearState';
    // constructor(public readonly services: Service[]) {}
}

export class ClearServiceCache {
    public static readonly type = 'ClearServiceCache';
    // constructor(public readonly services: Service[]) {}
}

export class GetAndLoadServices {
    public static readonly type = 'GetServices';
    // constructor(public readonly services: Service[]) {}
}

export class SetActiveContractor {
    public static readonly type = 'SetActiveContractor';
    constructor(public readonly contractor: Contractor) {}
}

export class SetCalendarFilter {
    public static readonly type = 'SetCalendarFilter';
    constructor(public filter: any, public timeFrequency?: any) {}
}

export class SetUserSettings {
    public static readonly type = 'SetUserSettings';
    constructor(public readonly userGuiSettings: GuiUserContractorSettings) {}
}
export class SetDefaultUserSettings {
    public static readonly type = 'SetDefaultUserSettings';
    constructor(public readonly settings: any) {}
}
export class SetCalendarSubcontractors {
    public static readonly type = 'SetCalendarSubcontractors';
    constructor(public readonly newSelectedSubcontractors: Subcontractor[]) {}
}
export class SetLoggedUser {
    public static readonly type = '[User] SetLoggedUser';
    constructor(public readonly user: User) {}
}

export class GetAndLoadSubcontractors {
    public static readonly type = '[User] GetSubcontractors';
}

export class GetAndLoadAssets {
    public static readonly type = '[User] GetAssets';
}

export class SetAppType {
    public static readonly type = 'AppType';
    constructor(public readonly appType: string) {}
}
