import {ServiceVisit} from './serviceVisit.model';
import {Serializable} from './deserialize.service';

export class Visit implements Serializable<Visit> {
    aggregateId: string;
    visitDate: Date;
    serviceVisit: ServiceVisit[];
    prereservationId: string;
    diagnoseCreated: boolean;

    deserialize(input) {
        this.aggregateId = input.aggregateId;
        this.visitDate = input.visitDate;
        this.prereservationId = input.prereservationId;
        if (input.visitService) {
            this.serviceVisit = input.visitService.map((conr) => {
                return new ServiceVisit().deserialize(conr);
            });
        }
        this.diagnoseCreated = input.diagnoseCreated;
        return this;
    }

    // constructor(obj?: any) {
    //     this.aggregateId = (obj && obj.aggregateId) || null;
    //     this.visitDate = (obj && obj.visitDate) || null;
    //     this.services = (obj && obj.services) || [];
    // }

    // constructor(aggregateId: string, visitDate: Date, services: Service []) {
    //     this.aggregateId = aggregateId
    //     this.visitDate = visitDate
    //     this.services = services;
    // }
}
