import {Serializable} from '../deserialize.service';
import * as moment from 'moment';

export class BookingAllowanceRequest implements Serializable<BookingAllowanceRequest> {
    public customerId: number;
    public serviceId: number;
    public startDate: moment.Moment;

    deserialize(input: any) {
        this.customerId = input.customerId;
        this.serviceId = input.serviceId;
        this.startDate = input.startDate;
        return this;
    }
}
