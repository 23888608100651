import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class NgbDateMomentParserFormatter extends NgbDateParserFormatter {
    format(date: NgbDateStruct): string {
        if (date === null) {
            return '';
        }
        let d = moment({
            year: date.year,
            month: date.month - 1,
            date: date.day,
        });
        return d.isValid() ? d.format('DD. MM. YYYY') : '';
    }

    parse(value: string): NgbDateStruct {
        if (!value) {
            return null;
        }
        let d = moment(value, 'DD. MM. YYYY');
        return d.isValid()
            ? {
                  year: d.year(),
                  month: d.month() + 1,
                  day: d.date(),
              }
            : null;
    }
}
