import {Treatment} from './treatment.model';

export class TreatmentGUI extends Treatment {
    isTreatmentCreated: boolean;

    deserialize(input: any) {
        super.deserialize(input);
        return Object.assign(this, input);
    }

    // public getTreatment(): Treatment {
    //     return super.deserialize(this);
    // }
}
