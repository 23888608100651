import {Serializable} from '../deserialize.service';
import * as moment from 'moment';

export class PrintPOSInvoiceDetails implements Serializable<PrintPOSInvoiceDetails> {
    public invoiceId: string;
    public cashier: string;
    public typeOfInvoice: string;
    public location: string;
    public date: moment.Moment;

    deserialize(input: any) {
        this.invoiceId = input.invoiceId;
        this.cashier = input.cashier;
        this.typeOfInvoice = input.typeOfInvoice;
        this.location = input.location;
        this.date = moment(input.date);
        return this;
    }
}
