<app-header-nav></app-header-nav>
<div class="container m-content" [ngClass]="this.source">
    <div class="row">
        <div class="col-12" *ngIf="surveyListArray.length > 1">
            <ul class="nav nav-pills nav-pills--brand">
                <li class="nav-item" *ngFor="let survey of surveyListArray">
                    <a
                        [routerLinkActive]="['active']"
                        [routerLink]="'form/' + setBtoa(survey?.id)"
                        (click)="selectSurvey(setBtoa(survey.id))"
                        class="nav-link"
                        ><span style="vertical-align: middle">{{ survey.name }}</span>
                        <span><i *ngIf="survey?.completed" class="la la-check"></i></span>
                    </a>
                </li>
            </ul>
        </div>
        <div class="col-12">
            <div class="surveySection">
                <ng-container *ngIf="this.survey?.completed && !this.error && this.bsId != 7">
                    <h1 *ngIf="!justSubmited">Vaše odgovore smo že prejeli.</h1>
                    <h1 *ngIf="justSubmited">Najlepša hvala za podane odgovore.</h1>
                </ng-container>
                <ng-container *ngIf="this.survey?.completed && !this.error && this.bsId == 7">
                    <h1 *ngIf="!justSubmited">Vaše odgovore smo že prejeli.</h1>
                    <h1 *ngIf="justSubmited">Hvala vam, da ste si vzeli čas in z nami delili vašo izkušnjo.</h1>
                    <h3 class="mt-3" *ngIf="justSubmited">Vaše mnenje šteje!</h3>
                    <div *ngIf="justSubmited">S prijaznimi pozdravi,</div>
                    <div *ngIf="justSubmited">vaš Varuh zdravja, Vzajemna</div>
                </ng-container>
                <ng-container *ngIf="this.error && !this.survey?.completed">
                    <h1>{{ this.error.errHeader }}</h1>
                    <h5 style="border-bottom: none">{{ this.error.errDesc }}</h5>
                </ng-container>
                <ng-container *ngIf="!this.error && !this.survey?.completed">
                    <formio
                        [refresh]="this.triggerRefresh"
                        (submit)="saveForm($event, this.survey?.type)"
                        [form]="this.surveyTemplate"
                        [options]="this.options"
                        (ready)="formReady($event)"
                        (change)="formChange($event)"
                        [renderOptions]="{ language: 'sl' }"
                        [submission]="this.consentData"
                        (errorChange)="formError($event)"
                    ></formio>
                    <small class="inputMissing text-danger" *ngIf="inputMissing"
                        >Nekatera vprašanja potrebujejo vaš odgovor. Prosimo, da izpolnite obvezna polja.</small
                    >
                </ng-container>
            </div>
        </div>
    </div>
    <div class="row" *ngIf="this.bsId">
        <div class="col-12">
            <div class="d-flex flex-column align-items-center mt-4" style="height: 100%">
                <common-business-subject-icon
                    [onlyIcon]="true"
                    [icon]="false"
                    [logo]="true"
                    [size]="25"
                    [setBsId]="this.bsId"
                ></common-business-subject-icon>
            </div>
        </div>
    </div>
</div>
<mf-can-leave [dontLeave]="formChanged && !justSubmited" #canLeave> </mf-can-leave>
