export const environment = {
    RELEASE_STAGE: 'production',
    production: true,
    test: false,
    VERSION: require('../../package.json').version,
    FILE_UPLOAD_MAX_SIZE: 52428800,
    AUTHPASS: btoa('test:test'),
    ERROR_URL: '',
    ERROR_NOTIFY_ACTIVE: true,
    termalPrinter: {
        port: '8080',
    },
    service: {
        v1: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            apiPort: '',
            apiUrl: '/erp/api',
        },
        v2: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            apiPort: '',
            apiUrl: '/eav2/erp/api',
        },
    },
    auth: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/oauth/token',
    },
    report: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/erp/report1',
    },
    document: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/erp/docapi',
    },
    printserver: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/eav2/erp/papi',
    },
    task: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/eav2/erp/taskapi',
    },
    accounting: {
        v1: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/erp/aapi',
        },
        v2: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/eav2/erp/aapi',
        },
    },
    visit: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/erp/visitapi',
    },
    communication: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/eav2/erp/communicationapi',
    },
    statistics: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/eav2/erp/statsapi',
    },
    surveys: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/surveys',
    },
    patient: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/patient/api',
    },
    access: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/access',
    },
    assistance: {
        v1: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/v2/assistance/api',
        },
        v2: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/v2/assistance/api',
        },
        v3: {
            protocol: 'https://',
            rootUrl: 'app.eambulanta.si/booking',
            port: '',
            url: '/asv2/assistance/api',
        },
    },
    triage: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/erp/triageapi',
    },
    telemed: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/erp/telemed',
    },
    onesignal: {
        appId: 'fdc55fb4-6851-4d64-aae3-89f45ea090d3',
        allowLocalhostAsSecureOrigin: false,
        defaultUrl: 'https://app.eambulanta.si',
        defaultTitle: 'eAmbulanta',
        safariWebId: 'web.onesignal.auto.2c53d929-118c-4db5-ba77-650d97dbe49e',
    },
    treatment: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/erp/treatments',
    },
    root: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '',
    },
    agora: {
        appId: '74694d6ab72646fea80f43f17aab66b6',
        logLevel: 2,
    },
    itero: {
        protocol: 'https://',
        rootUrl: 'app.eambulanta.si/api/booking',
        port: '',
        url: '/erp/itero',
    },
    webSocket: {
        protocol: 'wss://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/ws/ea',
    },
    webSocketTelemed: {
        protocol: 'wss://',
        rootUrl: 'app.eambulanta.si/booking',
        port: '',
        url: '/ws/telemed/',
    },
    toolsUrl: {
        protocol: 'https://',
        rootUrl: 'link.eambulanta.si/tools',
        port: '',
        url: '',
    },
};
