import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {StorageService} from '../../_shared/services';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private storage: StorageService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
        // let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        // if (!this.storage.getAuthNurse() && !this.storage.getSelectedTerm()) {
        //     this.router.navigate(['/authNurse'], { queryParams: { returnUrl: state.url } });
        // }
        if (this.storage.getUserData()) {
            return true;
        }
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
        // return this.userService.verify().map(
        //     data => {
        //         if (data !== null) {
        //             // logged in so return true
        //             return true;
        //         }
        //         // error when verify so redirect to login page with the return url
        //         this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        //         return false;
        //     },
        //     error => {
        //         // error when verify so redirect to login page with the return url
        //         this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        //         return false;
        //     });
    }
}
