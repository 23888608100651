import {Serializable} from '../deserialize.service';

export class AvgPerformance implements Serializable<AvgPerformance> {
    n: number;
    answers: AvgPerformanceAnswers[];

    deserialize(input: any) {
        this.n = input.n;
        if (input.answers && input.answers.length > 0) {
            this.answers = input.answers.map((el: AvgPerformanceAnswers) =>
                new AvgPerformanceAnswers().deserialize(el),
            );
        }
        return this;
    }
}

export class AvgPerformanceAnswers implements Serializable<AvgPerformanceAnswers> {
    id: number;
    avg: number;

    deserialize(input: any) {
        this.id = input.id;
        this.avg = input.avg;
        return this;
    }
}
