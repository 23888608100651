import { AppModulesType } from '../enums/appModulesType.enum';
import { Serializable } from './deserialize.service';

export class MfModule implements Serializable<MfModule> {
    id: AppModulesType;
    name: string;
    menuId: string;

    deserialize(input) {
        this.id = input.id;
        this.name = input.name;
        this.menuId = input.menuId;
        return this;
    }
}

// public Date notificationDate;
// public Long userId;
// public String prereservationId;
// public String msg;

// id: AppModulesType.PATIENT_APP,
// name: 'patientApp',
// menuId: ''
