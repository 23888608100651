import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'caseStatus',
})
export class CaseStatusPipe implements PipeTransform {
    transform(value: any, args?: any): any {
        switch (value) {
            case 'WAITING_QUEUE':
                return 'V čakalni vrsti';
            case 'CASE_CANCELED':
                return 'Avtorizacija: Preklicana';
            case 'NO_RESERVATIONS':
                return 'Avtorizacija: Ni rezervacij';
            case 'CASE_CONCLUDED':
                return 'Avtorizacija: Zaključena';
            case 'PARTIALLY_NOT_OK':
                return 'Avtorizacija: Nezaključena';
            case 'PARTIALLY_OK':
                return 'Avtorizacija: Odprta';
            default:
                return 'Manjka prevod';
        }
    }
}
