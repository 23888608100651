import * as _ from 'lodash';
import {Serializable} from '../deserialize.service';

export class DataPayerAction implements Serializable<DataPayerAction> {
    isGroupInvoice: boolean;

    deserialize(input) {
        if (_.isNil(input)) {
            return undefined;
        }
        return Object.assign(this, input);
    }
}
