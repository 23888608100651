import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import 'rxjs';
import {HttpClientService} from '../../../../_shared/services/http-client.service';
import {HelperService, StorageService} from '../../../../_shared/services';

@Injectable()
export class CommunicationService {
    data: any;
    token: any;

    constructor(
        private http: HttpClient,
        private httpClient: HttpClientService,
        private storage: StorageService,
        private helper: HelperService,
    ) {}
    //getCommunicationLogInfo()
    public getCommunicationList(contractorid: number, from: string, to: string): Observable<any> {
        return this.httpClient.get(
            this.helper.getCommunicationUrl() +
                '/contractors/' +
                contractorid +
                '/communication/' +
                '?from=' +
                from +
                '&to=' +
                to,
        );
    }

    //getCommunicationLogInfo()
    public getCommunicationListForReservation(
        contractorid: number,
        prereservationid: string,
        from: string,
        to: string,
    ): Observable<any> {
        return this.httpClient.get(
            this.helper.getCommunicationUrl() +
                '/contractors/' +
                contractorid +
                '/reservations/' +
                prereservationid +
                '?from=' +
                from +
                '&to=' +
                to,
        );
    }

    public getCommunicationListForVisit(contractorid: number, visitId: any, from: string, to: string): Observable<any> {
        return this.httpClient.get(
            this.helper.getCommunicationUrl() +
                '/contractors/' +
                contractorid +
                '/reservations/' +
                visitId +
                '?from=' +
                from +
                '&to=' +
                to,
        );
    }

    //getLogInfo()
    public getEventDetails(contractorid: number, queueid: string): Observable<any> {
        return this.httpClient.get(
            this.helper.getCommunicationUrl() + '/contractors/' + contractorid + '/communication/' + queueid,
            {
                responseType: 'text',
            },
        );
    }
}
