import {Serializable} from './deserialize.service';

export class Business implements Serializable<Business> {
    bsid: number;
    iconPath: string;
    logoPath?: string;
    name: string;
    size: number;

    deserialize(input) {
        this.bsid = input.bsid;
        this.iconPath = input.iconPath;
        this.logoPath = input.logoPath;
        this.name = input.name;
        this.size = input.size;
        return this;
    }
}
