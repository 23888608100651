import {Asset} from '../asset.model';
import {Subcontractor} from '../subcontractor.model';
import {Serializable} from '../deserialize.service';
import * as _ from 'lodash';

export class CalendarFilterSettings implements Serializable<CalendarFilterSettings> {
    visible: boolean = true;
    showResources: boolean;
    showOnlyAvailable: boolean;
    showExternalAvailable: boolean;
    showExternalCustomerAvailable: boolean;
    //samo za filter-> razpolozljivost
    showInternal: boolean;
    showWeekend: boolean;
    showHolidays: boolean;
    showServicesColor: boolean;
    show24h: boolean;
    from: string;
    to: string;
    timeFrequncy: number;
    selectedSubcontractors: Subcontractor[];
    selectedAssets: Asset[];
    // selectedCalendarView: string;

    deserialize(input) {
        if (!input || input === null) {
            return null;
        }
        this.selectedAssets = input.selectedAssets;
        // this.selectedCalendarView = input.selectedCalendarView;
        this.selectedSubcontractors = input.selectedSubcontractors;
        this.showResources = input.showResources;
        this.showOnlyAvailable = input.showOnlyAvailable;
        this.showExternalAvailable = input.showExternalAvailable;
        this.showExternalCustomerAvailable = input.showExternalCustomerAvailable;
        this.showInternal = input.showInternal;
        this.showWeekend = input.showWeekend;
        this.showHolidays = input.showHolidays;
        this.show24h = input.show24h;
        this.showServicesColor = input.showServicesColor;
        if (!_.isNil(input.visible)) {
            this.visible = input.visible;
        }
        // debugger;
        if (!_.isNil(input.from)) {
            this.from = input.from;
        }
        if (!_.isNil(input.to)) {
            this.to = input.to;
        }
        if (!_.isNil(input.timeFrequncy)) {
            this.timeFrequncy = input.timeFrequncy;
        }

        return this;
    }
}
