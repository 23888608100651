import {Serializable} from '../deserialize.service';

export class PriceListElementPut implements Serializable<PriceListElementPut> {
    discount: number;
    finalPrice: number;
    price: number;
    serviceName: string;
    serviceId: string;

    deserialize(input: any) {
        this.discount = input.discount;
        this.finalPrice = input.finalPrice;
        this.price = input.price;
        this.serviceName = input.serviceName;
        this.serviceId = input.serviceId;

        return this;
    }
}
