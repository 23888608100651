import {Serializable} from '../deserialize.service';

export class AppGuiSettings implements Serializable<AppGuiSettings> {
    public termsAndConditions: any;
    public isMenuMinimized: boolean;
    public customerDocumentViewType: string;

    deserialize(input) {
        if (!input || input === null) {
            return null;
        }

        Object.keys(input).forEach((key) => (this[key] = input[key]));
        // this.termsAndConditions = input.termsAndConditions;
        this.customerDocumentViewType = input.customerDocumentViewType;
        return this;
    }
}
