import { ActionButton, ContractorSettings, Customer, MenuItem, User } from '../model';
import { environment } from '../../../environments/environment';
import { NgxPermissionsService } from 'ngx-permissions';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { BehaviorSubject, Subject } from 'rxjs';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root',
})
export class MenuService {
    public default: MenuItem[] = [
        // {
        //     link: '/index',
        //     title: 'Domov',
        //     id: 'INDEX',
        //     icon: 'la la-h-square',
        // },
        {
            link: '/calendar/main',
            title: 'Termini',
            id: 'TERMS-CALENDAR',
            icon: 'la la-calendar',
            rules: 'menu_main_calendar__Visible',
        },
        {
            link: '/waiting-queue',
            title: 'Čakalna vrsta',
            onlyTest: false,
            id: 'WAITING-QUEUE',
            icon: 'la la-clock-o',
            rules: 'menu_main_waiting_queue__Visible',
        },
        {
            link: '/case',
            title: 'Avtorizacije',
            onlyTest: false,
            id: 'CASE',
            icon: 'la la-stethoscope',
            rules: 'menu_main_case__Visible',
        },
        // {
        //     link: '/inbox/device',
        //     title: 'Naprave',
        //     onlyTest: false,
        //     id: 'INBOX-DEVICE',
        //     icon: 'la la-inbox',
        //     rules: 'menu_main_inbox__Visible',
        // },
        {
            link: '/inbox',
            title: 'Prejeto',
            onlyTest: false,
            id: 'INBOX',
            icon: 'la la-inbox',
            rules: 'menu_main_inbox__Visible',
            statusIcon: 'newInboxItems',
            // submenu: [{ title: 'Naprave', link: '/inbox/device', id: 'INBOX-DEVICE' }], //la-sitemap
        },
        {
            link: '/treatments',
            title: 'Zdravljenja',
            id: 'TREATMENTS',
            icon: 'la la-heart-o',
            rules: 'menu_main_treatments__Visible',
        },
        // {
        //     link: '/consul',
        //     title: 'eTriaža',
        //     id: 'TRIAGE',
        //     icon: 'la la-lightbulb-o',
        //     rules: 'menu_main_triage__Visible',
        // },
        {
            link: '/customers',
            title: 'Stranke',
            id: 'CUSTOMERS',
            icon: 'la la-street-view',
            rules: 'menu_main_customers__Visible',
        },
        {
            link: '/tasks',
            title: 'Opravila',
            rules: 'menu_main_tasks__Visible',
            id: 'TASKS',
            icon: 'la la-check-square',
        },
        {
            link: '/accounting/list',
            title: 'Zaračunavanje',
            rules: 'menu_main_accountingVisible',
            id: 'ACCOUNTING',
            icon: 'la la-money',
        },
        {
            link: '/communication',
            title: 'Komunikacija',
            rules: 'menu_main_communicationVisible',
            id: 'COMMUNICATION',
            icon: 'la la-comments',
        },
        {
            link: '/reports',
            title: 'Poročila',
            id: 'REPORTS',
            icon: 'la la-pie-chart',
            rules: 'menu_main_reports__Visible',
        },
    ].map((el) => new MenuItem().deserialize(el));

    public terms: any = [
        {
            link: 'calendar/selected/',
            title: 'Podrobnosti',
            id: 'TERMS/SELECTED',
        },
        {
            link: '/calendar/visit/communication/',
            title: 'Komunikacija',
            id: 'TERMS/VISIT/COMMUNICATION',
        },
    ];

    public index: any = [
        // {
        //     link: '/profile',
        //     title: 'Profil',
        //     id: 'TERMS/VISIT/COMMUNICATION',
        // },
    ];
    // http://localhost:4200/terms/visit/communication
    // http://localhost:4200/terms/selected/936eab0d-d4f1-4139-a52f-227a2f935b79
    public visit: any = [
        {
            link: 'calendar/selected/',
            title: 'Podrobnosti',
            id: 'VISIT/TERMS/SELECTED',
        },
        // {
        //     link: '/terms/visit/portfolio',
        //     title: 'Listine',
        //     id: 'VISIT/TERMS/VISIT/PORTFOLIO',
        // },
        // {
        //     link: '/terms/visit/result',
        //     title: 'Izvidi',
        //     id: 'VISIT/TERMS/VISIT/RESULT'
        // },
        {
            link: '/calendar/visit/services/',
            title: 'Storitve',
            id: 'VISIT/TERMS/VISIT/SERVICES',
        },
        {
            link: '/calendar/visit/communication/',
            title: 'Komunikacija',
            id: 'VISIT/TERMS/VISIT/COMMUNICATION',
        },
    ];

    public customer: any = [
        {
            link: '/customers/view/',
            title: 'Podrobnosti',
            id: 'CUSTOMERS/VIEW',
        },
        {
            link: '/customers/history/',
            title: 'Vse obravnave',
            id: 'CUSTOMERS/HISTORY',
        },
    ];

    public accounting: any = [
        {
            link: '/accounting/list',
            title: 'Seznam',
            id: 'ACCOUNTING/LIST',
            rules: 'menu_main_accountingVisible',
        },
        {
            link: '/accounting/reports/',
            title: 'Izpisi',
            id: 'ACCOUNTING/REPORTS',
            rules: 'menu_main_accountingVisible',
        },
        {
            link: '/accounting/reports-advanced',
            title: 'Napredni izpisi',
            id: 'ACCOUNTING/REPORTS/ADVANCED',
            rules: 'menu_main_accountingVisible',
        },
        {
            link: '/accounting/group-invoice/',
            title: 'Zbirni račun',
            id: 'ACCOUNTING/GROUP-INVOICE',
            rules: 'menu_main_accountingVisible',
        },
        {
            link: '/accounting/unprocessed/',
            title: 'Neobdelani',
            id: 'ACCOUNTING/UNPROCESSED',
            rules: 'menu_main_accountingVisible',
        },
    ];

    public inbox: MenuItem[] = [
        {
            link: '/inbox',
            title: 'Povzetek',
            id: 'INBOX/DASHBOARD',
            routerActiveExact: true,
        },
        {
            link: '/inbox/devices',
            title: 'Naprave',
            id: 'INBOX/DEVICES',
            routerActiveExact: true,
        },
        {
            link: '/inbox/consul',
            title: 'eTriaža',
            id: 'TRIAGE',
            routerActiveExact: false,
        },

        {
            link: '/inbox/case/i',
            title: 'Avtorizacije',
            id: 'INBOX/CASE',
            routerActiveExact: false,
        },
        {
            link: '/inbox/eposvet',
            title: 'ePosvet',
            id: 'INBOX/EPOSVET',
            routerActiveExact: true,
        },
    ].map((el) => new MenuItem().deserialize(el));
    //če hočeš submenu: NE definiraj link in DODAJ submenu
    // Primer: (submenu z 2 linkoma)
    // title: 'Obvestila',
    // rules: 'menu_settings_notifications',
    // id: 'SETTINGS/SEND-CONTENT',
    // submenu: [
    //     { title: 'Vsebina', link: '/settings/send-content' },
    //     { title: 'Pravila', link: '/settings/send-rules' },
    // ],
    public settingsMenuList: MenuItem[] = [
        {
            link: '/settings/users',
            title: 'Uporabniki',
            rules: 'menu_main_settings_users__Visible',
            id: 'SETTINGS/USERS',
        },
        {
            link: '/settings/services',
            title: 'Storitve',
            rules: 'menu_main_settings_services__Visible',
            id: 'SETTINGS/SERVICES',
        },
        {
            link: '/settings/clinic',
            title: 'Ambulanta',
            rules: 'menu_main_settings_clinic__Visible',
            id: 'SETTINGS/CLINIC',
        },
        {
            link: '/settings/accounting',
            title: 'Računovodstvo',
            rules: 'menu_main_accountingVisible',
            id: 'SETTINGS/ACCOUNTING',
        },
        {
            link: '/settings/partners',
            title: 'Partnerji',
            rules: 'menu_main_settings_clinic__Visible',
            id: 'SETTINGS/PARTNERS',
        },
        {
            link: '/settings/printer',
            title: 'Tiskanje',
            rules: 'menu_main_settings_printer__Visible',
            id: 'SETTINGS/PRINTER',
        },
        {
            link: '/settings/availability',
            title: 'Razpoložljivost',
            rules: 'menu_main_settings_availability__Visible',
            id: 'SETTINGS/AVAILABILITY',
        },
        {
            title: 'Obveščanje',
            rules: 'menu_main_settings_sendContent__Visible',
            id: 'SETTINGS/SEND-CONTENT',
            link: '/settings/send-content',
            // submenu: [
            //     { title: 'Vsebina', link: '/settings/send-content' },
            //     { title: 'Pravila', link: '/settings/send-rules' },
            // ],
        },
    ].map((el) => new MenuItem().deserialize(el));

    public hiddeHeader: BehaviorSubject<any> = new BehaviorSubject(false);
    public menuList: BehaviorSubject<any[]> = new BehaviorSubject([]);
    public customerInMenu: Subject<Customer> = new Subject();
    public actionButtonsMenu: Subject<ActionButton[]> = new BehaviorSubject([]);

    public subTitle: BehaviorSubject<any> = new BehaviorSubject('');

    constructor(private storage: StorageService, private permissionsService: NgxPermissionsService) {
        // this.permissionsService.hasPermission('customer_details_canEdit').then(isEditable => {
        // });
    }

    // ngOnDestroy() {
    //     this.params.unsubscribe();
    // }

    public showDefaultMenu(): void {
        // this.clearTitle();
        // this.clearCustomerInMenu();
        // this.menuList.next([]);
    }

    public showTermsOrVisitMenu(id?): void {
        if (id) {
            const term = this.storage.getSelectedTerms(id);

            //Rezervacija
            if (!term.visitId) {
                this.terms[0].link = this.terms[0].link.substring(0, 18);
                this.terms[0].link += `/${id}`;
                this.terms[1].link = this.terms[1].link.substring(0, 29);
                this.terms[1].link += `/${id}`;
                this.menuList.next(this.terms);
            }
            //Obravnava brez rezervacije
            else if (term.visitId && !term.prereservationId) {
                this.visit[0].link = this.visit[0].link.substring(0, 18);
                this.visit[0].link += `/${id}/visit`;
                this.visit[1].link = this.visit[1].link.substring(0, 24);
                this.visit[1].link += `/${id}/visit`;
                this.visit[2].link = this.visit[2].link.substring(0, 29);
                this.visit[2].link += `/${id}/visit`;
                this.menuList.next(this.visit);
            }
            // Obravnava z rezervacijo
            else if (term.visitId && term.prereservationId) {
                // const last1 = this.visit[0].link.lastIndexOf('/');
                // const last2 = this.visit[1].link.lastIndexOf('/');
                // const last3 = this.visit[2].link.lastIndexOf('/');
                // this.visit[0].link = this.visit[0].link.substring(0, last1 + 1);
                this.visit[0].link = this.visit[0].link.substring(0, 18);
                this.visit[0].link += `/${id}`;
                this.visit[1].link = this.visit[1].link.substring(0, 24);
                this.visit[1].link += `/${id}`;
                this.visit[2].link = this.visit[2].link.substring(0, 29);
                this.visit[2].link += `/${id}`;
                this.menuList.next(this.visit);
            }
        }
        //Ne izvede (stara koda)
        else {
            const term = this.storage.getSelectedTerm();
            if (term && term.visitId) {
                this.menuList.next(this.visit);
            } else {
                this.menuList.next(this.terms);
            }
        }
    }

    public showAccountingMenu(): void {
        this.setSubTitle(undefined);
        this.menuList.next(this.validateMenu(this.accounting));
        // this.menuList.next(this.terms);
    }

    public showInboxMenu(): void {
        // debugger;
        this.setSubTitle(undefined);
        this.menuList.next(this.validateMenu(this.inbox));
        // this.menuList.next(this.terms);
    }

    public showIndexMenu(): void {
        // debugger;
        this.setSubTitle(undefined);
        this.menuList.next(this.validateMenu(this.index));
        // this.menuList.next(this.terms);
    }

    public showCustomerMenu(customerid: number): void {
        const last1 = this.customer[0].link.lastIndexOf('/');
        const last2 = this.customer[1].link.lastIndexOf('/');
        this.customer[0].link = this.customer[0].link.substring(0, last1 + 1);
        this.customer[0].link += customerid;
        this.customer[1].link = this.customer[1].link.substring(0, last2 + 1);
        this.customer[1].link += customerid;
        this.menuList.next(this.customer);
    }

    public setSubTitle(text: string): void {
        this.subTitle.next(text);
    }

    public setCustomerInMenu(customer: Customer): void {
        this.customerInMenu.next(customer);
    }

    public resetMenu() {
        this.menuList.next([]);
    }

    public clearTitle(): void {
        this.subTitle.next(undefined);
    }
    public clearCustomerInMenu(): void {
        // debugger;
        this.customerInMenu.next(null);
    }

    public validateMenu(menu: MenuItem[]): any {
        const allPermissions = this.permissionsService.getPermissions();
        // const hiddenMenus: ContractorSettings = this.storage.getContractorSettings();
        const hiddenMenus: ContractorSettings = this.storage.getContractorSettings();
        let res = menu.filter((menuItem: any) => {
            if (menuItem.onlyTest) {
                if (!environment.test) {
                    return false;
                }
            }
            if (hiddenMenus && menuItem.id && _.has(hiddenMenus, 'menu.hidden') && !_.isNil(hiddenMenus.menu.hidden)) {
                if (hiddenMenus.menu.hidden.includes(menuItem.id)) {
                    return false;
                    // ima dovoljenje za prikaz
                }
            }
            if (menuItem.rules) {
                if (allPermissions[menuItem.rules]) {
                    // ima dovoljenje za prikaz
                    return true;
                }
                // nima role - skrij
                return false;
            }
            return true;
        });
        return res;
        // this.permissionsService.hasPermission('customer_details_canEdit').then(isEditable => {
        //     debugger;
        // });
    }

    public loadSettingsMenu(user: User): any {
        this.settingsMenuList[0].title = user.name;
        this.settingsMenuList[2].title = _.get(this.storage.getSelectedContractor(), 'name', '');
        return this.validateMenu(this.settingsMenuList);
    }

    // public settingsMenuValidated(): any {
    //     debugger;
    //     return this.validateMenu(this.settingsMenuList);
    // }
}
