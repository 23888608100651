import { GuiUserSettings } from './../model/userGuiSettings/guiUserSettings.model';
import {
    CalendarFilterSettings,
    Contractor,
    ContractorSettings,
    Customer,
    Event,
    GuiUserContractorSettings,
    MfModule,
    Service,
    Slot,
    User,
} from '../model';
import { Injectable } from '@angular/core';
import { PreReservation } from '../model/preReservation.model';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

@Injectable()
export class StorageService {
    // credentialsData: any;

    constructor() {
        // debugger;
        // this.credentialsData = JSON.parse(localStorage.getItem('credentialsData'));
        // this.onTabChange = new EventEmitter<any>();
    }

    public saveToken(token: any): void {
        localStorage.setItem('385097849110528', JSON.stringify(token));
    }

    public getToken(): any {
        return JSON.parse(localStorage.getItem('385097849110528'));
    }

    public clearToken(): any {
        localStorage.removeItem('385097849110528');
    }

    // public getLoggedInUser(): User {
    //     debugger;
    //     return new User();
    //     // return new User({
    //     //     name: this.credentialsData.extraData.doctorFullName,
    //     //     doctorBpi: this.credentialsData.extraData.doctorBpi,
    //     //     institutions: this.credentialsData.extraData.institutions,
    //     // });
    // }

    //Contractor
    public setSelectedContractor(con: Contractor): void {
        this.set('mf-selectedContractor', con);
    }

    public getSelectedContractor(): Contractor {
        return this.get('mf-selectedContractor');
    }

    //subcontractor
    public setSelectedSubcontractor(con: Contractor): void {
        this.set('selectedSubcontractor', con);
    }

    public getSelectedSubcontractor(): Contractor {
        return this.get('selectedSubcontractor');
    }

    //ALL subcontractor
    public setAllSubcontractors(con: Contractor[]): void {
        this.set('mf-allSubcontractors', con);
    }

    public getAllSubcontractors(): Contractor {
        return this.get('mf-allSubcontractors');
    }

    //Service
    public setSelectedService(con: Service): void {
        this.set('selectedService', con);
    }

    public getSelectedService(): Service {
        return this.get('selectedService');
    }

    //TimeSlot
    public setSelectedTimeSlot(con: Slot): void {
        this.set('selectedTimeSlot', con);
    }

    public getSelectedTimeSlot(): Slot {
        return this.get('selectedTimeSlot');
    }

    //PreReservation
    public setSelectedPreReservation(con: PreReservation): void {
        this.set('selectedPreReservation', con);
    }

    public getSelectedPreReservation(): PreReservation {
        return this.get('selectedPreReservation');
    }

    //setUserData
    public setUserData(con: User): void {
        // debugger;
        this.set('userData', con);
    }

    public getUserData(): User {
        // debugger;
        return this.get('userData');
    }

    public setSocketSessionId(id: string): void {
        sessionStorage.setItem('socketSessionId', id);
    }

    public getSocketSessionId(): string {
        return sessionStorage.getItem('socketSessionId');
    }

    public deleteUserData() {
        localStorage.removeItem('userData');
    }

    public getUserRoles(): any {
        const accessToken = this.getToken();
        if (!accessToken || !accessToken.access_token) {
            return null;
        }
        const tokenData = JSON.parse(atob(accessToken.access_token.split('.')[1]));
        if (tokenData.sdata.contractorsRoles && tokenData.sdata.contractorsRoles[this.getSelectedContractor().id]) {
            return tokenData.sdata.contractorsRoles[this.getSelectedContractor().id];
        }
        return [];

        //return this.get('mf-userRoles');
    }

    // @TODO Odstrani čez nekaj releasov. Za zdaj puščam, da bo vsem uporabnikom prepucalo storage.
    public deleteUserRoles() {
        localStorage.removeItem('mf-userRoles');
    }

    public setSubcontractor(con: Contractor): void {
        this.set('selectedSubcontractor', con);
    }

    public getSubcontractor(): Contractor {
        return this.get('selectedSubcontractor');
    }

    // {
    //     0 : {'id',termData},
    //     1 : {'id2', termData2 }

    // }
    //Selected term
    public setSelectedTerms(termData: Event, id: string): void {
        let allData = JSON.parse(this.get('selectedTerms'));
        if (!allData) {
            allData = {};
        }
        allData[id] = termData;
        this.set('selectedTerms', JSON.stringify(allData));
    }

    public getSelectedTerms(id: string): Event {
        let data = JSON.parse(this.get('selectedTerms'));
        return _.get(data, `[${id}]`, undefined);
    }

    public deleteSelectedTerms(): void {
        localStorage.removeItem('selectedTerms');
    }

    public deleteState(): void {
        localStorage.removeItem('@@STATE');
    }

    public setSelectedTerm(termData: Event): void {
        this.set('selectedTerm', termData);
    }

    public authNurse(): void {
        this.set('nurseAuth', true);
    }
    
    public getAuthNurse(): any {
        return this.get('nurseAuth');
    }

    public deleteSelectedTerm(): void {
        localStorage.removeItem('selectedTerm');
    }
    

    public deleteAuthNurse(): void {
        localStorage.removeItem('nurseAuth');
    }

    public getSelectedTerm(): Event {
        return this.get('selectedTerm');
    }

    public getAnySelectedTerms(): Event {
        return this.get('selectedTerms');
    }
    

    //Selected customer id
    public setSelectedCustomerId(id: number): void {
        localStorage.removeItem('selectedCustomerId');
        this.set('selectedCustomerId', id);
    }

    public getSelectedCustomerId(): number {
        return this.get('selectedCustomerId');
    }

    //Selected customer
    public setSelectedCustomer(c: Customer): void {
        localStorage.removeItem('selectedCustomer');
        this.set('selectedCustomer', c);
    }

    public getSelectedCustomer(): Customer {
        return this.get('selectedCustomer');
    }

    //Customer for term selection
    public setCustomerForTerm(c: Customer): void {
        localStorage.removeItem('customerForTerm');
        this.set('customerForTerm', JSON.stringify(c));
    }

    public getCustomerForTerm(): Customer {
        return new Customer().deserialize(JSON.parse(this.get('customerForTerm')));
    }

    public deleteCustomerForTerm(): void {
        localStorage.removeItem('customerForTerm');
    }

    //AppData
    public setAppData(c: any): void {
        this.set('mf-appData', c);
    }

    public getAppData(): any {
        return this.get('mf-appData');
    }

    public deleteAppData(): void {
        localStorage.removeItem('mf-appData');
    }

    //GuiUserSettings
    public setGuiUserContractorSettings(c: GuiUserContractorSettings): void {
        this.set('mf-4d0f11f2-bca3-5cfc-8b70-409854acb273', c);
    }

    public setUserGuiSettings(c: GuiUserSettings): void {
        this.set('373b655a-5140-55a0-adda-9d685f54dea9', c);
    }

    public getUserGuiSettings(c: GuiUserSettings): GuiUserSettings {
        return new GuiUserSettings().deserialize(this.get('373b655a-5140-55a0-adda-9d685f54dea9'));
    }

    public getGuiUserSettings(): GuiUserContractorSettings {
        return new GuiUserContractorSettings().deserialize(this.get('mf-4d0f11f2-bca3-5cfc-8b70-409854acb273'));
    }

    public getGuiUserSettingsObservable(): Observable<GuiUserContractorSettings> {
        return new Observable((observer) => {
            observer.next(
                new GuiUserContractorSettings().deserialize(this.get('mf-4d0f11f2-bca3-5cfc-8b70-409854acb273')),
            );
        });
    }

    //ContractorSettings
    public setContractorSettings(c: ContractorSettings): void {
        this.set('8c100e9f-f60b-5d14-9177-97ddc05c6da9', c);
    }

    public getContractorSettings(): ContractorSettings {
        return new ContractorSettings().deserialize(this.get('8c100e9f-f60b-5d14-9177-97ddc05c6da9'));
    }

    public deleteContractorSettings(): any {
        localStorage.removeItem('8c100e9f-f60b-5d14-9177-97ddc05c6da9');
    }

    // public getContractorSettingsObservable(): Observable<GuiUserSettings> {
    //     return new Observable(observer => {
    //         observer.next(new GuiUserSettings().deserialize(this.get('mf-4d0f11f2-bca3-5cfc-8b70-409854acb273')));
    //     });
    // }
    //Accountgin tmp save overriden  payer
    public setAccountingPayer(c: any): void {
        this.set('3dee9fa4-2dc6-5928-a582-0324c02308e3', c);
    }

    public getAccountingPayer(): any {
        return this.get('3dee9fa4-2dc6-5928-a582-0324c02308e3');
    }

    public deleteAccountingPayer(): any {
        localStorage.removeItem('3dee9fa4-2dc6-5928-a582-0324c02308e3');
    }

    // public getGuiUserSettingsObservable(): Observable<GuiUserSettings> {
    //     return Observable.create(
    //         new GuiUserSettings().deserialize(this.get('mf-4d0f11f2-bca3-5cfc-8b70-409854acb273'))
    //     );
    // }

    public deleteGuiUserSettings(): void {
        localStorage.removeItem('mf-4d0f11f2-bca3-5cfc-8b70-409854acb273');
    }

    //module validation
    public setActiveModules(c: any): void {
        this.set('0fe413d1-6db8-5865-b9a4-bde609c9e8ac', btoa(unescape(encodeURIComponent(JSON.stringify(c)))));
    }

    public getActiveModules(): MfModule[] {
        if (_.isNil(this.get('0fe413d1-6db8-5865-b9a4-bde609c9e8ac'))) {
            return [];
        }
        return JSON.parse(atob(this.get('0fe413d1-6db8-5865-b9a4-bde609c9e8ac')));
    }

    public deleteActiveModules(): any {
        localStorage.removeItem('0fe413d1-6db8-5865-b9a4-bde609c9e8ac');
    }

    //Floodid
    public setFloodId(c: any): void {
        this.set('402addfe-06c2-5474-9df8-efb263832437', c);
    }

    public getFloodId(): any {
        return this.get('402addfe-06c2-5474-9df8-efb263832437');
    }

    // delete
    public deleteAll(): void {
        localStorage.removeItem('selectedPreReservation');
        localStorage.removeItem('selectedTimeSlot');
        localStorage.removeItem('selectedService');
        localStorage.removeItem('selectedContractor');
        localStorage.removeItem('mf-selectedContractor');
        localStorage.removeItem('customerForTerm');
        localStorage.removeItem('selectedCustomerId');
        localStorage.removeItem('selectedTerm');
        localStorage.removeItem('selectedCustomer');
        localStorage.removeItem('selectedCustomer');
        this.deleteGuiUserSettings();
        this.deleteUserRoles();
        this.deleteUserData();
        this.deleteContractorSettings();
    }

    //helpers
    private set(name: string, data) {
        localStorage.setItem(name, JSON.stringify(data));
    }

    private get(name: string): any {
        const data: any = localStorage.getItem(name);
        try {
            return JSON.parse(data);
        } catch (e) {
            return {};
        }
    }

    // public setCurrentContractorID(name: string, data: string) {
    //     localStorage.setItem(name, data);
    // }

    // public getCurrentContractorID(name: string): any {
    //     return localStorage.getItem(name);
    // }

    //Availibility
    public getAvailibilityFilter(): CalendarFilterSettings {
        return new CalendarFilterSettings().deserialize(this.get('availibilityFilter'));
    }

    public setAvailibilityFilter(filter: CalendarFilterSettings): void {
        this.set('availibilityFilter', filter);
    }

    // public getAvailibilityData(): any {
    //     return this.get('availibilityData');
    // }
    // public setAvailibilityData(data: any): void {
    //     this.set('availibilityData', data);
    // }
    public deleteAvailibilityData(): void {
        localStorage.removeItem('availibilityFilter');
        localStorage.removeItem('availibilityData');
    }

    public isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }
}
