import { NgModule } from '@angular/core';
import { DaterangepickerComponent } from './ng2-daterangepicker.component';

@NgModule({
  declarations: [DaterangepickerComponent],
  imports: [
  ],
  exports: [DaterangepickerComponent]
})
export class Daterangepicker { }
