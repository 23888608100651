import {InvoiceDetails} from './invoiceDetails.model';
import * as _ from 'lodash';
import {Serializable} from '../../deserialize.service';

export class InvoiceMain implements Serializable<InvoiceMain> {
    cashierName: string;
    companyInfo: string;
    printCopy: string;
    userId: string;
    invoiceDetails: InvoiceDetails;

    deserialize(input: any) {
        if (_.isNil(input)) {
            return undefined;
        }

        this.cashierName = input.cashierName;
        this.companyInfo = input.companyInfo;
        this.printCopy = input.printCopy;
        this.userId = input.userId;
        if (_.has(input, 'invoiceDetails')) {
            this.invoiceDetails = new InvoiceDetails().deserialize(input.invoiceDetails);
        }
        return this;
    }
}
