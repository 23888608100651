import {PreReservation} from '../../../../_shared/model/preReservation.model';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import 'rxjs';
import {HttpClientService} from '../../../../_shared/services/http-client.service';
import {HelperService} from '../../../../_shared/services';

@Injectable()
export class TermsService {
    data: any;
    token: any;

    constructor(private httpClient: HttpClientService, private helper: HelperService) {}

    public getAllSubcontractors(cid: number): Observable<any> {
        // debugger;
        return this.httpClient.get(this.helper.getApiUrl() + '/contractors/' + cid + '/subcontractors');
    }

    public cancelReservation(prereservation: PreReservation): Observable<any> {
        return this.httpClient.post(this.helper.getApiUrl() + '/reservation/delete', prereservation);
    }
    // getReservationByPrereservationId()
    public getReservation(contractorId: number, prereservationId: string): Observable<any> {
        // debugger;
        return this.httpClient.get(
            this.helper.getApiUrl() + '/contractors/' + contractorId + '/reservations/' + prereservationId,
        );
    }

    public customerNoShow(prereservation: string, notShow: boolean): Observable<any> {
        const data: any = {
            noShow: notShow,
        };
        return this.httpClient.put(this.helper.getApiUrl() + '/reservations/' + prereservation, data);
    }
    public updateCovidSurveys(
        reservationId: string,
        contractorId: number,
        customerId: number,
        surveysData,
    ): Observable<any> {
        // http://localhost:9090/booking/erp/api/reservations/6b397f42-2cab-4411-a70e-951a0562f78f/contractors/54/customers/1262/covid/update
        return this.httpClient.put(
            this.helper.getApiUrl() +
                `/reservations/${reservationId}/contractors/${contractorId}/customers/${customerId}/covid/update`,
            surveysData,
        );
    }
    public getAllCovidSurveys(reservationId: string, contractorId: number): Observable<any> {
        // http://localhost:9090/booking/erp/api/reservations/79a6b20a-af2b-4d89-ba77-e7c5ba6ef101/contractors/54/customers/1262/covid
        return this.httpClient.get(
            this.helper.getApiUrl(2) + `/reservations/${reservationId}/contractors/${contractorId}/covid`,
        );
    }

    public sendCovidSurveyToCustomer(reservationId: string, contractorId: number, customerId: number): Observable<any> {
        // http://localhost:9090/booking/erp/api/reservations/79a6b20a-af2b-4d89-ba77-e7c5ba6ef101/contractors/54/customers/1262/covid/send
        return this.httpClient.post(
            this.helper.getApiUrl() +
                `/reservations/${reservationId}/contractors/${contractorId}/customers/${customerId}/covid/send`,
        );
    }
    public confirmCovidSurveysByCustomer(guid: string): Observable<any> {
        //http://localhost:9090/booking/erp/api/covid/045f174d-6814-40f8-bc01-099857d240c3
        return this.httpClient.post(this.helper.getApiUrl() + `/covid/${guid}`);
    }
    public getCovidDataFromGuid(guid: string): Observable<any> {
        //localhost:9090/booking/erp/api/covid/045f174d-6814-40f8-bc01-099857d240c3
        return this.httpClient.get(this.helper.getApiUrl(2) + `/covid/${guid}`);
    }
    
}
