import { User } from './../model/user.model';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { StorageService } from './storage.service';

@Injectable()
export class ErrorNotifyService {
    constructor(private storage: StorageService) {}

    public send(error: any, severity?: any): void {
        if (this.storage.getUserData()) {
            const user: User = this.storage.getUserData();
        }
    }
}
