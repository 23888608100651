import {ContractorPrintHeader} from './../../../../_shared/model/settings/contractorPrintHeader.model';
import {GroupTag} from '../../../../_shared/model/settings/groupTag.model';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import 'rxjs';
import {HttpClientService} from '../../../../_shared/services/http-client.service';
import {environment} from '../../../../../environments/environment';
import {Asset, ContractorBasicInfo, Employee, Service, Subcontractor, User,} from '../../../../_shared/model';
import {HelperService, StorageService} from '../../../../_shared/services';
import {map} from 'rxjs/operators';

@Injectable()
export class CommonService {
    data: any;
    token: any;

    constructor(
        private httpClient: HttpClientService,
        private storage: StorageService,
        private helper: HelperService,
    ) {}

    public getContractorHeaders(contractorid: number) {
        return this.httpClient.get(this.helper.getApiUrl() + `/contractors/${contractorid}/print/rules`);
    }
    public changeContractorHeaders(contractorid: number, data: ContractorPrintHeader) {
        return this.httpClient.put(this.helper.getApiUrl() + `/contractors/${contractorid}/print/rules`, data);
    }
    public deleteGroupTag(contractorid: number, tagId: number): Observable<any> {
        return this.httpClient.delete(this.helper.getApiUrl() + `/contractors/${contractorid}/group-tags/${tagId}`);
    }

    public getGroupTags(contractorid: number): Observable<GroupTag[]> {
        return this.httpClient.get(this.helper.getApiUrl() + `/contractors/${contractorid}/group-tags`);
    }

    //getContractorAppUsers()
    public getContractorAppUsers(cid: number): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl() + '/contractors/' + cid + '/users');
    }

    // updateEmployee()
    public updateEmployee(contractorid: number, employeeId: number, employee: Employee): Observable<any> {
        return this.httpClient.put(
            this.helper.getApiUrl() + '/contractors/' + contractorid + '/employees/' + employeeId,
            employee,
        );
    }
    public createEmployee(contractorid: number, employee: Employee): Observable<any> {
        return this.httpClient.post(this.helper.getApiUrl() + '/contractors/' + contractorid + '/employees/', employee);
    }

    //getEmployee()
    public getEmployee(cid: number, employeeid: number): Observable<Employee> {
        return this.httpClient.get(this.helper.getApiUrl() + '/contractors/' + cid + '/employees/' + employeeid);
    }

    //getEmployees()
    public getAllEmployees(cid: number): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl() + '/contractors/' + cid + '/employees');
    }

    public getAllAssets(cid: number): Observable<Asset[]> {
        return this.httpClient.get(this.helper.getApiUrl() + '/contractors/' + cid + '/assets');

        // .pipe(
        //     map((allAssets: Asset[]) => {
        //         debugger;
        //         return allAssets;
        //     }),
        // );
    }

    //getContractorSubcontractors()
    public getAllSubcontractors(cid: number, showAll: boolean = false): Observable<Subcontractor[]> {
        if (showAll) {
            return this.httpClient.get(this.helper.getApiUrl() + '/contractors/' + cid + '/subcontractors');
        }
        return this.httpClient.get(this.helper.getApiUrl() + '/contractors/' + cid + '/subcontractors').pipe(
            map((data) => {
                return data.filter((el) => el.active);
            }),
        );
    }
    //GetSubcontractor()
    public getSubcontractor(cid: number, sid: number): Observable<Subcontractor> {
        return this.httpClient.get(this.helper.getApiUrl() + '/contractors/' + cid + '/subcontractors/' + sid);
    }

    public getServicesForSubcontractor(cid: number, scid: number): Observable<Service[]> {
        return this.httpClient.get(
            this.helper.getApiUrl() + '/contractors/' + cid + '/subcontractors/' + scid + '/services',
        );
    }

    public getServicesForContractor(cid: number): Observable<Service[]> {
        return this.httpClient.get(this.helper.getApiUrl(2) + `/contractors/${cid}/services`);
    }

    public getServiceForContractor(contractorid: number, serviceid: number): Observable<Service> {
        return this.httpClient.get(this.helper.getApiUrl(2) + `/contractors/${contractorid}/conservices/${serviceid}`);
    }

    //getSubcontractorsDeliveringService()
    public getSubcontractorsDeliveringService(contractorid: number, serviceid: number): Observable<Subcontractor[]> {
        return this.httpClient.get(this.helper.getApiUrl() + `/contractors/${contractorid}/services/${serviceid}`);
    }

    public searchCustomers(query: string): Observable<any> {
        return this.httpClient.get(
            this.helper.getApiUrl(2) +
                '/contractors/' +
                this.storage.getSelectedContractor().id +
                '/customers/search?query=' +
                query,
        );
    }

    //searchDiagnoses()
    public searchDiagnose(query: string): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl(2) + '/diagnoses/search?query=' + query);
    }

    public getContractorEmployees(cid: number): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl() + '/contractors/' + cid + '/employees');
    }

    public setAppPositionVisit(visitId: number): Observable<any> {
        return this.httpClient.put(this.helper.getApiUrl() + '/app/visit/' + visitId);
    }

    public deleteAppPosition(): Observable<any> {
        return this.httpClient.delete(this.helper.getApiUrl() + '/app');
    }

    //changePwd()
    public changePwd(data: any): Observable<any> {
        return this.httpClient.post(this.helper.getApiUrl() + '/user/pwd', data);
    }
    //getUserRoles()
    public getUserRoles(contractorid: number): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl(2) + '/roles/' + contractorid);
    }

    public getUserRequestLinks(userId: number): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl() + `/user/${userId}/contractorUserLinkRequests`);
    }

    public confirmContractorUserLinkRequest(requestId: number): Observable<any> {
        return this.httpClient.put(this.helper.getApiUrl() + `/contractorUserLinkRequest/${requestId}`);
    }

    public getContractorLinkRequests(contractorId: number): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl() + `/contractors/${contractorId}/contractorUserLinkRequests`);
    }

    public cancelContractorLinkRequest(requestId: number) {
        return this.httpClient.delete(this.helper.getApiUrl() + `/contractorLinkRequest/${requestId}`);
    }
    public cancelUserLinkRequest(requestId: number) {
        return this.httpClient.delete(this.helper.getApiUrl() + `/userLinkRequest/${requestId}`);
    }

    public deactivateUser(cid: number, userId: number): Observable<any> {
        return this.httpClient.delete(this.helper.getApiUrl() + `/contractors/${cid}/users/${userId}`);
    }

    public createNewContractorUser(cid: number, data: any): Observable<any> {
        return this.httpClient.post(this.helper.getApiUrl() + `/contractors/${cid}/contractorNewUserRequest`, data);
    }
    public saveShowNewTaskReceivedNotification(cid: number, userid: number, data: any): Observable<any> {
        return this.httpClient.post(
            this.helper.getApiUrl() + `/contractors/${cid}/users/${userid}/showNewTaskReceivedNotification`,
            data,
        );
    }

    //addUserRole()
    public addUserRole(cid: number, uid: number, data: string[]): Observable<any> {
        return this.httpClient.post(this.helper.getApiUrl() + '/contractors/' + cid + '/users/' + uid + '/roles', data);
    }

    //setUserRoles()
    public setUserRoles(cid: number, uid: number, data: string[]): Observable<any> {
        return this.httpClient.put(this.helper.getApiUrl() + '/contractors/' + cid + '/users/' + uid + '/roles', data);
    }

    //getZipCodes()
    public getZipCodes(): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl() + '/zipcodes');
    }

    //getAllRoles()
    public getAllRoles(contractorid: number): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl() + '/contractors/' + contractorid + '/users/roles');
    }

    public sendToSlack(data: any): void {
        this.httpClient
            .postClient(environment.ERROR_URL, data, {
                headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' },
            })
            .subscribe((res) => {}, (err) => {});
    }

    public sendToSlack2(data: any): void {
        this.httpClient
            .postClient(environment.ERROR_URL, data, {
                headers: { 'Content-Type': 'application/json; charset=UTF-8' },
            })
            .subscribe((res) => {}, (err) => {});
    }
    public getContractorBasicInfo(cid: number): Observable<ContractorBasicInfo> {
        return this.httpClient.get(this.helper.getApiUrl(2) + '/contractors/basicinfo/' + cid);
    }

    public getContractorCouponCode(cid: number) {
        return this.httpClient.get(this.helper.getAccountingUrl(2) + '/contractors/' + cid + '/coupons/counter');
    }

    public updateSubcontractor(
        contractorid: number,
        subcontractorid: number,
        subcontractor: Subcontractor,
    ): Observable<any> {
        return this.httpClient.put(
            this.helper.getApiUrl() + '/contractors/' + contractorid + '/subcontractors/' + subcontractorid,
            subcontractor,
        );
    }

    public createSubcontractor(contractorid: number, subcontractor: Subcontractor): Observable<any> {
        return this.httpClient.post(
            this.helper.getApiUrl() + '/contractors/' + contractorid + '/subcontractors/',
            subcontractor,
        );
    }

    public generateSlackObj(title: string, type: string, error: any, file?: string): any {
        let slackData: any = {};
        try {
            let user: string = 'Unknown';
            let color: string = '898989';
            if (type === 'ERROR') {
                color = 'ed1c24';
            }
            if (type === 'WARNING') {
                color = 'ffde00';
            }

            if (type === 'INFO') {
                color = '0072bc';
            }

            if (this.storage.getUserData()) {
                user = this.storage.getUserData().name + ' (' + this.storage.getUserData().id + ')';
            }

            if (error._body && typeof error._body === 'string') {
                const errorBody = error;
                slackData = {
                    attachments: [
                        {
                            fallback: type + ' - ' + title,
                            color: '#' + color,
                            pretext: type + ' - ' + title,
                            // author_name: title,
                            // text: 'Optional text that appears within the attachment',
                            fields: [
                                {
                                    title: 'User',
                                    value: user,
                                    short: true,
                                },
                                {
                                    title: type,
                                    value: errorBody.error,
                                    short: true,
                                },
                                {
                                    title: 'Status',
                                    value: error.status,
                                    short: true,
                                },
                                {
                                    title: 'File',
                                    value: file ? file : 'Unknown',
                                    short: true,
                                },
                                {
                                    title: 'URL',
                                    value: error.url,
                                    short: false,
                                },
                                {
                                    title: 'Description',
                                    value: errorBody.error_description,
                                    short: false,
                                },

                                // {
                                //     title: 'Full stack',
                                //     value: '```' + JSON.stringify(error) + '```',
                                //     short: false
                                // }
                            ],
                        },
                    ],
                };
            } else if (error.message && error.stack) {
                slackData = {
                    attachments: [
                        {
                            fallback: type + ' - ' + title,
                            color: '#' + color,
                            pretext: type + ' - ' + title,
                            // author_name: title,
                            // text: 'Optional text that appears within the attachment',
                            fields: [
                                {
                                    title: 'User',
                                    value: user,
                                    short: true,
                                },
                                {
                                    title: type,
                                    value: 'ngError',
                                    short: true,
                                },
                                {
                                    title: 'File',
                                    value: file ? file : 'Unknown',
                                    short: true,
                                },
                                {
                                    title: 'Description',
                                    value: error.message,
                                    short: false,
                                },

                                // {
                                //     title: 'Full stack',
                                //     value: '```' + error.stack + '```',
                                //     short: false
                                // }
                            ],
                        },
                    ],
                };
            } else {
                slackData = {
                    attachments: [
                        {
                            fallback: type + ' - ' + title,
                            color: '#' + color,
                            pretext: type + ' - ' + title,
                            // author_name: title,
                            // text: 'Optional text that appears within the attachment',
                            fields: [
                                {
                                    title: 'User',
                                    value: user,
                                    short: true,
                                },
                                {
                                    title: type,
                                    value: 'ngError',
                                    short: true,
                                },
                                {
                                    title: 'File',
                                    value: file ? file : 'Unknown',
                                    short: true,
                                },
                                // {
                                //     title: 'Full stack',
                                //     value: '```' + JSON.stringify(error) + '```',
                                //     short: false
                                // }
                            ],
                        },
                    ],
                };
            }

            // else {
            //     slackData = { text: type + ' ```' + JSON.stringify(error) + '```' };
            // }
        } catch (e) {
            return { text: type + ' ```' + e + '```' };
        }
        return slackData;
    }

    public sendError(title: string, error: any, file?: string): void {
        this.sendToSlack(this.generateSlackObj(title, 'ERROR', error, file));
    }

    public sendWarning(title: string, error: any, file?: string): void {
        this.sendToSlack(this.generateSlackObj(title, 'WARNING', error, file));
    }

    public sendInfo(title: string): void {
        let slackData = {
            attachments: [
                {
                    fallback: title,
                    color: '#0072bc',
                    pretext: title,
                    // author_name: title,
                    text: 'Optional text that appears within the attachment',
                },
            ],
        };
        this.sendToSlack2(slackData);
    }

    public getUserData(): Observable<User> {
        return this.httpClient.get(this.helper.getApiUrl(2) + '/userinfo/');
    }
}
