import {Serializable} from '../deserialize.service';

export class JumpTo implements Serializable<JumpTo> {
    type: string;
    name: string;
    data: any;

    deserialize(input) {
        this.type = input.type;
        this.name = input.name;
        this.data = input.data;

        return this;
    }
}
