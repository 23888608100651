import {MenuService} from './../../_shared/services/menu.service';
import {ClearTreatmentState} from './../../_shared/state/treatment/treatment.actions';
import {ClearState} from './../../_shared/state/base/base.actions';
import {StorageService} from '../../_shared/services';
import {CommonService} from '../../theme/pages/home/_services';
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {Router} from '@angular/router';
import {AuthenticationService} from '../_services';
import {Helpers} from '../../helpers';
import {Store} from '@ngxs/store';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class LogoutComponent implements OnInit {
    constructor(
        private _router: Router,
        private _authService: AuthenticationService,
        private commonRest: CommonService,
        // private helper: HelperService,
        private ls: StorageService,
        private store: Store,
        private menu: MenuService,
    ) {}

    ngOnInit(): void {
        Helpers.setLoading(true);
        this.store.dispatch(new ClearState());
        this.ls.deleteAvailibilityData();
        this.ls.deleteActiveModules();
        this.ls.deleteSelectedTerms();
        this.menu.resetMenu();
        // this.ls.deleteState();
        // reset login status
        // this.commonRest.deleteAppPosition().subscribe();
        this._authService.logout();
        this._router.navigate(['/login']);
        this.store.dispatch([new ClearState(), new ClearTreatmentState()]);
    }
}
