import {NgxPermissionsService} from 'ngx-permissions';
import {Injectable} from '@angular/core';
import {from, Observable} from 'rxjs';
import {HttpClientService} from '../../../../_shared/services/http-client.service';
import {HelperService} from '../../../../_shared/services';

import {mergeMap} from 'rxjs/operators';

@Injectable()
export class IndexService {
    data: any;
    token: any;

    constructor(
        private httpClient: HttpClientService,
        private helper: HelperService,
        private permissionsService: NgxPermissionsService,
    ) {}

    // findReservations()
    public getReservationList(cid: number, subcontractorId: number, fromdate: string, to: string): Observable<any> {
        let promise: Promise<any> = this.permissionsService.hasPermission('index_termsViewAll');
        return from(promise).pipe(
            mergeMap((hasPermission) => {
                let url: string = '';
                if (hasPermission) {
                    url =
                        this.helper.getApiUrl() +
                        '/contractors/' +
                        cid +
                        '/reservations?from=' +
                        fromdate +
                        '&to=' +
                        to;
                } else {
                    url =
                        this.helper.getApiUrl() +
                        '/contractors/' +
                        cid +
                        '/subcontractors/' +
                        subcontractorId +
                        '/reservations?from=' +
                        fromdate +
                        '&to=' +
                        to;
                }

                return this.httpClient.get(url);
            }),
        );
    }
}
