import {PaperType} from '../../enums/PaperType.enum';
import {Serializable} from '../deserialize.service';
import * as moment from 'moment';

export class PortfolioPaper implements Serializable<PortfolioPaper> {
    public id: number;
    public visitId: number;
    public paperType: PaperType;
    public created: moment.Moment;

    deserialize(input: any) {
        this.id = input.id;
        this.paperType = input.paperType;
        this.visitId = input.visitId;
        if (input.created) {
            this.created = moment(input.created);
        }
        return this;
    }
}
