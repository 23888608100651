import { catchError, mergeMap, map } from 'rxjs/operators';
import { Observable, empty, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest, HttpEvent, HttpHeaders } from '@angular/common/http';
import { StorageService } from './storage.service';
import { Router } from '@angular/router';
import { HelperService } from './helper.service';
import { environment } from '../../../environments/environment';
import * as _ from 'lodash';
import { Captcha } from '../model';

@Injectable()
export class HttpClientService {
    constructor(
        private httpClient: HttpClient,
        private storage: StorageService,
        private router: Router,
        private helper: HelperService,
    ) {}

    public postClient(url: string, data?: any, options?: any): Observable<any> {
        return this.httpClient.post(url, data, options);
    }

    public post(url: string, data?: any, options?: any): Observable<any> {
        // let token = this.storage.getToken();
        const httpOptions = {
            // headers: new HttpHeaders({ authorization: 'Bearer ' + token.access_token })
        };
        if (options) {
            Object.assign(httpOptions, options);
        }
        return this.httpClient.post(url, data, httpOptions);
    }

    public put(url: string, data?: any, options?: any): Observable<any> {
        // let token = this.storage.getToken();
        const httpOptions = {
            // headers: new HttpHeaders({ authorization: 'Bearer ' + token.access_token })
        };
        if (options) {
            Object.assign(httpOptions, options);
        }
        return this.httpClient.put(url, data, httpOptions);
    }

    public delete(url: string, options?: any): Observable<any> {
        // let token = this.storage.getToken();
        const httpOptions = {
            // headers: new HttpHeaders({ authorization: 'Bearer ' + token.access_token })
        };
        if (options) {
            Object.assign(httpOptions, options);
        }
        return this.httpClient.delete(url, httpOptions);
    }

    public get(url: string, options?: any): Observable<any> {
        const httpOptions = {
            // headers: new HttpHeaders({ authorization: 'Bearer ' + token.access_token })
        };
        if (options) {
            Object.assign(httpOptions, options);
        }
        return this.httpClient.get(url, httpOptions);
    }

    // public get(url: string): Observable<any> {
    //     return this.request(url, { method: RequestMethod.Get })
    //         .map((response: Response) => response)
    //         .catch(error => {
    //             console.log('napaka pri get');
    //             console.log(error);
    //             return observableThrowError(error);
    //         });
    // }

    public getAttachment(url: string): Observable<HttpEvent<any>> {
        // let data = this.storage.getToken();
        let options = {
            responseType: 'blob',
            observe: 'response',
            // headers: new HttpHeaders({ authorization: 'Bearer ' + data.access_token })
        };
        const req = new HttpRequest('GET', url, options);
        return this.httpClient.request(req);
    }

    public getAttachmentPost(url: string, data?: any): Observable<HttpEvent<any>> {
        //'blob' as 'json':=> https://github.com/angular/angular/issues/18586#issuecomment-336600793

        // let data = this.storage.getToken();
        let params = new HttpParams();
        let options = {
            headers: new HttpHeaders(),
            reportProgress: false,
            params: params,
            responseType: 'blob' as 'json',
            withCredentials: false,
        };

        // let options = {
        //     responseType: 'blob'
        //     // headers: new HttpHeaders({ authorization: 'Bearer ' + data.access_token })
        // };
        const req = new HttpRequest('POST', url, data, options);
        return this.httpClient.request(req);
    }

    // public downloadPDF(url): any {
    //     let data = this.storage.getToken();
    //     let options = {
    //         headers: new HttpHeaders({ 'authorization': 'Bearer ' + data.access_token})
    //     };

    //     return this.httpClient.get(url, { responseType: 'blob'}, options));
    // }

    public uploadFile(url: string, file: File, comment?: string): Observable<HttpEvent<any>> {
        let formData = new FormData();
        formData.append('file', file);
        formData.append('comment', comment);

        let data = this.storage.getToken();

        let params = new HttpParams();

        let options = {
            params: params,
            reportProgress: true,
            responseType: 'text' as 'text',
            headers: new HttpHeaders({ authorization: 'Bearer ' + data.access_token }),
        };

        // options.headers.append('content-length', '13110529');

        const req = new HttpRequest('POST', url, formData, options);
        return this.httpClient.request(req);
    }

    public refreshToken(): Observable<any> {
        // debugger;
        const token = this.storage.getToken();
        // let headersConf = new Headers();
        // headersConf.append('authorization', 'Basic ' + environment.AUTHPASS);
        // headersConf.append('content-type', 'application/x-www-form-urlencoded');

        const options = {
            headers: new HttpHeaders({
                authorization: 'Basic ' + environment.AUTHPASS,
                'Content-Type': 'application/x-www-form-urlencoded',
            }),
        };

        return this.httpClient
            .post(
                this.helper.getAuthUrl(),
                'refresh_token=' + _.get(token, 'refresh_token', '') + '&grant_type=refresh_token',
                options,
            )
            .pipe(
                map((response) => {
                    this.storage.saveToken(response);
                    return response;
                }),
                catchError((e) => {
                    console.log(e);
                    // this.storage.clearToken();
                    this.router.navigate(['/login']);
                    return empty();
                }),
            );
    }

    public getCaptcha(): Observable<Captcha> {
        return this.httpClient.get(this.helper.getRootUrl() + '/captcha') as Observable<Captcha>;
    }
}
