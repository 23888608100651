import {Customer} from './../../customer.model';
import {GroupInvoiceStatusType} from './../../../enums/accounting/groupInvoiceStatusType.enum';
import * as _ from 'lodash';
import * as moment from 'moment';

import {Serializable} from '../../deserialize.service';

export class GroupInvoice implements Serializable<GroupInvoice> {
    aggregateId: string;
    amount: number;
    contractor: any;
    contractorId: number;
    date: moment.Moment;
    details: any[];
    id: number;
    payerCustomerId: number;
    payerInfo: any;
    payerPartnerId: number;
    status: GroupInvoiceStatusType;
    customer: Customer;
    visit: any;
    customerId: number;
    visitId: number;
    deserialize(input) {
        if (_.isNil(input)) {
            return undefined;
        }
        return Object.assign(this, input);
    }
}
