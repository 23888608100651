import {Asset} from './../asset.model';
import {Service} from './../service.model';
import {Contractor} from './../contractor.model';
import {Serializable} from './../deserialize.service';
import * as moment from 'moment';

export class CreatePrereservationRequest implements Serializable<CreatePrereservationRequest> {
    from: moment.Moment;
    to: moment.Moment;
    contractor: Contractor;
    service: Service;
    asset: Asset;
    itemId: string;
    deserialize(input: any) {
        return Object.assign(this, input);
    }
}
