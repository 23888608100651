// services
import {Serializable} from '../deserialize.service';
// models
import {Comment} from '../comment.model';
import {Customer} from '../customer.model';
import {Contractor} from '../contractor.model';
import {AuthorityCase} from './authorityCase.model';
import {Business} from '../business.model';

export class TriageResponse implements Serializable<TriageResponse> {
    id: number;
    appUserName: string;
    bsid: number;
    created: Date;
    caseAuthority: AuthorityCase;
    comments: Comment[];
    contractorComments: Comment[];
    contractor: Contractor;
    customer: Customer;
    status: string;
    serviceId: number;
    serviceName: string;
    price: number;
    business: Business;

    deserialize(input: any) {
        if (input.id) {
            this.id = input.id;
            this.appUserName = input.appUserName;
            this.bsid = input.bsid;
            this.created = new Date(input.created);
            this.comments = this.comments;
            this.contractorComments = this.contractorComments;
            this.caseAuthority = new AuthorityCase().deserialize(input.caseAuthority);
            this.contractor = new Contractor().deserialize(input.contractor);
            this.customer = new Customer().deserialize(input.customer);
            this.status = input.status;
            this.serviceId = input.serviceId;
            this.serviceName = input.serviceName;
            this.price = input.price;
            this.business = new Business().deserialize(input.business);
            return this;
        }
    }
}
