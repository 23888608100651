import { MenuService } from './_shared/services/menu.service';
import { TreatmentState } from './_shared/state/treatment/treatment.state';
import { BaseState } from './_shared/state/base/base.state';
import { environment } from '../environments/environment';
import { TokenInterceptor } from './token.interceptor';
import { GlobalErrorHandler } from './_shared/services/globalErrorHandler.service';
import { ErrorHandler, NgModule } from '@angular/core';

import { RulesService } from './_shared/services';
import { BrowserModule } from '@angular/platform-browser';
import { LayoutModule } from './theme/layouts/layout.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ScriptLoaderService } from './_services/script-loader.service';
import { ThemeRoutingModule } from './theme/theme-routing.module';
import { AuthModule } from './auth/auth.module';
import {NgbActiveModal, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { NgxPermissionsModule } from 'ngx-permissions';

import { DeviceDetectorModule } from 'ngx-device-detector';
import { PublicComponent } from './public/public.component';
import { ToastrModule } from 'ngx-toastr';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { NoFooterComponent } from './theme/frames/no-footer-fixed/no-footer-fixed.component';
import { ThemeComponent } from './theme/frames/default/theme.component';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import Swal from 'sweetalert2';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { NgxsLoggerPluginModule } from '@ngxs/logger-plugin';
import { NgxsFormPluginModule } from '@ngxs/form-plugin';

import {FormioAppConfig} from "angular-formio";
import {AppConfig} from "../config";

const provideSwal = Swal.mixin({
    buttonsStyling: false,
    confirmButtonColor: null,
    cancelButtonColor: null,
    customClass: {
        cancelButton: 'btn btn-secondary m-btn m-btn--custom',
        confirmButton: 'btn btn-success m-btn m-btn--custom',
    },
});

@NgModule({
    declarations: [ThemeComponent, AppComponent, PublicComponent, NoFooterComponent],
    imports: [
        NgbModule,
        LayoutModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ThemeRoutingModule,
        AuthModule,
        ToastrModule.forRoot({
            positionClass: 'toast-top-center',
        }),
        SweetAlert2Module.forRoot({
            provideSwal,
        }),
        NgxPermissionsModule.forRoot(),
        DeviceDetectorModule.forRoot(),
        HttpClientXsrfModule,
        HttpClientModule,
        NgxsModule.forRoot([BaseState, TreatmentState], {
            developmentMode: !environment.production,
        }),
        NgxsStoragePluginModule.forRoot(),
        NgxsReduxDevtoolsPluginModule.forRoot(),
        NgxsLoggerPluginModule.forRoot(),
        NgxsFormPluginModule.forRoot(),
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        ScriptLoaderService,
        RulesService,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler,
        },
        MenuService,
        NgbActiveModal
    ],
    bootstrap: [AppComponent],
    entryComponents: [],
})
export class AppModule {}
