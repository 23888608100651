import { OpenDocumentRequest } from './../model/utils/openDocumentRequest.model';
import { AssetGroup } from './../model/utils/assetGroup.model';
import { ReservationRequest } from './../model/reservationRequest.model';
import { DocumentsService } from './../../theme/pages/home/_services/documents.service';
import { DocumentSign } from './../model/portfolio/documentSign.model';
import { Business } from '../model/business.model';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
// models
import { Case, Event, BusinessSubjectConnection, BusinessSubject } from '../model';
// services
import { StorageService } from './storage.service';
import { NgxPermissionsService } from 'ngx-permissions';
// components
// rxjs
import { BehaviorSubject, Observable, from, Subject, of } from 'rxjs';
// bootstrap
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// environment
import { environment } from '../../../environments/environment';
// other
import * as moment from 'moment';
import { Moment } from 'moment';
import * as _ from 'lodash';
import Swal from 'sweetalert2';
import { tags } from '../constants/customTags.const';
import { map } from 'rxjs/operators';

@Injectable()
export class HelperService {
    public hiddeHeader: BehaviorSubject<any> = new BehaviorSubject(false);
    // public menuList: BehaviorSubject<any> = new BehaviorSubject({});
    public disabledNavigation: boolean = false;
    public customTags: any = _.clone(tags.CUSTOM_TERMS_TAGS);
    public ROLE_TRANSLATE: any = {
        ROLE_OWNER: 'Vodja',
        ROLE_SUBCONTRACTOR: 'Podizvajalec',
        ROLE_SEKRETAR: 'Sekretar',
        ROLE_WORKINGHOURS_MANAGER: 'Planer',
        ROLE_FRANCHISE_MANAGER: 'Franšizodajalec',
        ROLE_RESTRICTED_SUBCONTRACTOR: 'Podizvajalec',
    };

    public ngxDataTableTransletedMessages: any = {
        emptyMessage: 'Ni podatkov',
        totalMessage: 'skupaj',
    };

    // public CUSTOM_TERMS_TAGS: any = [
    //     { name: 'Brez oznake', id: 0, classColor: 'mf-custom-tag' },
    //     { name: 'Rdeča', id: 1, classColor: 'mf-custom-tag__red' },
    //     { name: 'Rumena', id: 2, classColor: 'mf-custom-tag__yellow' },
    //     { name: 'Zelena', id: 3, classColor: 'mf-custom-tag__green' }
    // ];

    constructor(
        private storage: StorageService,
        private permissionsService: NgxPermissionsService,
        private modalService: NgbModal,
        private route: Router,
    ) {}

    getTriageUrl(): string {
        //http://10.50.40.210:9090/booking/triageapi/contractors
        return (
            environment.triage.protocol + environment.triage.rootUrl + environment.triage.port + environment.triage.url
        );
    }

    getApiUrl(version: number = 1): string {
        //http://10.50.40.210:9090/bookin/erp/api
        return (
            environment.service['v' + version].protocol +
            environment.service['v' + version].rootUrl +
            environment.service['v' + version].apiPort +
            environment.service['v' + version].apiUrl
        );
    }

    getTreatmentUrl(): string {
        //http://10.50.40.210:9090/erp/treatments
        return (
            environment.treatment.protocol +
            environment.treatment.rootUrl +
            environment.treatment.port +
            environment.treatment.url
        );
    }

    getTelemedUrl(): string {
        //http://10.50.40.210:9090/erp/telemed
        return (
            environment.telemed.protocol +
            environment.telemed.rootUrl +
            environment.telemed.port +
            environment.telemed.url
        );
    }

    getAuthUrl(): string {
        //'http://10.50.40.210:8080/auth/oauth/token
        return environment.auth.protocol + environment.auth.rootUrl + environment.auth.port + environment.auth.url;
    }

    getRootUrl(): string {
        return environment.root.protocol + environment.root.rootUrl + environment.root.port + environment.root.url;
    }

    getReportUrl(): string {
        //http://10.50.40.210:9090/dentist/api
        return (
            environment.report.protocol + environment.report.rootUrl + environment.report.port + environment.report.url
        );
    }

    getDocumentUrl(): string {
        //http://localhost:9090/booking/docapi
        return (
            environment.document.protocol +
            environment.document.rootUrl +
            environment.document.port +
            environment.document.url
        );
    }

    getPrintServerUrl(): string {
        //http://localhost:9090/booking/papi
        return (
            environment.printserver.protocol +
            environment.printserver.rootUrl +
            environment.printserver.port +
            environment.printserver.url
        );
    }

    getTaskUrl(): string {
        //http://localhost:9090/booking/papi
        return environment.task.protocol + environment.task.rootUrl + environment.task.port + environment.task.url;
    }

    getAccountingUrl(version: number = 1): string {
        //http://localhost:9090/booking/aapi
        return (
            environment.accounting['v' + version].protocol +
            environment.accounting['v' + version].rootUrl +
            environment.accounting['v' + version].port +
            environment.accounting['v' + version].url
        );
    }

    getPatientUrl(version: number = 1): string {
        //http://localhost:9090/booking/aapi
        return (
            environment.patient.protocol +
            environment.patient.rootUrl +
            environment.patient.port +
            environment.patient.url
        );
    }
    
    getVisitUrl(): string {
        //http://localhost:9090/booking/visitapi
        return environment.visit.protocol + environment.visit.rootUrl + environment.visit.port + environment.visit.url;
    }

    getCommunicationUrl(): string {
        //http://localhost:9090/booking/communicationapi
        return (
            environment.communication.protocol +
            environment.communication.rootUrl +
            environment.communication.port +
            environment.communication.url
        );
    }

    getStatsUrl(): string {
        //http://localhost:9090/booking/communicationapi
        return (
            environment.statistics.protocol +
            environment.statistics.rootUrl +
            environment.statistics.port +
            environment.statistics.url
        );
    }

    getIteroUrl(): string {
        //http://localhost:9090/booking/erp/itero/
        return environment.itero.protocol + environment.itero.rootUrl + environment.itero.port + environment.itero.url;
    }

    getSurveysUrl(): string {
        //http://localhost:9090/booking/surveys
        return (
            environment.surveys.protocol +
            environment.surveys.rootUrl +
            environment.surveys.port +
            environment.surveys.url
        );
    }

    getAccessUrl(): string {
        //http://localhost:9090/booking/access
        return (
            environment.access.protocol + environment.access.rootUrl + environment.access.port + environment.access.url
        );
    }
    
    getAssistanceUrl(version: number = 2): string {
        //http://10.50.40.210:9090/booking/v2/assistance
        return (
            environment.assistance['v' + version].protocol +
            environment.assistance['v' + version].rootUrl +
            environment.assistance['v' + version].port +
            environment.assistance['v' + version].url
        );
    }


    getLocalPrinterUrl(): string {
        //http://10.50.40.210:9090/dentist/api
        return `http://127.0.0.1:${environment.termalPrinter.port}/webdriver/Printer1.bxl`;
    }
    
    // http://127.0.0.1:8080/webdriver/Printer1.bxl

    uuid(length: number = 8): string {
        var i, random;
        var result = '';
        // for (i = 0; i < 32; i++)
        for (i = 0; i < length; i++) {
            random = (Math.random() * 16) | 0;
            if (i === 8 || i === 12 || i === 16 || i === 20) {
                result += '-';
            }
            result += (i === 12 ? 4 : i === 16 ? (random & 3) | 8 : random).toString(16);
        }

        return result;
    }

    public showMessage(text: string): void {
        alert('message helper');
        // this.snackBar.open(text, 'Obvestilo', {
        //     duration: 8000,
        //     verticalPosition: 'top'
        // });
    }

    public momentToNgbDate(mdate: Moment): any {
        return {
            day: mdate.date(),
            month: mdate.month() + 1,
            year: mdate.year(),
        };
    }

    public displayAlert(title: string, content: string, settings?: any): Promise<any> {
        return Swal.fire(
            Object.assign(
                {
                    icon: 'warning',
                    title: title,
                    text: content,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Potrdi',
                    cancelButtonText: 'Prekliči',
                    reverseButtons: true,
                    buttonsStyling: false,
                    confirmButtonClass: 'btn btn-accent m-btn m-btn--custom m-btn--pill',
                    confirmButtonColor: null,
                    cancelButtonClass: 'btn btn-danger m-btn m-btn--custom m-btn--pill',
                    cancelButtonColor: null,
                },
                settings,
            ),
        );
    }

    public precisionRound(number, precision) {
        var factor = Math.pow(10, precision);
        return Math.round(number * factor) / factor;
    }

    public getDateRangeSettingsSubconstractorCalendar(
        startDate: moment.Moment = moment().subtract(1, 'week'),
        endDate: moment.Moment = moment(),
    ): any {
        return {
            autoApply: true,
            locale: {
                format: 'DD.MM.YYYY',
                cancelLabel: 'Prekliči',
                applyLabel: 'Potrdi',
                customRangeLabel: 'Izberi ročno',
                daysOfWeek: ['Ne', 'Po', 'To', 'Sr', 'Če', 'Pe', 'So'],
                monthNames: [
                    'Januar',
                    'Februar',
                    'Marec',
                    'April',
                    'Maj',
                    'Junij',
                    'Julij',
                    'Avgust',
                    'September',
                    'Oktober',
                    'November',
                    'December',
                ],
                firstDay: 1,
            },
            alwaysShowCalendars: false,
            ranges: {
                Danes: [moment(), moment()],
                Jutri: [moment().add(1, 'day'), moment().add(1, 'day')],
                'Trenutni teden': [moment().startOf('week'), moment().endOf('week')],
            },
            startDate: startDate,
            endDate: endDate,
        };
    }

    public getDateRangeSettngs(
        startDate: moment.Moment = moment().subtract(1, 'week'),
        endDate: moment.Moment = moment(),
        settings?: any,
    ): any {
        return Object.assign(
            {
                autoApply: true,
                locale: this.dateSelectorLocale,
                alwaysShowCalendars: false,
                ranges: {
                    Danes: [moment(), moment()],
                    'Zadnjih 7 dni': [moment().subtract(1, 'week'), moment()],
                    'Zadnjih 30 dni': [moment().subtract(1, 'month'), moment()],
                    'Tekoči mesec': [moment().startOf('month'), moment().endOf('month')],
                    'Prejšnji mesec': [
                        moment().subtract(1, 'month').startOf('month'),
                        moment().subtract(1, 'month').endOf('month'),
                    ],
                },
                startDate: startDate,
                endDate: endDate,
            },
            settings,
        );
    }

    // public getDateRangeSettngs(
    //     startDate: moment.Moment = moment().subtract(1, 'week'),
    //     endDate: moment.Moment = moment(),
    // ): any {
    //     return {
    //         autoApply: true,
    //         locale: {
    //             format: 'DD.MM.YYYY',
    //             cancelLabel: 'Prekliči',
    //             applyLabel: 'Potrdi',
    //             customRangeLabel: 'Izberi ročno',
    //             daysOfWeek: ['Ne', 'Po', 'To', 'Sr', 'Če', 'Pe', 'So'],
    //             monthNames: [
    //                 'Januar',
    //                 'Februar',
    //                 'Marec',
    //                 'April',
    //                 'Maj',
    //                 'Junij',
    //                 'Julij',
    //                 'Avgust',
    //                 'September',
    //                 'Oktober',
    //                 'November',
    //                 'December',
    //             ],
    //             firstDay: 1,
    //         },
    //         alwaysShowCalendars: false,
    //         ranges: {
    //             Danes: [moment(), moment()],
    //             'Zadnjih 7 dni': [moment().subtract(1, 'week'), moment()],
    //             'Zadnjih 30 dni': [moment().subtract(1, 'month'), moment()],
    //             'Tekoči mesec': [moment().startOf('month'), moment().endOf('month')],
    //         },
    //         startDate: startDate,
    //         endDate: endDate,
    //     };
    // }

    public getDateRangeFutureSettngs(
        startDate: moment.Moment = moment(),
        endDate: moment.Moment = moment().add(1, 'day'),
        settings?: any,
    ): any {
        return Object.assign(
            {
                autoUpdateInput: false,
                autoApply: true,
                locale: this.dateSelectorLocale,
                alwaysShowCalendars: false,
                ranges: {
                    Vsi: [moment(), moment().add(1, 'day')],
                    Danes: [moment(), moment()],
                    'Zadnjih 7 dni': [moment().subtract(1, 'week'), moment()],
                    'Naslednjih 7 dni': [moment(), moment().add(1, 'week')],
                    'Tekoči mesec': [moment().subtract(1, 'month'), moment()],
                    'Naslednji mesec': [moment(), moment().add(1, 'month')],
                    'Prejšnji mesec': [
                        moment().subtract(1, 'month').startOf('month'),
                        moment().subtract(1, 'month').endOf('month'),
                    ],
                },
                startDate: startDate,
                endDate: endDate,
            },
            settings,
        );
    }

    public getDateSettngs(startDate: moment.Moment = moment()): any {
        const result: any = {
            locale: this.dateSelectorLocale,
            singleDatePicker: true,
            autoApply: true,
            alwaysShowCalendars: false,
        };
        if (!_.isNull(startDate)) {
            _.set(result, 'startDate', startDate);
        }
        return result;
    }

    public getDateSettngsEmpty(startDate: moment.Moment = moment()): any {
        return {
            locale: this.dateSelectorLocale,
            singleDatePicker: true,
            autoApply: false,
            autoUpdateInput: false,
            alwaysShowCalendars: false,
            startDate: startDate,
        };
    }

    private dateSelectorLocale: any = {
        format: 'DD.MM.YYYY',
        cancelLabel: 'Prekliči',
        applyLabel: 'Potrdi',
        customRangeLabel: 'Izberi ročno',
        daysOfWeek: ['Ne', 'Po', 'To', 'Sr', 'Če', 'Pe', 'So'],
        monthNames: [
            'Januar',
            'Februar',
            'Marec',
            'April',
            'Maj',
            'Junij',
            'Julij',
            'Avgust',
            'September',
            'Oktober',
            'November',
            'December',
        ],
        firstDay: 1,
    };

    public parseJWT(token: any): any {
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    }

    public getAppVersion(): string {
        return environment.VERSION;
    }

    public isDifferentAppVersion(): boolean {
        if (!this.storage.getAppData()) {
            return false;
        }

        if (environment.VERSION === this.storage.getAppData().version) {
            return false;
        }
        return true;
    }

    public timeFrom24(minutes: number): string {
        return moment().startOf('day').add(minutes, 'minutes').format('HH:mm');
    }

    public minFrom24(time: number): number {
        return moment(time, 'HH:mm').diff(moment().startOf('day'), 'minutes');
    }

    public numberToWeekDayName(dayNumber: number): string {
        switch (dayNumber) {
            case 1:
                return 'MONDAY';
            case 2:
                return 'TUESDAY';
            case 3:
                return 'WEDNESDAY';
            case 4:
                return 'THURSDAY';
            case 5:
                return 'FRIDAY';
            case 6:
                return 'SATURDAY';
            case 7:
                return 'SUNDAY';
        }
    }

    public weekDayNameToNumber(dayName: string): number {
        switch (dayName) {
            case 'MONDAY':
                return 1;
            case 'TUESDAY':
                return 2;
            case 'WEDNESDAY':
                return 3;
            case 'THURSDAY':
                return 4;
            case 'FRIDAY':
                return 5;
            case 'SATURDAY':
                return 6;
            case 'SUNDAY':
                return 7;
        }
    }
    public getPaymentGroups(): any[] {
        return [
            { name: 'CASH', active: false, description: 'Gotovina', group: 'Ostalo' },
            { name: 'DEBIT_CARD', active: false, description: 'Kartica', group: 'Kartice' },
            { name: 'CREDIT_CARD', active: false, description: 'Ostale kartice', group: 'Kartice' },
            { name: 'VOUCHER', active: false, description: 'Kupon', group: 'Kuponi' },
            { name: 'TRR', active: false, description: 'Bančni račun', group: 'Ostalo' },
            { name: 'SELECT_BOX', active: false, description: 'Select box bon', group: 'Kuponi' },
            { name: 'ZVEZDAR', active: false, description: 'Zvezdar bon', group: 'Kuponi' },
            { name: 'CARD_MAESTRO', active: false, description: 'Maestro', group: 'Kartice' },
            { name: 'CARD_MASTERCARD', active: false, description: 'Mastercard', group: 'Kartice' },
            { name: 'CARD_VISA', active: false, description: 'Visa', group: 'Kartice' },
            { name: 'CARD_DINERS', active: false, description: 'Diners', group: 'Kartice' },
            { name: 'HISA_DARIL', active: false, description: 'Hiša daril', group: 'Kuponi' },
        ];
    }

    public getAssetGroups(): any[] {
        return [
            {
                type: 'DEVICE',
                label: 'Naprava',
            },
            {
                type: 'ROOM',
                label: 'Prostor',
            },
        ];
    }
    public createAssetGroups(data): AssetGroup[] {
        let result = [];
        let tmp = _.groupBy(data, 'assetType');
        for (let group in tmp) {
            switch (group) {
                case 'ROOM':
                    result.push(new AssetGroup().deserialize({ group: group, label: 'Prostor', data: tmp[group] }));
                    break;
                case 'DEVICE':
                    result.push(new AssetGroup().deserialize({ group: group, label: 'Naprava', data: tmp[group] }));
                    break;
            }
        }
        return result;
    }
    public parseMFError(error: any): any {
        if (error) {
            return _.get(error, 'errors', []).map((er) => {
                return er.msg;
            });
        }
        return '';
    }

    public checkStructError(data: any, code: string): boolean {
        const err = _.get(data, 'error.errors[0]', undefined);
        if (err) {
            if (err.code === code) {
                return true;
            }
        }
        return false;
    }
   

    public deepCopy(obj: any): any {
        if (obj) {
            return JSON.parse(JSON.stringify(obj));
        }
        return obj;
    }

    public getIconType(contentType: any): string {
        // debugger;
        let fileName = '';
        // let iconUrl = `./assets/app/media/img/files/${fileName}.svg`;
        if (contentType === 'application/pdf') {
            fileName = 'pdf';
        } else if (contentType === 'image/png') {
            fileName = 'png';
        } else if (contentType === 'image/jpeg' || contentType === 'image/jpg') {
            fileName = 'jpg';
        } else if (contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
            fileName = 'doc';
        } else if (contentType === 'audio/ogg; codecs=opus' || contentType.includes('video')) {
            fileName = 'mp4';
        } else {
            fileName = 'any';
        }
        return './assets/app/media/img/files/' + fileName + '.svg';
    }

    public navigateToVisitOrReservation(dataIn: any): void {
        const data = new Event().deserialize(dataIn);
        if (!data.case) {
            data.case = new Case().deserialize({
                caseId: dataIn.caseId,
                bsid: dataIn.bsid,
            });
        }
        // this.visitRest
        // .isVisitConcluded(this.storage.getSelectedContractor().id, model.data.visitId)
        // .pipe(takeUntil(this.onDestroy$))
        // .subscribe(res => {
        //     model.data.isConcluded = res; // == 'true';
        //     this.storage.setSelectedTerm(model.data);
        //     this.route.navigate(['terms/selected']);
        // });
        this.storage.setSelectedTerm(data);
        if (data.prereservationId) {
            this.route.navigate([`selected/${data.prereservationId}`]);
        } else {
            this.route.navigate([`selected/${data.visitId}/visit`]);
        }
    }

    public parseMauticTaskObj(mautic: any): any {
        let data: any = {};
        mautic.split('\n').forEach((el: string) => {
            if (el.includes('vase_ime_in_priimek')) {
                let tmp = el.split('->');
                data['leadName'] = tmp[1];
                return;
            }
            if (el.includes('vas_elektronski_naslov')) {
                let tmp = el.split('->');
                data['leadEmail'] = tmp[1];
                return;
            }
            if (el.includes('vas_telefon')) {
                let tmp = el.split('->');
                data['leadPhone'] = tmp[1];
                return;
            }
        });
        return data;
    }

    public getBusiness(bs: any): Business {
        //prejmeš Business objekt
        if (bs.bsid !== undefined) {
            return new Business().deserialize({
                bsid: bs.bsid,
                name: _.get(bs, 'name', 'Interno'),
                iconPath: _.get(bs, 'iconPath', '../../../assets/demo/demo5/media/img/logo/logo1.svg'),
            });
        }
        //prejmeš samo bsId ali drugače definiran Business
        let tmp = bs.id;
        if (tmp === undefined) {
            tmp = bs;
        }
        switch (tmp) {
            case 1:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: 'Generali',
                    iconPath: '../../../assets/app/media/img/bu/generali.svg',
                    logoPath: '../../../assets/app/media/img/bu/generali-logo.svg',
                });
            case 2:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: 'Triglav',
                    iconPath: '../../../assets/app/media/img/bu/triglav.svg',
                    logoPath: '../../../assets/app/media/img/bu/triglav.svg',
                });
            case 3:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: 'Merkur',
                    size: 35,
                    iconPath: '../../../assets/app/media/img/bu/merkur.svg',
                    logoPath: '../../../assets/app/media/img/bu/merkur-color.png',
                });
            case 4:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: 'M zavarovalnica',
                    iconPath: '../../../assets/app/media/img/bu/medifit.png',
                    logoPath: '../../../assets/app/media/img/bu/medifit.png',
                });
            case 6:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: 'Doktor 24',
                    iconPath: '../../../assets/app/media/img/bu/logo_dr24.png',
                    logoPath: '../../../assets/app/media/img/bu/logo_dr24.png',
                });
            case 7:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: 'Vzajemna',
                    iconPath: '../../../assets/app/media/img/bu/vzajemna.svg',
                    logoPath: '../../../assets/app/media/img/bu/vzajemna-logo.svg',
                });
            case 8:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: 'Medifit',
                    iconPath: '../../../assets/app/media/img/bu/medifit.png',
                    logoPath: '../../../assets/app/media/img/bu/medifit.png',
                });
            case 1016:
                return new Business().deserialize({
                    bsid: bs.id,
                    size: 55,
                    name: 'Medicinski Center Ljubljana',
                    logoPath: '../../../assets/app/media/img/bu/mcl.png',
                });
            case 1337:
                return new Business().deserialize({
                    bsid: bs.id,
                    size: 55,
                    name: 'Petrol',
                    logoPath: '../../../assets/app/media/img/bu/petrol.png',
                });
            case -111:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: !_.isNil(bs.name) ? `${bs.name}` : 'Zunanja rezervacija',
                    iconPath: '../../../assets/demo/demo5/media/img/logo/logo1.svg',
                    logoPath: '../../../assets/demo/demo5/media/img/logo/logo1.svg',
                });
            default:
                return new Business().deserialize({
                    bsid: bs.id,
                    name: !_.isNil(bs.name) ? `${bs.name}` : 'Interno',
                    iconPath: '../../../assets/demo/demo5/media/img/logo/logo1.svg',
                    logoPath: '../../../assets/demo/demo5/media/img/logo/logo1.svg',
                });
        }
    }

    public parseMauticTasksObjToString(mautic: any): string {
        const data: any = this.parseMauticTaskObj(mautic);
        return `Naziv: ${data.leadName} \nEpošta: ${data.leadEmail} \nTelefon: ${data.leadPhone}`;
    }

    public replaceSlahsForDolar(data: string): string {
        // debugger;
        if (!data) {
            return data;
        }
        return data.replace('/', '$$$$$$');
    }

    public filterArrayByCustomFilter(data: any[], filters: any[]) {
        filters = filters.filter((fil) => fil.value !== '');
        data = data.filter((element) => {
            return filters.every((fil) => {
                if (fil.subtypes == undefined) {
                    if (_.isNil(element[fil.type])) {
                        return null;
                    }
                    return element[fil.type].toString().toLowerCase().indexOf(fil.value) !== -1 ? true : false;
                } else {
                    if (_.isNil(element[fil.type])) {
                        return false;
                    }
                    const filterString = JSON.stringify(
                        fil.subtypes.map((subfil) => {
                            if (fil.caseElementType === 'object') {
                                if (_.isNil(element[fil.type][subfil])) {
                                    return null;
                                }
                                return element[fil.type][subfil].toString().toLowerCase();
                            } else {
                                return element[fil.type].map((el) => {
                                    return el[subfil].toString().toLowerCase();
                                });
                            }
                        }),
                    );
                    const inputString: string[] = fil.value.toString().toLowerCase().split(' ');
                    return inputString.every((inputElement) => {
                        return filterString.indexOf(inputElement) !== -1 ? true : false;
                    });
                }
            });
        });

        return data;
    }
    
    public isJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    public redirectToAndRefresh(uri: string) {
        this.route.navigateByUrl('/', { skipLocationChange: true }).then(() => this.route.navigate([uri]));
    }

    /* public filterArrayByPartner(data: any[], value: string) {
        let partners: Partner[] = this.allPartners();
        partners = partners.filter((partner) => {
            return partner.name.toLowerCase().indexOf(value) !== -1 ? true : false;
        });
        if (value === '') {
            return data;
        }
        if (partners.length === 0) {
            return [];
        } else {
            let filteredData: any[] = [];
            partners.forEach((partner) => {
                const filtered = data.filter((element) => element.bsid === partner.bsid);
                if (filtered.length > 0) {
                    filteredData = filteredData.concat(filtered);
                }
            });
            return filteredData;
        }
    } */
}
