import {Serializable} from '../deserialize.service';

export class PrintPOSItemsFooter implements Serializable<PrintPOSItemsFooter> {
    public sum: number;
    public toPay: number;
    public discountSum: number;

    deserialize(input: any) {
        this.sum = input.sum;
        this.toPay = input.toPay;
        this.discountSum = input.discountSum;

        return this;
    }
}
