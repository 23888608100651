import { Component, Input, OnInit } from '@angular/core';
// services
// models
import {Business} from "../../../../../../_shared/model/business.model";
import {HelperService} from "../../../../../../_shared/services";

@Component({
    selector: 'common-business-subject-icon',
    templateUrl: './business-subject-icon.component.html',
    styles: [],
})
export class BusinessSubjectIconComponent implements OnInit {
    @Input() set setBsId(value) {
        if (value) {
            this.bs = value;
            this.loadIcon();
        }
    }
    public bs;

    @Input()
    public size: number = 25;

    @Input()
    public onlyIcon: boolean = false;
    
    @Input()
    public logo: boolean = false;
    
    @Input()
    public icon: boolean = false;

    partner: Business;

    constructor(private helper: HelperService) {}

    public loadIcon() {
        this.partner = this.helper.getBusiness(this.bs);
    }

    ngOnInit() {}
}
