import { User } from './../model/user.model';
import { MfToastService } from './mfToast.service';
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { environment } from '../../../environments/environment';
import { StorageService } from './storage.service';

//https://github.com/bugsnag/bugsnag-js/issues/135#issuecomment-384788669

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
    constructor(private injector: Injector) {}

    handleError(error: any): void {
        const storage = this.injector.get(StorageService);
        let errorToDisplay: string = 'Napaka.';

        if (storage.getUserData()) {

            const user: User = storage.getUserData();
        }
        // }
        console.error(errorToDisplay);
        // IMPORTANT: Rethrow the error otherwise it gets swallowed
        throw error;
    }
}
