<!-- BEGIN: Header -->
<header
    class="m-grid__item    m-header"
    data-minimize-offset="200"
    data-minimize-mobile-offset="200"
    appunwraptag=""
>
    <div class="m-container m-container--fluid m-container--full-height overflow-x-scroll">
        <div class="m-stack m-stack--ver m-stack--desktop">
            <!-- BEGIN: Brand -->
            <div class="m-stack__item m-brand  m-brand--skin-dark m--hidden-desktop">
                <div class="m-stack m-stack--ver m-stack--general">
                    <div class="m-stack__item m-stack__item--middle m-brand__tools m--hidden-desktop text-left">
                        <!-- BEGIN: Responsive Aside Left Menu Toggler Desktop -->
                        <a
                            href="javascript:"
                            id="m_aside_left_offcanvas_toggle"
                            class="m-brand__toggler m-brand__toggler--right m--visible-tablet-and-mobile-inline-block text-left"
                        >
                            <span></span>
                        </a>
                        <!-- END -->
                    </div>
                    <div class="m-stack__item m-stack__item--middle m--hidden-desktop m-brand__logo">
                        <a
                            routerLink="/index"
                            class="m-brand__logo-wrapper d-flex"
                        >
                            <img
                                class="m-auto"
                                alt=""
                                src="./assets/demo/demo5/media/img/logo/logo-grey-color.svg"
                                width="140"
                                height="100%"
                            />
                            <!--christmas <img
                            class="m-auto"
                            alt=""
                            src="./assets/demo/demo5/media/img/logo/logo-grey-color-c.svg"
                            width="140"
                            height="100%"
                        /> -->
                        </a>
                    </div>
                    <div class="m-stack__item m-stack__item--middle m-brand__tools m--hidden-desktop">
                        <!-- BEGIN: Notification Menu item Mobile -->
                        <a
                            href="#"
                            (click)="sendDataToNotificatonSidebar()"
                        >
                            <i class="m--icon-font-size-lg2 la la-bell m--valign-middle"></i>
                            <span class="notification">
                                <span *ngIf="notificationsList.length !== 0">
                                    <span class="m-badge m-badge--danger"> </span>
                                </span>
                            </span>
                        </a>
                        <!-- END -->
                    </div>
                </div>
            </div>
            <!-- END: Brand -->
            <div
                class="m-stack__item m-stack__item--fluid m-header-head overflow-x-scroll"
                id="m_header_nav"
            >
                <!-- BEGIN: Horizontal Menu -->
                <button
                    class="m-aside-header-menu-mobile-close  m-aside-header-menu-mobile-close--skin-dark"
                    id="m_aside_header_menu_mobile_close_btn"
                >
                    <i class="la la-close"></i>
                </button>
                <div
                    id="m_header_menu"
                    class="m-header-menu m-aside-header-menu-mobile m-aside-header-menu-mobile--offcanvas  m-header-menu--skin-light m-header-menu--submenu-skin-light m-aside-header-menu-mobile--skin-dark m-aside-header-menu-mobile--submenu-skin-dark"
                >
                    <div class="d-flex mf-header-menu">
                        <ng-container>
                            <div
                                *ngIf="menu.subTitle | async"
                                class="pl-3 mf-header--sub-title"
                            >
                                {{ menu.subTitle | async }}
                            </div>
                        </ng-container>
                        <!--                        <div *ngIf="!isProduction">-->
                        <!--                            <span class="mf-notificiation-of-test-instance">TESTNA INSTANCA</span>-->
                        <!--                        </div>-->
                    </div>
                </div>
                <!-- END: Horizontal Menu -->
                <!-- BEGIN: Topbar -->
                <div
                    id="m_header_topbar"
                    class="m-topbar  m-stack m-stack--hor m-stack--general"
                >
                    <div>
                        <ul class="mf-header-submenu">
                            <li
                                *ngFor="let menu of subMenuList"
                                class="mf-header-submenu__item"
                                routerLinkActive="mf-header-submenu__item--active"
                                [routerLinkActiveOptions]="{exact:menu?.routerActiveExact}"
                            >
                                <a routerLink="{{ menu.link }}">
                                    {{ menu.title }}
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

<!--                <li-->
<!--                    class="m-menu__item  m-menu__item&#45;&#45;submenu"-->
<!--                    aria-haspopup="true"-->
<!--                    data-menu-submenu-toggle="hover"-->
<!--                    routerLinkActive="m-menu__item&#45;&#45;active m-menu__item&#45;&#45;open"-->
<!--                    routerLinkActiveOptions="{ exact: false }"-->
<!--                >-->
<!--                    <a href="#" class="m-menu__link m-menu__toggle">-->
<!--                        <div class="m-menu__link-icon mf-menu-icon__user">-->
<!--                            <common-subcontractor-icon [user]="loggedingUser"> </common-subcontractor-icon>-->
<!--                        </div>-->
<!--                        <span class="m-menu__link-text">-->
<!--                        {{ loggedingUser?.name }}-->
<!--                    </span>-->
<!--                        <i class="m-menu__ver-arrow la la-angle-right"></i>-->
<!--                    </a>-->
<!--                    <div class="m-menu__submenu">-->
<!--                        <span class="m-menu__arrow"></span>-->
<!--                        <ul class="m-menu__subnav">-->
<!--                            <li-->
<!--                                class="m-menu__item"-->
<!--                                routerLinkActive="m-menu__item&#45;&#45;active"-->
<!--                                routerLinkActiveOptions="{ exact: true }"-->
<!--                                aria-haspopup="true"-->
<!--                                *ngxPermissionsOnly="['menu_main_settings_profile__Visible']"-->
<!--                            >-->
<!--                                <a routerLink="/settings/profile" class="m-menu__link">-->
<!--                                    <span class="m-menu__link-text"> Profil </span>-->
<!--                                </a>-->
<!--                            </li>-->
<!--                            <li-->
<!--                                class="m-menu__item"-->
<!--                                routerLinkActive="m-menu__item&#45;&#45;active"-->
<!--                                routerLinkActiveOptions="{ exact: true }"-->
<!--                                aria-haspopup="true"-->
<!--                            >-->
<!--                                <a href="https://podpora.medifit.si/eambulanta" class="m-menu__link" target="_blank">-->
<!--                                    <span class="m-menu__link-text"> Pomoč <i class="la la-external-link"></i> </span>-->
<!--                                </a>-->
<!--                            </li>-->
<!--                            <li-->
<!--                                class="m-menu__item"-->
<!--                                routerLinkActive="m-menu__item&#45;&#45;active"-->
<!--                                routerLinkActiveOptions="{ exact: true }"-->
<!--                                aria-haspopup="true"-->
<!--                            >-->
<!--                                <a routerLink="/logout" class="m-menu__link">-->
<!--                                <span class="m-menu__link-text">-->
<!--                                    Odjava-->
<!--                                </span>-->
<!--                                </a>-->
<!--                            </li>-->
<!--                        </ul>-->
<!--                    </div>-->
<!--                </li>-->

                <div
                    id="m_header_topbar"
                    class="m-topbar  m-stack m-stack--hor m-stack--general  float-right m--hidden-tablet-and-mobile customActions"
                >
                    <ul class="list-unstyled d-flex h-100 mt-2 my-2">
                        <li
                            *ngFor="let actionButton of menu.actionButtonsMenu | async"
                            class="justify-content-center align-self-center pr-2"
                        >
                            <button
                                (click)="evaluateActionButton(actionButton)"
                                class="btn  m-btn m-btn--pill btn-block "
                                [ngClass]="actionButton.buttonStyle"
                                [disabled]="actionButton.disabled"
                            >
                                <span>
                                    <span>
                                        {{ actionButton.title }}
                                    </span>
                                </span>
                            </button>
                        </li>
                    </ul>
                </div>

                <!-- END: Topbar -->
            </div>
        </div>
    </div>
</header>
<!-- END: Header -->