import {WorkingIntervals} from '../../../../_shared/model/workingIntervals.model';
import {BusinessSubjectConnection, Contractor, PriceList, PriceListPut, Service} from '../../../../_shared/model';
import {SchedulerRules} from '../../../../_shared/model/schedulerRules.model';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import 'rxjs';
import {HttpClientService} from '../../../../_shared/services/http-client.service';
import {HelperService} from '../../../../_shared/services';

@Injectable()
export class SettingsService {
    data: any;
    token: any;

    constructor(private httpClient: HttpClientService, private helper: HelperService) {}

    //sendNewSubcontractorRequest()
    public sendNewSubcontractorRequest(contractorid: number, newSubcontractor: any): Observable<any> {
        return this.httpClient.post(
            this.helper.getApiUrl() + '/contractors/' + contractorid + '/subcontractors/new',
            newSubcontractor,
        );
    }
    public createUserWithExistingEaAccount(contractorid: number, newUser: any): Observable<any> {
        return this.httpClient.post(
            this.helper.getApiUrl() + '/contractors/' + contractorid + '/contractorUserLinkRequest',
            newUser,
        );
    }

    // .delete(
    //     'http://10.230.33.13:9090/booking/erp/api' +
    //         '/contractors/' +
    //         this.storage.getSelectedContractor().id +
    //         '/assets/' +
    //         data.value.id,
    //     data.value,
    // )

    public createNewAsset(contractorid: number, data: any): Observable<any> {
        return this.httpClient.post(this.helper.getApiUrl() + '/contractors/' + contractorid + '/assets', data);
    }

    public updateAsset(contractorid: number, assetId: number, data: any): Observable<any> {
        return this.httpClient.put(
            this.helper.getApiUrl() + '/contractors/' + contractorid + '/assets/' + assetId,
            data,
        );
    }
    public deleteAsset(contractorid: number, assetId: number, data: any): Observable<any> {
        return this.httpClient.delete(
            this.helper.getApiUrl() + '/contractors/' + contractorid + '/assets/' + assetId,
            data,
        );
    }

    //defineSlotRules()
    public defineSlotRules(
        contractorid: number,
        subcontractorid: number,
        schedulerRules: SchedulerRules,
    ): Observable<any> {
        // const data = {
        //     title: title,
        //     content: content
        // };
        return this.httpClient.post(
            this.helper.getApiUrl() + '/slotrules/contractors/' + contractorid + '/subcontractors/' + subcontractorid,
            schedulerRules,
        );
    }
    // getSlotRules()
    public getSlotRules(contractorid: number, subcontractorid: number): Observable<SchedulerRules> {
        // debugger;
        return this.httpClient.get(
            this.helper.getApiUrl() + '/slotrules/contractors/' + contractorid + '/subcontractors/' + subcontractorid,
        );
    }

    public getWorkingHours(
        contractorid: number,
        subcontractorid: number,
        from: string,
        to: string,
    ): Observable<SchedulerRules> {
        // debugger;
        return this.httpClient.get(
            this.helper.getApiUrl() +
                '/workinghours/contractors/' +
                contractorid +
                '/subcontractors/' +
                subcontractorid +
                '?from=' +
                from +
                '&to=' +
                to,
        );
    }

    public getAllWorkingHours(contractorid: number, from: string, to: string): Observable<SchedulerRules> {
        // debugger;
        return this.httpClient.get(
            this.helper.getApiUrl() + '/workinghours/contractors/' + contractorid + '?from=' + from + '&to=' + to,
        );
    }
    // http://localhost:9090/booking/erp/api/workinghours/contractors/54?from=2019-06-01&to=2019-07-30
    //updateWorkingHours()
    public updateWorkingHours(
        contractorid: number,
        subcontractorid: number,
        data: SchedulerRules,
        force: boolean = false,
    ): Observable<any> {
        // debugger;
        let dataPut: any = data;
        dataPut.force = force;
        return this.httpClient.put(
            this.helper.getApiUrl() + `/workinghours/contractors/${contractorid}/subcontractors/${subcontractorid}`,
            dataPut,
        );
    }

    public deleteWorkingHours(
        contractorid: number,
        subcontractorid: number,
        timeSlotId: string,
        from?: string,
        at?: string,
    ): Observable<SchedulerRules> {
        let url: string = `/workinghours/contractors/${contractorid}/subcontractors/${subcontractorid}/timeslots/${timeSlotId}`;
        if (from) {
            url = url + `?from=${from}`;
        } else if (at) {
            url = url + `?at=${at}`;
        }
        return this.httpClient.delete(this.helper.getApiUrl() + url);
    }

    public editWorkingHours(
        contractorid: number,
        subcontractorid: number,
        timeSlotId: string,
        data: WorkingIntervals,
        from?: string,
        diff?: number,
    ): Observable<SchedulerRules> {
        let url: string = `/workinghours/contractors/${contractorid}/subcontractors/${subcontractorid}/timeslots/${timeSlotId}`;
        if (from) {
            url = url + `?from=${from}&diff=${diff}`;
        } else {
            url = url + `?diff=${diff}`;
        }
        return this.httpClient.put(this.helper.getApiUrl() + url, data);
    }

    //updateContractor()
    public updateContractor(contractorid: number, data: Contractor): Observable<any> {
        // debugger;
        return this.httpClient.put(this.helper.getApiUrl() + '/contractors/' + contractorid, data);
    }

    public getSubContractorsServicesSetup(contractorid): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl() + `/services/manage/${contractorid}/type/MEDIFIT`);
    }

    public updateSubContractorsServicesSetup(contractorid, data: any): Observable<any> {
        return this.httpClient.put(this.helper.getApiUrl() + '/services/manage/' + contractorid, data);
    }

    //updateContractorService()
    public updateContractorService(contractorId: number, serviceId: number, data: Service): Observable<any> {
        return this.httpClient.put(
            this.helper.getApiUrl() + '/contractors/' + contractorId + '/services/' + serviceId,
            data,
        );
    }

    //addContractorService()
    public addContractorService(contractorId: number, data: Service): Observable<any> {
        return this.httpClient.post(this.helper.getApiUrl() + '/contractors/' + contractorId + '/services', data);
    }

    //removeContractorService()
    public removeContractorService(contractorId: number, serviceId: number): Observable<any> {
        return this.httpClient.delete(
            this.helper.getApiUrl() + '/contractors/' + contractorId + '/services/' + serviceId,
        );
    }
    //getBusinessSubjects()
    public getBusinessSubjects(contractorid: number): Observable<BusinessSubjectConnection[]> {
        return this.httpClient.get(this.helper.getApiUrl() + `/contractors/${contractorid}/bs`);
    }

    //updateBusinessSubjectsContractorSetup()
    public updateBusinessSubjectsContractorSetup(
        contractorid: number,
        bsid: number,
        data: BusinessSubjectConnection,
    ): Observable<any> {
        return this.httpClient.put(this.helper.getApiUrl() + `/contractors/${contractorid}/bs/${bsid}`, data);
    }

    //getContractorPriceLists()
    public getContractorPriceLists(contractorid: number, bsid: number): Observable<PriceList[]> {
        return this.httpClient.get(this.helper.getApiUrl() + `/contractors/${contractorid}/bs/${bsid}/price-lists`);
    }

    //updateContractorPriceLists()
    public updateContractorPriceLists(contractorid: number, bsid: number, data: PriceListPut): Observable<any> {
        return this.httpClient.put(
            this.helper.getApiUrl() + `/contractors/${contractorid}/bs/${bsid}/price-lists`,
            data,
        );
    }

    //getContractorPriceListForId()
    public getContractorPriceListForId(contractorid: number, bsid: number, plistid: number): Observable<PriceList> {
        return this.httpClient.get(
            this.helper.getApiUrl() + `/contractors/${contractorid}/bs/${bsid}/price-lists/${plistid}`,
        );
    }

    //getContractorActivePricelist()
    public getContractorActivePriceList(contractorid: number, bsid: number): Observable<PriceList> {
        return this.httpClient.get(
            this.helper.getApiUrl() + `/contractors/${contractorid}/bs/${bsid}/price-lists/active`,
        );
    }

    public getSendingContent(cid: number): Observable<any> {
        return this.httpClient.get(this.helper.getCommunicationUrl() + `/contractors/${cid}/templates`);
    }
    public setSendingContent(cid: number, data: any): Observable<any> {
        return this.httpClient.put(this.helper.getCommunicationUrl() + `/contractors/${cid}/templates`, data);
    }
    // /contractors/{contractorid}/notifications/rules"
    public getSendingRules(cid: number): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl() + `/contractors/${cid}/notifications/rules`);
    }
    // ("/contractors/{contractorid}/notifications/rules"
    public setSendingRules(cid: number, data: any): Observable<any> {
        return this.httpClient.put(this.helper.getApiUrl() + `/contractors/${cid}/notifications/rules`, data);
    }
    // /booking/erp/communicationapi/templates
    getDefaultRules(): Observable<any> {
        return this.httpClient.get(this.helper.getCommunicationUrl() + '/templates');
    }
}
