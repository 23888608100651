import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {ToastrService} from "ngx-toastr";
import {StorageService} from "../../../_shared/services";

@Component({
    selector: 'app-auth-nurse',
    templateUrl: './auth-nurse.component.html',
    styleUrls: ['./auth-nurse.component.scss'],
})
export class AuthNurseComponent implements OnInit {
    public authMode: boolean = false;
    constructor(public activeModal: NgbActiveModal, private route: Router, private toast: ToastrService, private storage: StorageService) {}

    ngOnInit(): void {
        if (!this.storage.getSelectedTerm()) {
            this.authMode = true;
        }
    }

    model: any = {};

    public authenticate() {
        if (this.model) {
            if (this.model.passcode === '1234') {
                // this.toast.clear();
                this.activeModal.close();
                this.storage.authNurse();
                this.route.navigate(['index']);
            } else {
                this.toast.error('Napačno geslo');
            }
        }
    }
}
