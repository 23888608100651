import { Event } from './event.model';
import { Customer } from './customer.model';
import { Asset } from './asset.model';
import { Service } from './service.model';
import { Serializable } from './deserialize.service';
import { ReservationTypes } from '../enums/reservationTypes.enum';
import { Subcontractor } from './subcontractor.model';
import * as moment from 'moment';

export class ReservationRequest implements Serializable<ReservationRequest> {
    businessHours: any;
    customerForTerm: Customer;
    customTagId: number;
    disableDateChange: boolean;
    editModel: any;
    end: moment.Moment;
    event: Event;
    genericTitle: string;
    isCreateReservationFromDraft: boolean = false;
    isEditMode: boolean;
    isGenericTerm: boolean;
    isOpenReservation: boolean;
    noShow: boolean;
    popupTitle: string;
    recurringType: any;
    reservationType: ReservationTypes;
    selectedAsset: Asset;
    selectedDate: moment.Moment;
    selectedService: Service;
    selectedSubcontractor: Subcontractor;
    selectedTime: string;
    selectedTimeEnd: string;
    showGenericTermDeleteButton: boolean = false;
    start: moment.Moment;
    termComment: string;
    telemed: boolean;
    oldEvent: any;
    // originDate: moment.Moment;
    // newCustomer: boolean;

    deserialize(input) {
        if (!this) {
            return;
        }
        // this.reservationType = input.reservationType;
        // // this.create = input.create;
        // this.selectedService = new Service().deserialize(input.selectedService);
        // this.selectedSubcontractor = new Subcontractor().deserialize(input.selectedSubcontractor);
        // this.selectedAsset = new Asset().deserialize(input.selectedAsset);
        // this.customerForTerm = new Customer().deserialize(input.customerForTerm);
        // this.event = new Event().deserialize(input.event);

        // this.isEditMode = input.isEditMode;
        // this.disableDateChange = input.disableDateChange;
        // this.isGenericTerm = input.isGenericTerm;
        // this.showGenericTermDeleteButton = input.showGenericTermDeleteButton;
        // this.selectedTime = input.selectedTime;
        // this.selectedTimeEnd = input.selectedTimeEnd;
        // this.popupTitle = input.popupTitle;
        // this.termComment = input.termComment;

        // this.genericTitle = input.genericTitle;
        // this.editModel = input.editModel;
        // this.selectedDate = input.selectedDate;
        // this.businessHours = input.businessHours;
        // this.customTagId = input.customTagId;
        // this.isCreateReservationFromDraft = input.isCreateReservationFromDraft;
        // this.recurringType = input.recurringType;
        // this.noShow = input.noShow;
        // this.isOpenReservation = input.isOpenReservation;

        // this.start = input.start;
        // this.end = input.end;

        return Object.assign(this, input);
        // return this;
    }
}
