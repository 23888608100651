import {Case} from './schedule/case.model';
import {Service} from './service.model';
import {Contractor} from './contractor.model';
import {Customer} from './customer.model';
import {ReservationTypes} from '../enums/reservationTypes.enum';
import {Serializable} from './deserialize.service';
import {Asset} from './asset.model';
import {tags} from '../constants/customTags.const';
import * as _ from 'lodash';
import * as moment from 'moment';

export class Holiday implements Serializable<Holiday> {
    aggregateId: number;
    asset: Asset;
    backgroundClass: string;
    backgroundColor: string;
    caseId: string;
    className: string;
    customer: Customer;
    contractor: Contractor;
    customTagId: number;
    // data: any;
    comment: string;
    dotClass: string;
    editable: boolean;
    isRecurring: boolean;
    noShow: boolean;
    prereservationId: string;
    recurringId: string;
    resourceId: any;
    start: any;
    status: number;
    reservationType: ReservationTypes;
    service: Service;
    textColor: string;
    title: string;
    visitEndDate: moment.Moment;
    visitId: any;
    isConcluded: boolean;
    forceUpdate: boolean;
    recurringType: any;
    fromNowOn: string;
    isBs: boolean; // is business subject
    additionalPrereservationId: string;
    isDraft: boolean;
    case: Case;
    recurringUntil: moment.Moment;
    allDay: boolean;

    deserialize(input) {
        if (_.isNil(input)) {
            return this;
        }
        this.allDay = true;
        this.case = new Case().deserialize(input.case);
        this.isDraft = input.isDraft;
        this.additionalPrereservationId = input.additionalPrereservationId;
        // debugger;
        this.forceUpdate = input.forceUpdate;
        this.fromNowOn = input.fromNowOn;
        this.contractor = new Contractor().deserialize(input.contractor);
        this.service = new Service().deserialize(input.service);
        this.recurringId = input.recurringId;
        this.isRecurring = input.isRecurring;
        if (!_.isNil(input.recurringId)) {
            this.isRecurring = true;
        }
        this.start = moment('2019-06-10');
        this.isBs = input.isBs;
        this.isConcluded = input.isConcluded;
        this.customer = new Customer().deserialize(input.customer);
        this.caseId = input.caseId;
        this.customTagId = input.customTagId;

        if (input.recurringUntil) {
            this.recurringUntil = moment(input.recurringUntil);
        }

        this.comment = input.comment;
        // debugger;
        this.recurringType = input.recurringType;
        this.title = 'Demo title';

        // this.data = input;
        this.visitId = input.visitId;
        this.prereservationId = input.prereservationId;
        this.aggregateId = input.aggregateId;
        // if (!input.resourceId) {
        //     this.resourceId = this.data.contractor.subcontractors[0].id;
        // } else {
        //     this.resourceId = input.resourceId;
        // }

        if (input.visitEndDate) {
            this.visitEndDate = moment(input.visitEndDate);
        }
        //ce je definiran uporstevamo tega!
        this.reservationType = input.reservationType;
        this.noShow = input.noShow;
        this.dotClass = 'm-fc-event--pink';
        if (!_.isNil(input.status)) {
            if (input.status === 2) {
                this.reservationType = ReservationTypes.RESERVE;
            } else if (input.status === 7) {
                this.reservationType = ReservationTypes.DRAFT;
            }
        }

        // if (input.dotClass) {
        //     this.dotClass = input.dotClass;
        // } else {
        //     if (subcontractor) {
        //         let foundSubc = subcontractor.find(el => {
        //             return el.id === this.data.contractor.subcontractors[0].id;
        //         });
        //         if (foundSubc) {
        //             // this.dotClass = foundSubc.color;
        //             this.backgroundClass = foundSubc.color;
        //         }
        //     }
        // }

        // // this.className = this.dotClass + ' ' + this.backgroundClass;
        // this.className = this.backgroundClass;

        // //ce je definiran uporstevamo tega!
        // if (input.className) {
        //     this.className = input.className;
        // }
        // debugger;
        // if (subcontractor) {
        //     let foundSubc = subcontractor.find(el => {
        //         return el.id === this.data.contractor.subcontractors[0].id;
        //     });
        //     // debugger;
        //     if (foundSubc) {
        //         // this.dotClass = foundSubc.color;
        //         // this.backgroundClass = foundSubc.color;
        //         this.backgroundColor = foundSubc.bgColor;
        //         this.textColor = foundSubc.fgColor;
        //     }
        // }

        //samoplacnik
        // if (input.selfFunding) {
        //     this.className = this.className + ' mf-self-funding';
        // }
        // debugger;

        if (this.customTagId) {
            // debugger;
            let tag: any = tags.CUSTOM_TERMS_TAGS.find((el) => el.id === this.customTagId);
            if (tag) {
                this.className = this.className + ' ' + tag.classColor;
            }
        }

        // if (this.status === ReservationTypes.DRAFT) {
        //     this.className = this.className + ' mf-draft-event';
        // }
        this.editable = input.editable;

        if (input.asset) {
            this.asset = new Asset().deserialize(input.asset);
        }

        return this;
    }
}

/*
m-fc-event--solid-primary
m-fc-event--solid-focus
m-fc-event--solid-danger
m-fc-event--solid-warning
m-fc-event--solid-success
*/

// id: 1000,
// title: 'Repeating Event',
// description: 'Lorem ipsum dolor sit amet, labore',
// start: YM + '-16T16:00:00'
