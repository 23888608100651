import {Serializable} from './deserialize.service';
import * as _ from 'lodash';

export class Customer implements Serializable<Customer> {
    id: number;
    name: string;
    surname: string;
    address: string;
    city: string;
    country: string;
    phone: string;
    mobilePhone: string;
    email: string;
    post: string;
    birthDate: Date;
    sendMail: boolean = false;
    sendSms: boolean = false;
    kzz: string;
    emso: string;
    taxNo: string;
    medifitId: number;
    createdFrom: string;

    deserialize(input) {
        if (_.isNil(input)) {
            return undefined;
        }

        this.id = input.id;
        this.name = input.name;
        this.surname = input.surname;
        this.address = input.address;
        this.city = input.city;
        this.country = input.country;
        this.phone = input.phone;
        this.mobilePhone = input.mobilePhone;
        this.email = input.email;
        this.post = input.post;
        this.birthDate = input.birthDate;
        this.sendMail = input.sendMail;
        this.sendSms = input.sendSms;
        this.kzz = input.kzz;
        this.emso = input.emso;
        this.taxNo = input.taxNo;
        this.medifitId = input.medifitId;
        this.createdFrom = input.createdFrom;
        return this;
    }

    public getFullName(): string {
        // return 'xxxxx';
        // debugger;
        return `${this.name} ${this.surname}`;
    }
}
