import { Serializable } from './deserialize.service';

export class ContractorBasicInfo implements Serializable<ContractorBasicInfo> {
    id: number;
    name: string;
    addressName: string;
    addressNumber: string;
    aggregateId: number;
    bankAccount: string;
    country: string;
    description: string;
    email: string;
    mapsLink: string;
    mobilePhone: string;
    phone: string;
    postName: string;
    postNumber: string;
    registrationNumber: string;
    taxId: string;
    www: string;
    taxPayer: boolean;
    paymentDeadlineDays: number;
    bottomLabelMedical: string;
    bottomLabel: string;
    accountingAddressName: string;
    accountingAddressNumber: string;
    accountingEmail: string;
    accountingPhone: string;
    accountingMobilePhone: string;
    accountingName: string;
    accountingPostName: string;
    accountingPostNumber: string;
    paymentOnlyViaVisit: boolean;

    deserialize(input) {
        this.id = input.id;
        this.name = input.name;
        this.addressName = input.addressName;
        this.addressNumber = input.addressNumber;
        this.aggregateId = input.aggregateId;
        this.bankAccount = input.bankAccount;
        this.country = input.country;
        this.description = input.description;
        this.email = input.email;
        this.mapsLink = input.mapsLink;
        this.mobilePhone = input.mobilePhone;
        this.phone = input.phone;
        this.postName = input.postName;
        this.postNumber = input.postNumber;
        this.registrationNumber = input.registrationNumber;
        this.taxId = input.taxId;
        this.www = input.www;
        this.taxPayer = input.taxPayer;
        this.paymentDeadlineDays = input.paymentDeadlineDays;
        this.bottomLabelMedical = input.bottomLabelMedical;
        this.bottomLabel = input.bottomLabel;
        this.accountingAddressName = input.accountingAddressName;
        this.accountingAddressNumber = input.accountingAddressNumber;
        this.accountingEmail = input.accountingEmail;
        this.accountingPhone = input.accountingPhone;
        this.accountingMobilePhone = input.accountingMobilePhone;
        this.accountingName = input.accountingName;
        this.accountingPostName = input.accountingPostName;
        this.accountingPostNumber = input.accountingPostNumber;
        this.paymentOnlyViaVisit = input.paymentOnlyViaVisit;
        return this;
    }

    // constructor(obj?: any) {
    //     this.name = obj && obj.name || null;
    //     this.doctorBpi = obj && obj.doctorBpi || null;
    //     this.institutions = obj && obj.institutions || null;
    // }
}

// addressName:"Dunajska"
// addressNumber:"156"
// aggregateId:482
// bankAccount:"123541324513451345134"
// country:"Slovenija"
// description:"Test"
// email:"janez.novak@gmail.com"
// id:54
// mapsLink:"https://goo.gl/maps/d6kUjF424AT2"
// mobilePhone:"041345635"
// name:"MEDIFIT FIZIOTERAPIJA"
// phone:"043524165"
// postName:"Ljubljana"
// postNumber:"1100"

// registrationNumber:"12342134"
// taxId:"10494278"
// www:"www.adrea.si"
