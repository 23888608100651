import {MenuItem} from './../../../_shared/model/utils/menuItem.model';
import {environment} from '../../../../environments/environment';
import {Router} from '@angular/router';
import {Contractor, Customer, User} from '../../../_shared/model';
import {MenuService} from '../../../_shared/services/menu.service';
import {AfterViewInit, Component, OnInit, Renderer2, ViewEncapsulation} from '@angular/core';
import {HelperService, StorageService} from '../../../_shared/services';
import {QuickSidebarService} from '../../../_shared/services/quickSidebar.service';
import {Notification} from '../../../_shared/model/notification.model';
import {BaseState} from "../../../_shared/state/base/base.state";
import {Store} from "@ngxs/store";

declare let mLayout: any;
@Component({
    selector: 'app-header-nav',
    templateUrl: './header-nav.component.html',
    encapsulation: ViewEncapsulation.None,
    styleUrls: ['header-nav.component.scss'],
})
export class HeaderNavComponent implements OnInit, AfterViewInit {
    public loggedingUser: User = this.storage.getUserData();
    public userRoles: any;
    public selectedCustomer: Customer;
    public selectedContractor: Contractor;
    public translateRole = this.helper.ROLE_TRANSLATE;
    public isProduction: boolean = environment.production;
    public clock: Date;
    public notificationsList: Notification[] = [];
    public subMenuList: MenuItem[] = [];
    public appType;
    constructor(
        public menu: MenuService,
        private helper: HelperService,
        public router: Router,
        private render: Renderer2,
        private quickSidebarService: QuickSidebarService,
        private storage: StorageService,
        private store: Store,
    ) {
        // this.helperNotification.onNotificationSideBarOpen.subscribe((data) => {
        //     this.notificationsList = data;
        // });
    }

    ngOnInit() {
        // debugger;
        // this.checkUrl(this.router.url.split('/')[1]);
        this.store.select(BaseState.appType).subscribe((data) => { this.appType = data; });
        this.menu.menuList.subscribe((res) => {
            this.subMenuList = res;
            if (res.length <= 0 && this.appType === 'browser') {
                this.render.removeClass(document.body, 'm-topbar--on');
                this.render.removeClass(document.body, 'm-header--on');
            } else {
                this.render.addClass(document.body, 'm-topbar--on');
                this.render.addClass(document.body, 'm-header--on');
            }
        });

        this.clock = new Date();
        setInterval(() => {
            //replaced function() by ()=>
            this.clock = new Date();
        }, 1000);
    }

    // public checkUrl(route) {
    //     switch (route) {
    //         case 'tablet':
    //             this.store.dispatch([new SetAppType('tablet')]);
    //             return;
    //         default:
    //             this.store.dispatch([new SetAppType('browser')]);
    //             return;
    //     }
    // }

    ngAfterViewInit() {
        mLayout.initHeader();
    }

    public evaluateActionButton(actionButton: any): void {
        actionButton.component[actionButton.function]();
    }
    public sendDataToNotificatonSidebar(): void {
        this.quickSidebarService.toggle(this.notificationsList);
    }
}
