import {Asset} from '../asset.model';
import {Subcontractor} from '../subcontractor.model';
import {Serializable} from '../deserialize.service';
import * as moment from 'moment';

export class AvailabiliyRequestData implements Serializable<AvailabiliyRequestData> {
    subcontractor: Subcontractor;
    from: moment.Moment;
    to: moment.Moment;
    asset: Asset;
    openForExternal: boolean;
    openForExternalCustomer: boolean;
    bsids: any;
    id: string;
    reacurring: number;
    partners: any;
    // connected: boolean;
    // directBooking: boolean;

    deserialize(input: any) {
        // debugger;
        // this.connected = input.connected;
        // this.directBooking = input.directBooking;
        this.from = input.from;
        this.to = input.to;
        if (input.subcontractor) {
            this.subcontractor = new Subcontractor().deserialize(input.subcontractor);
        }
        if (input.asset) {
            this.asset = new Asset().deserialize(input.asset);
        }
        this.openForExternal = input.openForExternal;
        this.openForExternalCustomer = input.openForExternalCustomer;
        this.id = input.id;
        this.bsids = input.bsids;
        this.reacurring = input.reacurring;
        this.partners = input.partners;
        return this;
    }
}
