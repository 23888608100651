import {Service} from './../../service.model';
import * as _ from 'lodash';
import * as moment from 'moment';

import {Serializable} from '../../deserialize.service';

export class IrInvoiceService implements Serializable<IrInvoiceService> {
    serviceId: number;
    date: moment.Moment;
    label: string;
    description: string;
    quantity: number;
    taxRate: number;
    taxableAmount: number;
    taxAmount: number;
    price: number;
    amount: number;
    discount: number;
    couponId: string;
    couponValue: number;
    medicalService: boolean;

    deserialize(input: any) {
        if (_.isNil(input)) {
            return undefined;
        }

        this.serviceId = input.serviceId;
        this.date = input.date;
        this.label = input.label;
        this.description = input.description;
        this.quantity = input.quantity;
        this.taxRate = input.taxRate;
        this.taxableAmount = input.taxableAmount;
        this.taxAmount = input.taxAmount;
        this.price = input.price;
        this.amount = input.amount;
        this.discount = input.discount;
        this.couponId = input.couponId;
        this.couponValue = input.couponValue;
        this.medicalService = input.medicalService;
        return this;
    }

    public getServiceObj(): Service {
        return new Service().deserialize({
            id: this.serviceId,
            name: this.description,
            price: this.price,
            tax: this.taxRate,
            isCoupon: this.couponId ? true : false,
            medicalService: this.medicalService,
        });
    }
}
