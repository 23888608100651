import {Serializable} from '../deserialize.service';

export class ContractorBookingSettings implements Serializable<ContractorBookingSettings> {
    customTags;

    constructor() {}
    deserialize(input: any) {
        if (!input || input === null) {
            return null;
        }
        this.customTags = input.customTags;

        return this;
    }
}
