import {Customer} from '../customer.model';
import {Serializable} from '../deserialize.service';
import * as _ from 'lodash';

export class EventUpdate implements Serializable<EventUpdate> {
    dateFrom: Date;
    dateTo: any;
    subcontractorId: number;
    customer: Customer;
    serviceId: number;
    assetId: any;
    comment: string;
    forceUpdate: boolean = false;
    sendSms: boolean = false;
    sendEmail: boolean = false;
    customTagId: any;
    title: String;
    newCustomer: boolean;
    telemed: boolean;

    //case
    bsId: number;
    caseId: string;
    caseInternalId: string;
    deserialize(input) {
        if (_.isNil(input)) {
            return this;
        }
        // debugger;
        this.dateFrom = input.dateFrom;
        this.dateTo = input.dateTo;
        this.subcontractorId = input.subcontractorId;
        this.customer = new Customer().deserialize(input.customer);
        this.serviceId = input.serviceId;
        this.assetId = input.assetId;
        this.comment = input.comment;
        this.sendSms = input.sendSms;
        this.sendEmail = input.sendEmail;
        this.customTagId = input.customTagId;
        this.title = input.title;
        this.newCustomer = input.newCustomer;
        this.telemed = input.telemed;

        this.bsId = input.bsId;
        this.caseId = input.caseId;
        this.caseInternalId = input.caseInternalId;
        return this;
    }
}
