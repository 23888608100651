import {PrereservationId} from './../schedule/prereservationId.model';
import {Serializable} from './../deserialize.service';

export class ConfirmReservationsRequest implements Serializable<ConfirmReservationsRequest> {
    prereservations: PrereservationId[];
    appUserId: number;
    appUserName: string;
    deserialize(input: any) {
        return Object.assign(this, input);
    }
}
