import {PriceListElement} from './priceListElement.model';
import {Serializable} from '../deserialize.service';
import * as moment from 'moment';

export class PriceList implements Serializable<PriceList> {
    id: number;
    listName: string;
    fromDate: moment.Moment;
    toDate: moment.Moment;
    priceList: PriceListElement[];
    confirmed: boolean;
    active: boolean;
    deserialize(input: any) {
        this.id = input.id;
        this.listName = input.listName;
        this.fromDate = moment(input.fromDate);
        this.toDate = moment(input.toDate);
        if (input.priceList) {
            this.priceList = input.priceList.map((el: PriceListElement) => new PriceListElement().deserialize(el));
        }
        this.confirmed = input.confirmed;
        this.active = input.active;
        return this;
    }
}
