import {ContractorAccoutingSettings} from './contractorAccoutingSettings.model';
import {ContractorModulesSettings} from './contractorModulesSettings.model';
import {ContractorMenuSettings} from './contractorMenuSettings.model';
import {Serializable} from '../deserialize.service';
import {ContractorBookingSettings} from './contractorBookingSettings.model';

export class ContractorSettings implements Serializable<ContractorSettings> {
    menu: ContractorMenuSettings;
    activeModules: ContractorModulesSettings;
    accounting: ContractorAccoutingSettings;
    booking: ContractorBookingSettings;

    constructor() {
        this.menu = new ContractorMenuSettings();
    }
    deserialize(input: any) {
        if (!input || input === null) {
            return null;
        }
        if (input.menu) {
            this.menu = new ContractorMenuSettings().deserialize(input.menu);
        }
        if (input.activeModules) {
            this.activeModules = new ContractorModulesSettings().deserialize(input.activeModules);
        }

        if (input.accounting) {
            this.accounting = new ContractorAccoutingSettings().deserialize(input.accounting);
        }

        if (input.booking) {
            this.booking = new ContractorBookingSettings().deserialize(input.booking);
        }

        return this;
    }
}
