import {Serializable} from '../deserialize.service';
import * as moment from 'moment';

export class PaperHistoryElement implements Serializable<PaperHistoryElement> {
    public paperDate: moment.Moment;
    public paperId: number;
    public visitDate: moment.Moment;
    public visitId: number;

    deserialize(input: any) {
        this.paperId = input.paperId;
        this.visitId = input.visitId;
        if (input.paperDate) {
            this.paperDate = moment(input.paperDate);
        }

        if (input.visitDate) {
            this.visitDate = moment(input.visitDate);
        }
        return this;
    }
}
