import {Serializable} from '../deserialize.service';

export class ContractorModulesSettings implements Serializable<ContractorModulesSettings> {
    active: string[];

    constructor() {}
    deserialize(input: any) {
        if (!input || input === null) {
            return null;
        }
        this.active = input.active;

        return this;
    }
}
