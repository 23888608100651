import {Serializable} from '../deserialize.service';

export class GuiUserSettings implements Serializable<GuiUserSettings> {
    lastSelectedContractorId: number;
    lastSelectedStaffId: number;

    constructor() {}

    deserialize(input: any) {
        if (!input || input === null) {
            return null;
        }
        this.lastSelectedContractorId = input.lastSelectedContractorId;
        this.lastSelectedStaffId = input.lastSelectedStaffId;

        return this;
    }
}
