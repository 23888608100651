import {map} from 'rxjs/operators';
import {AvgPerformance} from '../../../../_shared/model';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import 'rxjs';
import {HttpClientService} from '../../../../_shared/services/http-client.service';
import {HelperService} from '../../../../_shared/services';

@Injectable({
    providedIn: 'root',
})
export class ReportsService {
    data: any;
    token: any;

    constructor(private http: HttpClient, private httpClient: HttpClientService, private helper: HelperService) {}

    // getVisit()
    public getCustomerStats(cid: number, from: string, to: string): Observable<any> {
        return this.httpClient.get(
            this.helper.getStatsUrl() + '/contractors/' + cid + '/stats?from=' + from + '&to=' + to,
        );
    }

    //allStatsNew
    public getAllStats(cid: number, data: any): Observable<any> {
        return this.httpClient.post(
            this.helper.getStatsUrl() + '/contractors/' + `${cid}/` + 'reservationVisitStats',
            data,
        );
    }

    //Promet
    public getIncomeStats(cid: number, data: any): Observable<any> {
        return this.httpClient.post(this.helper.getStatsUrl() + '/contractors/' + `${cid}/` + 'visitStats', data);
    }
    //poslani SMS+EMAIL
    public getCommunicationStats(cid: number, data: any): Observable<any> {
        return this.httpClient.post(
            this.helper.getStatsUrl() + '/contractors/' + `${cid}/` + 'communicationStats',
            data,
        );
    }
    //Rezervacije
    public getReservationsStats(cid: number, data: any): Observable<any> {
        return this.httpClient.post(this.helper.getStatsUrl() + '/contractors/' + cid + '/reservationStatuses', data);
    }
    //Termini
    public getVisitStats(cid: number, data: any): Observable<any> {
        return this.httpClient.post(this.helper.getStatsUrl() + '/contractors/' + `${cid}/` + 'visitDailyStats', data);
    }
    //nove stranke
    public getNewCustomers(cid: number, data: any): Observable<any> {
        return this.httpClient.post(this.helper.getStatsUrl() + '/contractors/' + `${cid}/` + 'userStats', data);
    }

    // getServicesPerformance()
    public getServicesPerformance(cid: number, from: string, to: string): Observable<any> {
        return this.httpClient.get(
            this.helper.getStatsUrl() + '/contractors/' + cid + '/services/performance?from=' + from + '&to=' + to,
        );
    }

    // getSubcontractorsPerformance()
    public getSubcontractorsPerformance(cid: number, from: string, to: string): Observable<any> {
        return this.httpClient.get(
            this.helper.getStatsUrl() +
                '/contractors/' +
                cid +
                '/subcontractors/performance?from=' +
                from +
                '&to=' +
                to,
        );
    }

    // getSubcontractorsPerformance()
    public getAvgPerformanceContr(contractorid: number, from: string, to: string): Observable<AvgPerformance> {
        return this.httpClient
            .get(this.helper.getStatsUrl() + `/avg/contractors/${contractorid}?from=${from}&to=${to}`)
            .pipe(map((el) => new AvgPerformance().deserialize(el)));
    }

    // getSubcontractorsPerformance()
    public getAvgPerformanceSubcontr(
        contractorid: number,
        subcontractorid: number,
        from: string,
        to: string,
    ): Observable<any> {
        return this.httpClient.get(
            this.helper.getStatsUrl() +
                `/avg/contractors/${contractorid}/subcontractors/${subcontractorid}?from=${from}&to=${to}`,
        );
    }

    //getDailyAvailabilityReport
    public getDailyAvailabilityReport(
        contractorId: number,
        subcontractor: number[],
        from: string,
        to: string,
    ): Observable<any> {
        return this.httpClient.getAttachmentPost(
            `${this.helper.getApiUrl()}/contractors/${contractorId}/availability/report?from=${from}&to=${to}`,
            subcontractor,
        );
    }
}
