import { TreatmentGUI } from './../../model/treatment/treatmentGUI.model';
import { Treatment } from './../../model/treatment/treatment.model';
import { TreatmentReservationRequest } from '../../model/treatment/treatmentReservationRequest';
import { ReservationRequest } from './../../model/reservationRequest.model';
export class TreatmentStateModel {
    baseTerm: ReservationRequest;
    treatmentData: TreatmentGUI;
    newTerms: any[];
    allTerms: TreatmentReservationRequest[];
    treatmentform: any;
}
