import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../auth/_guards';
import {ThemeComponent} from './frames/default/theme.component';
import {SelectedTermComponent} from "./pages/home/terms/visit/selected-term/selected-term.component";
import {AuthNurseComponent} from "./components/auth-nurse/auth-nurse.component";

const routes: Routes = [
    {
        path: '',
        component: ThemeComponent,
        canActivate: [AuthGuard],
        // 'canActivateChild': [NotSavedGuard],
        children: [
            {
                path: 'index',
                loadChildren: () => import('./pages/home/index/index.module').then((m) => m.IndexModule),
            },
            {
                path: 'tablet/index',
                loadChildren: () => import('./pages/home/index/index.module').then((m) => m.IndexModule),
            },
            {
                path: '',
                redirectTo: 'index',
                pathMatch: 'full',
            },
            {
                path: '',
                loadChildren: () => import('./pages/home/terms/terms.module').then((m) => m.TermsModule),
            },
            {
                path: 'authNurse',
                component: AuthNurseComponent,
            }
        ],
    },
    {
        path: '**',
        redirectTo: 'index',
        pathMatch: 'full',
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class ThemeRoutingModule {}
