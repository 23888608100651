import { HttpClientService } from './http-client.service';
import { HelperService } from './helper.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class SurveysService {
    constructor(private httpClient: HttpClientService, private helper: HelperService) {}

    public getErpSurveys(cid: number): Observable<any> {
        return this.httpClient.get(this.helper.getAssistanceUrl(3) + `/public/surveytemplates/cid/${cid}`);
    }

    public getSurveys(cid: number): Observable<any> {
        return this.httpClient.get(this.helper.getAssistanceUrl(3) + `/surveytemplates/cid/${cid}`);
    }
    
    public getAssistanceSurveys(bsid: number): Observable<any> {
        return this.httpClient.get(this.helper.getAssistanceUrl(3) + `/public/surveytemplates/bsid/${bsid}`);
    }

    public getPublicCustomerResponses(cid: string): Observable<any> {
        return this.httpClient.get(this.helper.getAssistanceUrl(3) + `/public/surveys/customer/${encodeURIComponent(cid)}`);
    }

    public getCustomerResponses(cid: number, from?: string, to?: string): Observable<any> {
        return this.httpClient.get(
            this.helper.getAssistanceUrl(3) + `/surveys/${from}/${to}/customer/${encodeURIComponent(cid)}`,
        );
    }

    public updateCustomerSurvey(id, data): Observable<any> {
        return this.httpClient.put(this.helper.getAssistanceUrl(3) + `/surveytemplates/${id}`, data);
    }

    public createCustomerSurvey(data): Observable<any> {
        return this.httpClient.post(this.helper.getAssistanceUrl(3) + `/surveytemplates/`, data);
    }

    public getSurveysByGuid(guid): Observable<any> {
        return this.httpClient.get(this.helper.getPatientUrl() + `/booking/info/${guid}`);
    }

    public setAssistancePublicSurveyResponse(data): Observable<any> {
        return this.httpClient.post(this.helper.getAssistanceUrl(3) + `/public/surveys/`, data, { responseType: 'text' });
    }

    public setAssistanceSurveyResponse(data): Observable<any> {
        return this.httpClient.post(this.helper.getAssistanceUrl(3) + `/surveys/`, data, { responseType: 'text' });
    }


    public getSendingContent(bsid: number): Observable<any> {
        return this.httpClient.get(this.helper.getAssistanceUrl(2) + `/bs/${bsid}/templates`);
    }

}
