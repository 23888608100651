import {Serializable} from '../deserialize.service';

export class AssetGroup implements Serializable<AssetGroup> {
    group: string;
    label: string;
    data: any;

    deserialize(input) {
        if (!input || input === null) {
            return null;
        }

        Object.keys(input).forEach((key) => (this[key] = input[key]));

        return this;
    }
}
