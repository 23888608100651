import {AuthorizationType} from '../../enums/authorizationType.enum';

import {Serializable} from '../deserialize.service';
import * as moment from 'moment';

export class BCReservationStatus implements Serializable<BCReservationStatus> {
    bsId: number;
    caseId: string;
    caseInternalId: number;
    eventType: AuthorizationType;
    prereservationId: string;
    eventDate: moment.Moment;
    reservationDate: moment.Moment;
    wqId: number;
    subcontractorName: string;
    deserialize(input: any) {
        this.bsId = input.bsId;
        this.caseId = input.caseId;
        // this.contractorId = input.contractorId;
        this.caseInternalId = input.caseInternalId;
        this.eventType = input.eventType;
        this.prereservationId = input.prereservationId;
        this.eventDate = moment(input.eventDate);
        this.reservationDate = moment(input.reservationDate);
        this.wqId = input.wqId;
        this.subcontractorName = input.subcontractorName;
        return this;
    }
}
