import {PaperHistoryElement, PortfolioPaper, Result} from '../../../../_shared/model';
import {WhiteReferralApi} from '../../../../_shared/model/portfolio/whiteReferralApi.model';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import 'rxjs';
import {HttpClientService} from '../../../../_shared/services/http-client.service';
import {HelperService} from '../../../../_shared/services';

@Injectable({
    providedIn: 'root',
})
export class ResultService {
    constructor(private httpClient: HttpClientService, private helper: HelperService) {}

    //saveDocumentTemplate
    public saveDocumentTemplate(contractorId: number, templateName: string, data: Result): Observable<any> {
        return this.httpClient.put(
            this.helper.getDocumentUrl() + `/contractors/${contractorId}/documentTemplates/${templateName}`,
            data,
        );
    }
    //getDocumentTemplates
    public getDocumentTemplates(contractorId: number, paperType: string): Observable<any> {
        return this.httpClient.get(
            this.helper.getDocumentUrl() + `/contractors/${contractorId}/documentTemplates/` + paperType,
        );
    }
    //deleteDocumentTemplates
    public deleteDocumentTemplates(contractorId: number, templateId: string): Observable<any> {
        return this.httpClient.delete(
            this.helper.getDocumentUrl() + `/contractors/${contractorId}/documentTemplates/${templateId}`,
        );
    }

    public addSurvey(visitId: number, surveys: Result): Observable<any> {
        return this.httpClient.put(this.helper.getVisitUrl() + '/visits/' + visitId + '/surveys', surveys);
    }

    public getAllSurveys(visitId: number): Observable<Result> {
        return this.httpClient.get(this.helper.getVisitUrl() + '/visits/' + visitId + '/surveys');
    }

    public updateSurvey(visitId: number, sets: Result): Observable<any> {
        return this.httpClient.put(this.helper.getVisitUrl() + '/visits/' + visitId + '/surveys/', sets);
    }

    //brisemo izvid
    public deleteSurvey(visitId: number, surveyId: string): Observable<any> {
        return this.httpClient.delete(this.helper.getVisitUrl() + '/visits/' + visitId + '/surveys');
    }

    public getSurveyPreview(visitId: number): Observable<any> {
        return this.httpClient.getAttachment(this.helper.getPrintServerUrl() + '/visits/' + visitId + '/surveys');
    }

    //printDiagnose()
    public getSurveyPaperPreview(visitId: number, paperid: number, signature: boolean = false): Observable<any> {
        let url: string = `/visits/${visitId}/surveys/${paperid}`;
        if (signature) {
            url += `?signature=true`;
        }
        return this.httpClient.getAttachment(this.helper.getPrintServerUrl() + url);
    }

    public getVisitProof(visitId: number): Observable<any> {
        return this.httpClient.getAttachment(this.helper.getPrintServerUrl() + '/visits/' + visitId + '/termin');
    }

    //getVisitPortfolio
    public getVisitPortfolio(contractorId: number, visitId: number): Observable<PortfolioPaper[]> {
        return this.httpClient.get(
            this.helper.getVisitUrl() + `/contractors/${contractorId}/visits/${visitId}/portfolio`,
        );
    }

    //getVisitSurveys()
    public getSurveyPaper(visitId: number, paperid: number): Observable<Result> {
        return this.httpClient.get(this.helper.getVisitUrl() + `/visits/${visitId}/surveys/${paperid}`);
    }
    //updateWholeVisitSurvey()
    public updateSurveyPaper(visitId: number, surveyid: number, sets: Result): Observable<any> {
        return this.httpClient.put(this.helper.getVisitUrl() + `/visits/${visitId}/surveys/${surveyid}`, sets);
    }
    //podpisovanje dokumentov znotraj eA
    public getSignedSurvey(contractorId: number, visitId: number, paperId: number): Observable<any> {
        return this.httpClient.getAttachmentPost(
            this.helper.getVisitUrl() + `/contractors/${contractorId}/visits/${visitId}/surveys/${paperId}/signed`,
        );
    }
    public getAllSignedFromEa(contractorId: number, paperId: number, bsId?, caseId?): Observable<any> {
        let allSignedUrl = this.helper.getVisitUrl() + `/contractors/${contractorId}/surveys/${paperId}/signed`;
        if (bsId && caseId) {
            allSignedUrl += `/?bsid=${bsId}&caseid=${caseId}`;
        }
        return this.httpClient.get(allSignedUrl);
    }
    public getLastSigned(contractorId: number, paperId: number): Observable<string> {
        return this.httpClient.get(this.helper.getDocumentUrl() + `/contractors/${contractorId}/surveys/${paperId}`, {
            responseType: 'text',
        });
    }
    public getSingleSigned(contractorId: number, documentId: string): Observable<any> {
        return this.httpClient.getAttachment(
            this.helper.getDocumentUrl() + `/contractors/${contractorId}/documents/${documentId}`,
        );
    }

    //getWhiteReferral()
    public getWhiteReferral(contractorid: number, paperid: number): Observable<WhiteReferralApi> {
        return this.httpClient.get(
            this.helper.getVisitUrl() + `/contractors/${contractorid}/white-referral/${paperid}`,
        );
    }

    //searchRDP()
    public searchRDP(query: string): Observable<any> {
        return this.httpClient.get(this.helper.getApiUrl(2) + `/rdp/search?query=${query}`);
    }

    //printWhiteReferral()
    public printWhiteReferral(contractorid: number, paperid: number, signature: boolean = false): Observable<any> {
        let url: string = `/contractors/${contractorid}/white-referral/${paperid}`;
        if (signature) {
            url += `?signature=true`;
        }
        return this.httpClient.getAttachment(this.helper.getPrintServerUrl() + url);
    }

    //updateWhiteReferral()
    public updateWhiteReferral(
        contractorid: number,
        visitId: number,
        paperid: number,
        data: WhiteReferralApi,
    ): Observable<any> {
        return this.httpClient.put(
            this.helper.getVisitUrl() + `/contractors/${contractorid}/visits/${visitId}/white-referral/${paperid}`,
            data,
        );
    }

    //updateWhiteReferral()
    public newWhiteReferral(contractorid: number, visitId: number, data: WhiteReferralApi): Observable<any> {
        return this.httpClient.put(
            this.helper.getVisitUrl() + `/contractors/${contractorid}/visits/${visitId}/white-referral`,
            data,
        );
    }

    //getCustomerPapersHistory()
    public getCustomerPapersHistory(
        contractorId: number,
        customerId: number,
        papertype: string,
        from?: string,
        to?: string,
    ): Observable<PaperHistoryElement[]> {
        if (!from || !to) {
            return this.httpClient.get(
                this.helper.getVisitUrl() + `/contractors/${contractorId}/customers/${customerId}/papers/${papertype}/`,
            );
        }
        return this.httpClient.get(
            this.helper.getVisitUrl() +
                `/contractors/${contractorId}/customers/${customerId}/papers/${papertype}?form=${from}&to=${to}`,
        );
    }
}
